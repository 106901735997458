import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Button, SectionHeader, ContentDiv, SectionLabel, Wrapper, Column, MessageLabel, TextFieldLabel, TextFieldSmall } from '../utils/Styles';
import swal from 'sweetalert';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { SelectionControl } from 'react-md';
import * as Constants from '../utils/Constants';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from './Loader';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Leaflet from './Leaflet';
import { cloneDeep, isEqual } from 'lodash';
import { getMarkersListLength } from '../utils/Utils';

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

//eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default


 //Access key: pk.eyJ1IjoiZnRpLXNtYXNrZSIsImEiOiJja3FxYzRvN3kwcGthMndwYjNncXVid29tIn0.oTWwf89LJJvbqJHCLKI0cQ

const LOCATION_DEFAULT = {
  'longitude': '139.83947',
  'latitude': '35.65283',
  'enable_cloud_upload': true,
  'center_map': false,
  'enabled_location': false
}

const DirectionInputs = styled.div`
    display: flex;
    gap: 0 20px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const MapContainer = styled.div`
    margin: 20px;
    @media screen and (max-width: 500px) {
        overflow-x: auto;
    }
`;

const MessageCotainer = styled.div`
    @media screen and (max-width: 1920px) {
        width: 508px;
    }
    @media screen and (max-width: 850px) {
        width: 400px;
    }
    @media screen and (max-width: 515px) {
        width: 325px;
    }
    @media screen and (max-width: 450px) {
        width: 265px;
    }
`;

const CheckboxLabel = styled.div`
    @media screen and (max-width: 450px)  {
        font-size: 8px;
        text-align: center;
        margin-block: auto;
    }
`;

const CheckboxContainer = styled.div`
    margin: 0 0 15px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    ${({isDisabled}) => isDisabled && css`
        opacity: 0.4;
    `}
`;


const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const FetchBrowserLocationDiv = styled.div`
    margin-top: 22px;
    display: flex;
    align-items: center;
    gap: 0 10px;
    > div {
        margin: -1px 10px 0 0; // Removed margin-bottom of 5px
        @media screen and (max-width:900px){
            width: 278px;
        }
        @media screen and (max-width:700px){
            width: 228px;
        }
        @media screen and (max-width:450px){
            width: 170px;
        }
        @media screen and (max-width:390px){
            width: 140px;
        }
    }
    > button {
        margin: 0;
        width: max-content;
    }
    @media screen and (max-width:390px){
        gap: 0 3px;
    }
    ${({isDisabled}) => isDisabled && css`
        opacity: 0.4;
        > button {
            pointer-events: none;
        }
    `}
`;

const ToggleContainer = styled.div`
    > div > label {
        flex-direction: row-reverse;
        > div {
            margin-left: 0;
        }
    }
`;

const DeviceSettings = styled.div`
    ${({isDisabled}) => isDisabled && css`
        opacity: 0.4;
        pointer-events: none;
        ${FetchBrowserLocationDiv} {
            opacity: 1;
        }
    `}
`;

const DeviceInputsContainer = styled.div`
    padding: 10px 15px;
    ${({isReadOnly}) => isReadOnly && css`
        pointer-events: none;
    `}
`;

const ResponsiveFieldset = styled.fieldset`
    margin: 0;
`;

const ResponsiveRow = styled(Row)`
    @media screen and (max-width: 470px) {
        width: 300px;
    }
    @media screen and (max-width: 440px) {
        width: 230px;
    }
`;

const ResponsiveWrapper = styled(Wrapper)`
    @media screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 500px) {
        font-size: 0.75rem;
    }
    @media screen and (max-width: 450px) {
        font-size: 0.68rem;
    }
    @media screen and (max-width: 390px) {
        font-size: 0.6rem;
    }
`;

const Location = (props) => {
    const [locationInputs, setLocationInputs] = useState({latitude: 35.65283, longitude: 139.83947});
    const [stackList, setStackList] = useState({});
    const [stackListLoaded, setStackListLoaded] = useState(false);
    const [streamStatusLoaded, setStreamStatusLoaded] = useState(false);
    const [stackListLength, setStackListLength] = useState(0);
    const prevStackList = useRef(stackList);
    const prevStackListRef = useRef({});
    const prevStreamStatusDataRef = useRef({});
    const [readOnly, setReadonly] = useState(true);
    const [locationLoading, setLocationLoading] = useState(true);
    const [deviceId, setDeviceId] = useState('');
    const params = useLocation().search;
    const [historyParams] = useState(params);
    const history = useHistory();
    const [showDeleteButton, setShowDeleteButton] = useState(true);
    const [selectedTab, setSelectedTab] = useState('general');
    const [selectMode, setSelectMode] = useState('mapbox://styles/mapbox/streets-v11')
    const [deviceEnabled, setDeviceEnabled] = useState(false);
    const [viewport, setViewport] = useState({
        latitude: 35.65283,
        longitude: 139.83947,
        zoom: 8.5,
        bearing: 0,
        pitch: 0
    });
    const [marker, setMarker] = useState({
        latitude: 35.65283,
        longitude: 139.83947
    });
    const [browserLocation, setBrowserLocation] = useState(marker)
    const [events, logEvents] = useState({ 
        latitude: 35.65283,
        longitude: 139.83947
    });
    const [ width, setWidth ]   = useState(window.innerWidth);
    const [leafletInputs, setLeafletInputs] = useState({latitude: 35.65283, longitude: 139.83947});
    const [streamStatusData, setStreamStatusData] = useState();
    const [mapViewType, setMapViewType] = useState('NoStreamDevice');
    const initialMapViewCheckboxes = {streamsCheckbox: false, deviceCheckbox: false};
    const [mapViewCheckboxes, setMapViewCheckboxes] = useState(!localStorage.getItem('mapViewCheckboxesSettingsPage') ? initialMapViewCheckboxes : JSON.parse(localStorage.getItem('mapViewCheckboxesSettingsPage')));
    const [isMount, setIsMount] = useState(true);

    function getStreamStatus(stackName){
        var streamStatus = "";
        try{
            if (streamStatusData && "stacks" in streamStatusData){
                streamStatus = streamStatusData["stacks"][stackName]["running_status"]["status"];
            }
        }catch(error){
            console.log(error);
        }
        return streamStatus;
    }

    const OnCancel=()=>{
        setReadonly(true);
        getLocationApi();
        getStreamList();
        if (localStorage.getItem('mapViewCheckboxesSettingsPage') !== null) {
            setMapViewCheckboxes(JSON.parse(localStorage.getItem('mapViewCheckboxesSettingsPage')));
        }
    }

    const getStackTooltipDetails = (stack_name, stack) => {
        let name = ''
        if(stack === 'USB') {
            name = stack_name + ' (Web Camera)'
        } else if(stack === 'RTSP') {
            name = stack_name + ' (IP Camera)'
        } else if(stack === 'FTP') {
            name = stack_name + ' (Image FTP)'
        } else if(stack === 'SAFIE') {
            name = stack_name + ' (Safie Camera)'
        } else if(stack === 'MLIT') {
            name = stack_name + ' (MLIT encoder)'
        } else if(stack === 'RTMP') {
            name = stack_name + ' (RTMP)'
        } else if(stack === 'RTP') {
            name = stack_name + ' (RTP)'
        }
        return name;
      }

    const updateDimensions = useCallback(()  => {
        setWidth(window.innerWidth);
      },  [ ]);
    
      useEffect(() => {
        window.addEventListener('resize', updateDimensions);
    
        return () => window.removeEventListener('resize', updateDimensions);
      }, [ updateDimensions ]);

    function round5(value) {
        return (Math.round(value * 1e5) / 1e5).toFixed(5);
    }
    
    const setParams = useCallback(() => {
        const params = '?' + `${selectedTab === 'general' ? 'general' : '&selectedTab=' + selectedTab}`
        if (params === '?') {
            return '';
        }
        return params;
    }, [selectedTab]);
    
    const fetchHistoryParams = useCallback(() => {
        const string = new URLSearchParams(historyParams);
        let newParams = {};
        newParams.selectedTab = string.get('selectedTab');

        if (newParams.selectedTab) {
            setSelectedTab(newParams.selectedTab);
        }
    }, [historyParams]);
    
    useEffect(()=>{
        fetchHistoryParams();
    },[fetchHistoryParams]);

    useEffect(() => {
        history.push('/location' + setParams());
    }, [history, setParams]);

    const getLocation = () => {
        try {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    let data = { ...locationInputs };
                    data.latitude = round5(position.coords.latitude);
                    data.longitude = round5(position.coords.longitude);
                    data.browser_location = true;
                    setLocationInputs(prev => {
                        return {...prev, ...data};
                    });
                    setLeafletInputs(prev => {
                        return {...prev, ...data};
                    });
                    setBrowserLocation({...data})
                    setMarker({
                        longitude: parseFloat(data.longitude),
                        latitude: parseFloat(data.latitude)
                    });
                    setViewport({
                        latitude:  parseFloat(data.latitude),
                        longitude:  parseFloat(data.longitude),
                        zoom: 8.5,
                        bearing: 0,
                        pitch: 0
                    });
                    logEvents({
                        longitude: parseFloat(data.longitude),
                        latitude: parseFloat(data.latitude)
                    });
                },
                function(error) {
                    let data = { ...locationInputs };
                    data.longitude = '139.83947';
                    data.latitude = '35.65283';
                    data.browser_location = true; 
                    setLocationInputs(data);
                    setLeafletInputs(data);
                    setBrowserLocation({...data});
                    setMarker({
                        longitude: parseFloat(data.longitude),
                        latitude: parseFloat(data.latitude)
                    });
                    setViewport({
                        latitude:  parseFloat(data.latitude),
                        longitude:  parseFloat(data.longitude),
                        zoom: 8.5,
                        bearing: 0,
                        pitch: 0
                    });
                    logEvents({
                        longitude: parseFloat(data.lon),
                        latitude: parseFloat(data.lat)
                    });
                }
            );
        } catch (err) {
            let data = { ...locationInputs };
            data.longitude = '139.83947';
            data.latitude = '35.65283';
            data.browser_location = true; 
            setLocationInputs(data);
            setLeafletInputs(data);
            setBrowserLocation({...data});
            setMarker({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
            setViewport({
                latitude:  parseFloat(data.latitude),
                longitude:  parseFloat(data.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            });
            logEvents({
                longitude: parseFloat(data.lon),
                latitude: parseFloat(data.lat)
            });
        } 
    }

    function validateText(str) {
        var tarea = str;
        if (tarea.indexOf("http://") == 0) {
            return true;
        } else if (tarea.indexOf("https://") == 0) {
            return false;
        }
    }

    const getBrowserLocation = () => {
        if(!readOnly){
            const Url = window.location.href;
            const isHttp = validateText(Url);
            if(isHttp) {
                swal({ text: "Browser's Location can be used only when site is accessed through HTTPS", icon: 'error' });
            } else {
                getLocation();
            }
        }
    };

    const handleToggle = (value, event) => {
        if(!readOnly){
            let data = {...locationInputs, [event.target.id]: value};
            setLocationInputs(data);
        }
    };

    const handleOnChange = (event) => {
        let data = { ...locationInputs };
        if(event.target.name === 'latitude') {
            event.target.value = event.target.value.replace(/[^.-\d]/g, '');
            if((event.target.value > 90  ||  event.target.value <= -90)) {
                return '';
            } else if(event.target.value.split('.').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.split('-').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
                event.target.value = event.target.value.replace('-', '');
            } else {
                data[event.target.name] = event.target.value;
                setLocationInputs(data); 
            }
        } else if(event.target.name === 'longitude') {
            event.target.value = event.target.value.replace(/[^.-\d]/g, '');
            if((event.target.value > 180  ||  event.target.value < -180)) {
                return '';
            } else if(event.target.value.split('.').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.split('-').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
                event.target.value = event.target.value.replace('-', '');
            } else {
                data[event.target.name] = event.target.value;
                setLocationInputs(data);
            }
        } else {
            data[event.target.name] = event.target.value;
            setLocationInputs(data); 
        }
    };

    const onBlurInputs = (event) => {
        let data = {...locationInputs};
        if (event.target.id === 'latitude' && (event.target.value.length === 1 && (event.target.value === '-' || event.target.value === '.') )) {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            return;
        } else if (event.target.id === 'longitude' && (event.target.value.length === 1 && (event.target.value === '-' || event.target.value === '.'))) {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            return;
        } else if (event.target.id === 'longitude' && event.target.value === '') {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            data[event.target.id] = 0;
            setLocationInputs(data);
            setLeafletInputs(data);
            return;
        } else if (event.target.id === 'latitude' && event.target.value === '') {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            data[event.target.id] = 0;
            setLocationInputs(data);
            setLeafletInputs(data);
            return;
        } else {
            if(data.latitude === '-' || data.latitude === '.') {
                document.getElementById('latitude').style.borderColor = 'lightgray';
                return;
            } else if(data.longitude === '-' || data.longitude === '.') {
                document.getElementById('longitude').style.borderColor = 'lightgray';
                return;
            } else {
                data[event.target.id] = round5(parseFloat(event.target.value)).toString();
                setMarker({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude)
                });
                setViewport({
                    latitude: parseFloat(data.latitude),
                    longitude: parseFloat(data.longitude),
                    zoom: 8.5,
                    bearing: 0,
                    pitch: 0
                });
                logEvents({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude)
                });
                setLocationInputs(data);
                setLeafletInputs(data);
            } 
        }
    }

    const editMap = () => {
        setReadonly(false);
    }

    const getLocationApi = useCallback((browser_location) => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location')
        .then(res =>{
          if(res.data.data.action === 'DeleteStack'){
            setLocationInputs(LOCATION_DEFAULT);
            let data = LOCATION_DEFAULT;
            setShowDeleteButton(false);
            setDeviceEnabled(false);
            setMarker({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
            setViewport({
                latitude:  parseFloat(data.latitude),
                longitude:  parseFloat(data.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            });
            logEvents({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
          } else {
            let data = res.data.data;
            data.latitude = data.latitude;
            data.longitude = data.longitude;
            data.browser_location = browser_location;
            setDeviceEnabled(data.enabled_location);
            if(data.latitude === ' ' && data.longitude === ' ') {
                setLocationInputs(data);
                setLeafletInputs(data);
                setShowDeleteButton(true);
                delete data.browser_location;
                setMarker({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude)
                });
                setViewport({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude),
                    zoom: 8.5,
                    bearing: 0,
                    pitch: 0
                });
                logEvents({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude)
                });
            } else {
                setLocationInputs(data)
                setLeafletInputs(data);
                setShowDeleteButton(true);
                delete data.browser_location;
                setMarker({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude)
                });
                setViewport({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude),
                    zoom: 8.5,
                    bearing: 0,
                    pitch: 0
                });
                logEvents({
                    longitude: parseFloat(data.longitude),
                    latitude: parseFloat(data.latitude)
                });
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
            setLocationLoading(false);
        });
    },[locationInputs]);

    useEffect(() => {
        getLocationApi(false);
    },[]);

    const saveStreamLocation = useCallback(async (location, stackName) => {
        let result = false;
        if (location.enabled_location && location.latitude !== undefined && location.longitude !== undefined) {
            location.longitude = round5(location.longitude.toString());
            location.latitude = round5(location.latitude.toString());
        }
        let jsonData = {
        "namespace": "default"
        }
        jsonData["location"] = location;
        await axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            result = true;
        })
        .catch(error => {
            result = false;
        });
        return result;
    }, []);

    const onSave = useCallback(async () => {
        let jsonData = Object.assign({}, locationInputs);
        let input = Object.assign({}, locationInputs);
        let lat = jsonData.latitude;
        let lon = jsonData.longitude;
        input.longitude = round5(input.longitude.toString());
        input.latitude = round5(input.latitude.toString());

        if((lat.length === 1 && lat === '-' && lat === '')){
            swal('Invalid Latitude.', { icon: 'error'});
            return;
        }
        if(lon.length === 1 && lon === '-' && lon === ''){
            swal('Invalid Longitude.', { icon: 'error'});
            return;
        }
        jsonData.enabled_location = deviceEnabled;
        jsonData.namespace= 'system'
        jsonData.latitude = round5(jsonData.latitude.toString());
        jsonData.longitude = round5(jsonData.longitude.toString());
        delete jsonData.access_token;
        delete jsonData.browser_location;

        const stackNames = Object.keys(stackList);
        let result = false;
        for (let index = 0; index < stackNames.length; index++) {
            result = false;
            try {
                const {location} = stackList[stackNames[index]];
                const prevLocation = prevStackList.current[stackNames[index]].location;
                if (isEqual(prevLocation, location)) {
                    result = true;
                    continue;
                }
                result = await saveStreamLocation(location, stackNames[index]);
                if (!result) {
                    break;
                }
            } catch (error) {
                console.error(error);
                swal("Failed to update geolocation.", { icon: "error"});
                break;
            }
        }
        if (stackNames.length !== 0 && !result) {
            swal("Failed to update geolocation.", { icon: "error"});
            return;
        }
        prevStackList.current = stackList;
        axios.patch(Constants.EDGE_API_ENDPOINT + '/system/device_location', jsonData)
            .then(res => {
                swal('Device location updated successfully.', { icon: 'success', timer: '3000'});
                setReadonly(true);
                setShowDeleteButton(true);
                getLocationApi(input.browser_location);
                setLocationInputs(input);
                const {streamsCheckbox, deviceCheckbox} = mapViewCheckboxes;
                localStorage.setItem('mapViewCheckboxesSettingsPage', JSON.stringify({deviceCheckbox, streamsCheckbox}));
            })
            .catch(error => {
                if(error.response){
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                }else{
                swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                }
        });
    }, [mapViewCheckboxes, deviceEnabled, stackList, locationInputs, saveStreamLocation])

      const leafletMarkerDragger = useCallback((points) => {
        const data = {longitude: round5(points.lng), latitude: round5(points.lat)};
        setLocationInputs(prev => {
            return {...prev, ...data};
        });
        setLeafletInputs(prev => {
            return {...prev, ...data};
        });
      },[]);

      const leafletDoubleClick = useCallback((points) => {
        const data = {longitude: round5(points.lng), latitude: round5(points.lat)};
        setLocationInputs(prev => {
            return {...prev, ...data};
        });
        setLeafletInputs(prev => {
            return {...prev, ...data};
        });
      },[]);

      const onCheckboxcallback = useCallback((value) => {
        let data = {...locationInputs, ['center_map']: value};
        setLocationInputs(data);
        setLeafletInputs(data);
      },[locationInputs]);

      const getDeviceId = useCallback(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/display/left_menu/all')
        .then(res => {
          setDeviceId(res.data.data.device_id);
        })
        .catch(error => {
          console.error(error);
        });
      },[]);

      useEffect(()=>{
        getDeviceId()
    },[])

    useEffect(() => {
        getStreamList();
    }, []);

    function getStreamList(){
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        
        client.get('/stacks/all')
        .then(res => {
            const stacks = res.data["data"]["stacks"];
            Object.keys(stacks).map((stack_name) => {
                if(stacks[stack_name]["action"] === "DeleteStack"){
                    delete stacks[stack_name];
                }
            });
            setStackList(stacks);
            prevStackList.current = stacks;
        }).catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        }).finally(() => {
            setStackListLoaded(true);
        });
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/status/all')
        .then(res => {
            var runningCount = 0, recoveringCount = 0, disabledCount = 0, failedCount = 0, pausedCount = 0;
            const stacks = res.data["data"]["stacks"];
            Object.keys(stacks).map((stack_name) => {
                let statckStatus = stacks[stack_name]["running_status"]["status"];
                if(statckStatus === "running"){
                    runningCount++;
                } else if(statckStatus === "disabled"){
                    disabledCount++;
                } else if(statckStatus === "paused"){
                    pausedCount++;
                } else if(statckStatus === "recovering" || statckStatus === "desired"){
                    recoveringCount++;
                } else if(statckStatus === "failed"){
                    failedCount++;
                }
            });
            setStreamStatusData(res.data.data)
        }).finally (() => {
            setStreamStatusLoaded(true);
        });
    }

    const handleDeviceLocationToggle = useCallback((e) => {
        if (readOnly) return;
        setDeviceEnabled(e);
        if (e) {
            if (localStorage.getItem('mapViewCheckboxesSettingsPage') !== null) {
                setMapViewCheckboxes(JSON.parse(localStorage.getItem('mapViewCheckboxesSettingsPage')));
            }
        } else {
            setMapViewCheckboxes(prev => { return {...prev, deviceCheckbox: false} });
        }
    }, [readOnly]);

    const updateStackList = useCallback((location, stack_name) => {
        setStackList(prev => {
            let tempPrev = cloneDeep(prev);
            let tempList = tempPrev[stack_name];
            let loc = {...tempList.location, ...location};
            tempList.location = loc;
            return {...tempPrev, [stack_name]: tempList};
        });
    }, []);

    const onMapDragEnd = useCallback(() => {
        setMapViewCheckboxes(initialMapViewCheckboxes);
        localStorage.setItem('mapViewCheckboxesSettingsPage', JSON.stringify({deviceCheckbox: false, streamsCheckbox: false}));
    }, [initialMapViewCheckboxes]);

    const onStreamCheckbox = useCallback((e) => {
        const {checked} = e.target;
        setMapViewCheckboxes(prev => {
            localStorage.setItem('mapViewCheckboxesSettingsPage', JSON.stringify({...prev, streamsCheckbox: checked}));
            return {...prev, streamsCheckbox: checked};
        });
        if (localStorage.getItem('keepRedirectedDeviceMapView') !== null) {
            localStorage.removeItem('keepRedirectedDeviceMapView');
        }
    }, [readOnly]);

    const onDeviceCheckbox = useCallback((e) => {
        const {checked} = e.target;
        setMapViewCheckboxes(prev => {
            localStorage.setItem('mapViewCheckboxesSettingsPage', JSON.stringify({...prev, deviceCheckbox: checked}));
            return {...prev, deviceCheckbox: checked};
        });
        if (localStorage.getItem('keepRedirectedDeviceMapView') !== null) {
            localStorage.removeItem('keepRedirectedDeviceMapView');
        }
    }, [readOnly]);

    useEffect(() => {
        if (!isEqual(prevStreamStatusDataRef.current, streamStatusData) || !isEqual(stackList, prevStackListRef.current)) {
            const length = getMarkersListLength(stackList, streamStatusData);
            setStackListLength(length);
            prevStreamStatusDataRef.current = streamStatusData;
            prevStackListRef.current = stackList;
        }
    }, [stackList, streamStatusData]);

    useEffect(() => {
        if (isMount && stackListLoaded && streamStatusLoaded) {
            if (stackListLength > 0 && localStorage.getItem('mapViewCheckboxesSettingsPage') !== null) {
                const {streamsCheckbox = false, deviceCheckbox = false} = JSON.parse(localStorage.getItem('mapViewCheckboxesSettingsPage'));
                setMapViewCheckboxes({streamsCheckbox, deviceCheckbox: (deviceEnabled && deviceCheckbox)});
                setIsMount(false);
            } else if (localStorage.getItem('mapViewCheckboxesSettingsPage') !== null) {
                const {deviceCheckbox = false} = JSON.parse(localStorage.getItem('mapViewCheckboxesSettingsPage'));
                setMapViewCheckboxes({streamsCheckbox: false, deviceCheckbox: (deviceEnabled && deviceCheckbox)});
                setIsMount(false);
            }
        }
    }, [isMount, stackListLength, deviceEnabled, stackListLoaded, streamStatusLoaded]);

    useEffect(() => {
        if (isMount) return;
        const {streamsCheckbox, deviceCheckbox} = mapViewCheckboxes;
        let mapViewType = 'NoStreamDevice';
        if (streamsCheckbox && deviceCheckbox) {
            mapViewType = 'StreamDevice';
        } else if (!streamsCheckbox && deviceCheckbox) {
            mapViewType = 'Device';
        } else if (streamsCheckbox && !deviceCheckbox) {
            mapViewType = 'Stream';
        } else if (!streamsCheckbox && !deviceCheckbox) {
            mapViewType = 'NoStreamDevice';
        }
        setMapViewType(mapViewType);
    }, [mapViewCheckboxes, isMount]);

      return (
        <ContentDiv>
          <ResponsiveWrapper>
            <SectionHeader>
              <SectionLabel>Device Location</SectionLabel>
            </SectionHeader>
            <Loader isLoading={locationLoading && !stackListLoaded && !streamStatusLoaded} />
            {!locationLoading && stackListLoaded && streamStatusLoaded && locationInputs && selectMode && (
              <>
                <Row style={{ width: "auto", padding: "20px 0 10px" }}>
                  <Row>
                    {leafletInputs ? (
                      <Leaflet
                        deviceEnabled={deviceEnabled}
                        onMapDragEnd={onMapDragEnd}
                        leafletMarkerDragger={leafletMarkerDragger}
                        leafletDoubleClick={leafletDoubleClick}
                        locationInputs={leafletInputs}
                        stackList={stackList}
                        streamStatusData={streamStatusData}
                        updateStackList={updateStackList}
                        browserLocation={browserLocation}
                        onCheckboxcallback={onCheckboxcallback}
                        mapViewType={mapViewType}
                        readOnly={readOnly}
                      />
                    ) : null}
                  </Row>
                </Row>

                <ResponsiveRow style={{ width: "auto", padding: "0", maxWidth: "606px"}}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 10px"
                    }}
                  >
                    <CheckboxContainer isDisabled={stackListLength === 0}
                      style={{
                        margin: "5px 0 5px",
                      }}                      
                    >
                      <input
                        type="checkbox"
                        id="center_map"
                        name="center_map"
                        style={{ marginLeft: "0" }}
                        checked={mapViewCheckboxes.streamsCheckbox}
                        onChange={onStreamCheckbox}
                        disabled={stackListLength === 0}
                      />
                      &nbsp;
                      <CheckboxLabel>
                        Show all camera locations on the map
                      </CheckboxLabel>
                    </CheckboxContainer>
                    <CheckboxContainer isDisabled={!deviceEnabled}>
                      <input
                        type="checkbox"
                        id="center_map"
                        name="center_map"
                        style={{ marginLeft: "0" }}
                        disabled={!deviceEnabled}
                        checked={mapViewCheckboxes.deviceCheckbox}
                        onChange={onDeviceCheckbox}
                      />
                      &nbsp;
                      <CheckboxLabel>
                        Move the map to show the device location
                      </CheckboxLabel>
                    </CheckboxContainer>
                    <ResponsiveFieldset>
                      <legend>Device Location</legend>
                      <DeviceInputsContainer isReadOnly={readOnly}>
                        <ToggleContainer>
                          <SelectionControl
                            style={{ height: "auto" }}
                            id="enabled_location"
                            type="switch"
                            label={deviceEnabled ? "Enabled" : "Disabled"}
                            name="enabled_location"
                            labelBefore="true"
                            value={deviceEnabled}
                            checked={deviceEnabled}
                            onChange={handleDeviceLocationToggle}
                          />
                        </ToggleContainer>
                        <DeviceSettings isDisabled={!deviceEnabled}>
                          <DirectionInputs>
                            <div>
                              <TextFieldLabel style={{ width: "80px" }}>
                                Latitude
                              </TextFieldLabel>
                              <TextFieldSmall
                                id="latitude"
                                name="latitude"
                                title={locationInputs.latitude}
                                maxLength={8}
                                disabled={readOnly || !deviceEnabled}
                                value={
                                  (!deviceEnabled && locationInputs.latitude === undefined) ? "" : locationInputs.latitude
                                }
                                onChange={handleOnChange}
                                onBlur={onBlurInputs}
                                style={{ width: "150px", cursor: "pointer" }}
                              />
                            </div>
                            <div>
                              <TextFieldLabel style={{ width: "80px" }}>
                                Longitude
                              </TextFieldLabel>
                              <TextFieldSmall
                                id="longitude"
                                name="longitude"
                                title={locationInputs.longitude}
                                maxLength={9}
                                disabled={readOnly || !deviceEnabled}
                                value={
                                  (!deviceEnabled && locationInputs.longitude === undefined) ? "" : locationInputs.longitude
                                }
                                onChange={handleOnChange}
                                onBlur={onBlurInputs}
                                style={{ width: "150px", cursor: "pointer" }}
                              />
                            </div>
                          </DirectionInputs>
                          <FetchBrowserLocationDiv
                            isDisabled={!deviceEnabled || validateText(window.location.href)}
                          >
                            <div>
                              Fetch the geolocation from your browser{" "}
                              <span style={{ whiteSpace: "nowrap" }}>
                                (requiring Webgate/HTTPS)
                              </span>
                            </div>
                            <Button
                              id="browser_location"
                              primary
                              disabled={readOnly || !deviceEnabled}
                              onClick={getBrowserLocation}
                            >
                              Fetch
                            </Button>
                          </FetchBrowserLocationDiv>
                        </DeviceSettings>
                      </DeviceInputsContainer>
                    </ResponsiveFieldset>
                    <div style={{ clear: "both" }}>
                      <Column
                        style={{
                          display: "flex",
                          width: "100%",
                          marginLeft: "0",
                          padding: "10px 0",
                          justifyContent: "space-between",
                        }}
                      >
                        <SelectionControl
                          id="enable_cloud_upload"
                          type="switch"
                          label={
                            "Upload the location information to SCORER Cloud"
                          }
                          value={locationInputs.enable_cloud_upload}
                          checked={locationInputs.enable_cloud_upload}
                          name="enable_cloud_upload"
                          onChange={handleToggle}
                          labelBefore="true"
                          style={{ marginTop: "0px" }}
                        />
                      </Column>
                    </div>
                    {readOnly ? (
                      <ButtonWrapper>
                        <Button
                          style={{ margin: "0" }}
                          primary
                          onClick={editMap}
                        >
                          Edit
                        </Button>
                      </ButtonWrapper>
                    ) : (
                      <ButtonWrapper>
                        <Button
                          style={{ margin: "0" }}
                          primary
                          onClick={onSave}
                        >
                          Save
                        </Button>
                        <Button
                          style={{ margin: "0" }}
                          id="btnCancle"
                          danger
                          onClick={OnCancel}
                        >
                          Cancel
                        </Button>
                      </ButtonWrapper>
                    )}
                  </Row>
                </ResponsiveRow>
              </>
            )}
          </ResponsiveWrapper>
        </ContentDiv>
      );
};

export default Location;
