import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import * as Constants from './utils/Constants';

import ScheduleSetting from './components/ScheduleSetting';
import NetworkSettings from './components/NetworkSettings';
import Power from './components/Power';
import AddStream from './components/AddStream';
import ViewStream from './components/ViewStream';
import StreamList from './components/StreamList';
import DeviceLog from './components/DeviceLog';
import CurrentStatus from './components/CurrentStatus';
import Menus from './components/Menus';
import DeviceInfo from './components/DeviceInfo';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { Redirect } from 'react-router';
import { ReactComponent as LogoSVG } from './images/edgeLogo.svg';
import FTPServerSetting from './components/FTPServerSetting';
import NamingRules from './components/NamingRules';
import UploadSettings from './components/UploadSettings';
import Volumes from './components/Volumes';
import DeviceHealthStatus from './components/DeviceHealthStatus';
import Rdb from './components/Rdb';
import APITester from './components/APITester';
import Metabase from './components/Metabase';
import EmailClient from './components/EmailClient';
import WebRedirect from './components/WebRedirect';
import TimeSettings from './components/TimeSetting';
import Location from './components/Location';
import Project from './components/Project';
import DeviceReset from './components/DeviceReset';
import SafieCameraSettings from './components/SafieCameraSettings';
import MonitoringSettings from './components/MonitoringSettings';
import UpperMenuDisplay from './components/UpperMenuDisplay';
import {ReactComponent as EditSVG } from './images/edit.svg';
import Footers, { MenuSettingsPopup } from './components/Footers';
import Bugherd from './components/Bugherd';
import Popup from 'reactjs-popup';
import { overlayStyle } from './utils/Styles';
import { ReactComponent as LeftAngleSVG } from './images/LeftAngle.svg';
import { ReactComponent as RightAngleSVG } from './images/RightAngle.svg';
import { ReactComponent as NoteSVG } from './images/notes.svg';
import { ReactComponent as WiFiSVG } from './images/wifi.svg';

export const Menu = styled(Menus)`
    width: 100%;
    background-color: #1F303A;
    max-height: 100%;
    scrollbar-width: thin;
    scrollbar-color: #A8B5BD7D transparent;
    ::-webkit-scrollbar {
      height: 4px;
      width: 6px;
      border-radius: 10px;
      background: transparent;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #A8B5BD7D;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #A8B5BD7D;
    }
`;

const MenuToggleButton = styled.div`
  display: none;
  @media screen and (max-width: 680px) {
    width: 100%;
    padding: 10px 0 12px;
    font-size: 25px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1e292f;
    color: #a8b5bd;
    cursor: pointer;
    transition:  background .5s;

    &:hover {
      background: #4a9bb3;
      svg > path {
        fill: #000;
      }
    }
  }
`;

const MenuWrapper = styled.div`
  flex-grow: 1;
  height: 0px;

  @media screen and (max-width: 680px) {

    ${({ isMenuOpen, isFirstClick }) => !isMenuOpen ? css`
    
      & > .metismenu > .metismenu-container > .metismenu-item > .metismenu-link {
        font-size: 0;
        padding: 16px 0 16px;
      }
    
      & > .metismenu > .metismenu-container > .metismenu-item > .metismenu-container {
        display: none;
      }
    ` : isFirstClick === 2 && css`      
      & > .metismenu > .metismenu-container > .metismenu-item > .visible {
        opacity: 0;
        height: 0;
        padding: 0;
        animation: revealFromTop .6s ease-in-out .2s forwards;
      }
    `}
  }
`;

export const DeviceIDContainer = styled.div`
    width: 100%;
    height: 60px;
    background-color:#1E292F;
    padding-left: 15px;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
`;

export const HeaderTitle = styled.h3`
    margin: 0px;
    padding-top: 15px;
    color: #0097f1;
    text-align: center;
    font-size: 24px;
    height: 60px;
`;

export const ContentWrapper = styled.div`
    width: 86vw;
    height: 100%;
    background-color: #eef3fa;
    text-align: center;
    display: inline;
`;

const AppLogo = styled(LogoSVG)`
    height: 38px;
    object-fit: contain;
    cursor: pointer;
    flex-shrink: 0;
`;

const EditMenu = styled(EditSVG)`
  object-fit: contain;
  cursor: pointer;
  margin-right: 5px;
  width: 28px;
  height: 28px;
  margin-top: 12px;
  visibility: hidden;
`;

const DivEdit = styled.div`
  &:hover{
    ${EditMenu}{
      object-fit: contain;
      cursor: pointer;
      margin-right: 5px;
      width: 28px;
      height: 28px;
      margin-top: 12px;
      visibility: visible;
    }
  }
`;

const AppLogoDiv = styled.div`
    background-color: #1E292F;
    padding-bottom: 20px;
    float: left;
    /* fill or stroke: #fefefe; you could add styles here if you wanted but logos it doesn't make sense */
    
    @media screen and (max-width: 680px) {
      & > .deviceHealthStatus {
        opacity: 0;
        ${({ isMenuOpen }) => isMenuOpen ? css`
          animation: revealFromLeft .8s linear .2s forwards;
        ` : css`
          display: none;
        `}
      }
      & > .upperMenuDisplay {
        opacity: 0;
        ${({ isMenuOpen }) => isMenuOpen ? css`
          animation: revealFromLeft .8s linear .2s forwards;
        ` : css`
          display: none;
        `}
      }
    }
`;

const LeftSVG = styled(LeftAngleSVG)`
  width: 10px;
  height: auto;
  @media screen and (max-width: 720px) and (max-height:1520px) {
    margin:0px 0px 50px 1px;
  }
`;

const RightSVG = styled(RightAngleSVG)`
  width: 10px;
  height: auto;
  @media screen and (max-width: 720px) and (max-height:1520px) {
    margin:0px 0px 50px 0px;
  }  
`;

const NoteIcon = styled(NoteSVG)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const QRIcon = styled(WiFiSVG)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const Footer = styled.div`
  margin-top: 15px;
  background-color: #1E292F;
  
  & > .footerIcons {
    display: none;
  }

  @media screen and (max-width: 680px) {
    ${({ isMenuOpen }) => isMenuOpen && css`
      opacity: 0;
      animation: revealFromBottom .6s ease-in-out .1s forwards;
    `}

    & > .menuFooter {
      display: ${({ isMenuOpen }) => isMenuOpen ? 'block' : 'none'};
    }
    & > .footerIcons {
      display: flex;
      flex-direction: column;
      gap: 12px 0;
      ${({ isMenuOpen }) => !isMenuOpen ? css`
        opacity: 1;
        width: 100%;
        height: initial;
        padding: 15px 0;
      ` : css`
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0;
      `}
      align-items: center;
      justify-content: space-around;
      transition: opacity 1s, padding 1s, height 1s;
    }
  }
`;

const MenuContainer = styled.div`
  width: 16vw;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow-x: hidden;
  
  @media screen and (max-width: 1450px) {
    width: 245px;
  }
  
  @media screen and (max-width: 850px) {
    width: 225px;
  }

  @media screen and (max-width: 680px) {
    width: ${props => props.isMenuOpen ? '70%' : '50px'};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex; 
  
  @media screen and (max-width: 680px) {
    ${props => props.isMenuOpen && `
      z-index: -1;
    `}
  }
`;

const LogoContainer = styled.div`
  padding: 10px 0 0 8px;
  display: flex;
  gap: 0px 14px;
  align-items: center;
  user-select:none;
`

const LogoTextContainer = styled.div`
  @media screen and (max-width: 680px) {
    transition: clip-path .6s;
    ${({ isMenuOpen }) => isMenuOpen ? css`
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
      ` : css`
      clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
    `}
  }
`
const LogoTextOne = styled.div`
  margin: 2px 0 -1px;
  font-size: 13.5px;
  font-weight: 400;
  letter-spacing: .6px;
  color: #80adcc;
  opacity: .75;
`;

const LogoTextTwo = styled.div`
  font-size: 11px;
  letter-spacing: .6px;
  color: #6b95b3;
  opacity: .6;
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.8);
      background: transparent !important;
    }
  }
`;

function App() {
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [isMenuClick, setIsMenuClick] = useState(false);
  const [firstClick, setFirstClick] = useState(1);
  const [showWifiIcon, setShowWifiIcon] = useState(false);
  const [clickedTab, setClickedTab] = useState(0);
  const [prevModal, setPrevModal] = useState(false);
  let pathArray = window.location.pathname.split('/');
  const basename = pathArray[2];
  
  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: '510px',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const handleMenuClick = useCallback(() => {
    if (window.innerWidth <= 680) {
      setIsMenuClick(true);
      setFirstClick(prev => prev + 1);
    }
  }, []);

  const hideMenu = useCallback(() => {
    if(document.getElementsByClassName('metismenu-container visible')[1]){
      document.getElementsByClassName('metismenu-container visible')[1].previousElementSibling.click();
    }
    setIsMenuClick(false);
    setFirstClick(1);
  }, []);

  const showStream = () => {
    if (!preview) {
      showStreamList();
    }
  }

  const checkWifiIconDisplay = useCallback((show)=>{
    setShowWifiIcon(show);
  } ,[]);

  const isTabChange = useCallback((tab)=>{
    setClickedTab(tab);
  } ,[]);

  const openSettings = () => {
    setPrevModal(true);
  };

  const closePopup = () => {
    setPrevModal(false);
  };

  return (
    
    <BrowserRouter basename={window.location.pathname.includes('edgeproxy') ? '/edgeproxy/' + basename : ''}>
    <MainContainer isMenuOpen={isMenuClick}>
      <MenuContainer className="menu" isMenuOpen={isMenuClick}>
        <AppLogoDiv isMenuOpen={isMenuClick}>
        <div style={{'display': 'flex', 'justifyContent': 'space-between'}}>
          <LogoContainer onClick={showStream}>
            <AppLogo mode={preview} onClick={showStream} title="" />
            <LogoTextContainer isMenuOpen={isMenuClick}>
              <LogoTextOne>SCORER</LogoTextOne>
              <LogoTextTwo>Edge</LogoTextTwo>
            </LogoTextContainer>
          </LogoContainer>
          {/* <DivEdit>
            <EditMenu title='Customize' onClick={openSettings} />
          </DivEdit> */}
        </div>
          <DeviceHealthStatus />
          <UpperMenuDisplay openSettings={openSettings} mode={preview} />
        </AppLogoDiv>
        <MenuWrapper mode={preview} isMenuOpen={isMenuClick} isFirstClick={firstClick} onClick={handleMenuClick}>
          <Menu handleLinkChange={hideMenu} />
        </MenuWrapper>
        <Footer isMenuOpen={isMenuClick}>
          <div className='footerIcons' onClick={handleMenuClick}>
            <NoteIcon onClick={()=>{setClickedTab(0)}} />
            {Constants.AD_HW_VPU === 'MMAL' && showWifiIcon && <QRIcon onClick={()=>{setClickedTab(1)}} />}
          </div>
          <Footers mode={preview} previewData={previewData} openSettings={openSettings} isWifiShow={checkWifiIconDisplay} isTabChange={isTabChange} clickedTab={clickedTab} />
        </Footer>
        <MenuToggleButton onClick={isMenuClick ? hideMenu : handleMenuClick}>
          {isMenuClick ? <LeftSVG /> : <RightSVG />}
        </MenuToggleButton>
      </MenuContainer>
      <div className="contents" id='contents'>
        <Switch>
          <Route exact path={'/'} render={() => (
            <Redirect to={"/stream-viewer"} />
          )}/>
          <Route path={'/stream-viewer'} component={StreamList} />
          <Route path={ '/add-rtmp-stream'} component={AddStream} />
          <Route path={ '/add-rtsp-stream'}component={AddStream} />
          <Route path={ '/add-web-stream'}component={AddStream} />
          <Route path={ '/add-usb-stream'}component={AddStream} />
          <Route path={ '/add-rtp-stream'} component={AddStream} />
          <Route path={ '/add-ftp-stream'} component={AddStream} />
          <Route path={ '/add-safie-stream'} component={AddStream} />
          <Route path={ '/add-mlit-stream'} component={AddStream} />
          <Route path={ '/device-info'} component={DeviceInfo} />
          <Route path={ '/current-status'} component={CurrentStatus} />
          <Route path={ '/schedule'}component={ScheduleSetting} />
          <Route path={ '/network'} component={NetworkSettings} />
          <Route path={ '/device-log'} component={DeviceLog} />
          <Route path={ '/power'}component={Power} />
          <Route path={ '/view-stream'} component={ViewStream} />
          <Route path={ '/ftp-server'} component={FTPServerSetting} />
          <Route path={ '/naming-rules'}component={NamingRules} />
          <Route path={ '/cloud-uploader'} component={UploadSettings} />
          <Route path={ '/volumes'} component={Volumes} />
          <Route path={ '/rdb'} component={Rdb} />
          <Route path={ '/api-tester'} component={APITester} />
          <Route path={ '/metabase'} component={Metabase} />
          <Route path={ '/email-client'}component={EmailClient} />
          <Route path={ '/web-redirect'} component={WebRedirect} />
          <Route path={ '/time-settings'} component={TimeSettings} />
          <Route path={ '/location'} component={Location} />
          <Route path={ '/projects'} component={Project} />
          <Route path={ '/device-reset'} component={DeviceReset} />
          <Route path={ '/safie-camera-settings'} component={SafieCameraSettings} />
          <Route path={ '/monitoring-settings'} component={MonitoringSettings} />
          <Route path={ '/bugherd'} component={Bugherd} />
          </Switch>
          {
            prevModal && 
            <ResponsivePopupContainer>
              <Popup modal closeOnDocumentClick={false} open={prevModal} onClose={closePopup} {...{ overlayStyle, contentStyle }}>
                <MenuSettingsPopup closePopup={closePopup} checkWifiIconDisplay={checkWifiIconDisplay} isTabChange={isTabChange} clickedTab={clickedTab}  /> 
              </Popup>
            </ResponsivePopupContainer>
          }
      </div>
    </MainContainer>
    </BrowserRouter>
  );
}

function showStreamList() {
  window.location.href = '/stream-viewer';
}

export default App;
