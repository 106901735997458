import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, CenterDiv, Label, TableContainer, SelectField, MdnsIpButton, Td, Th, overlayStyle, PopupContainer, TextFieldLabel, TextFieldSmall, PopupButtons, MandatoryLabel, LinkIcon, Link1 } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { generateRandomPassword, isEmpty } from '../utils/Utils';
import SelectOptions from '../components/SelectOptions';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import PasswordField from './PasswordField.js';
import ls from 'local-storage';
import styled from 'styled-components';
import axiosRetry from 'axios-retry';
import RDBLink from './RdbLink';
import { convertToTimeZone } from 'date-fns-timezone';
import { format, toDate } from 'date-fns';


const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ResponsiveTabContent = styled.div`
  padding: 5px 16px 10px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: #A8B5BD7D transparent;
  ::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #6262627d;
    border-radius: 10px;
  }
`;

const ResponsiveTabs = styled(Tabs)`
  @media screen and (max-width: 500px) {
    width: 320px;
  }
`;

const ResponsiveMandatoryLabel = styled(MandatoryLabel)`
  @media screen and (max-width: 500px) {
    width: 115px;
  }
`;

const ResponsiveTextFieldLabel = styled(TextFieldLabel)`
  @media screen and (max-width: 500px) {
    width: 115px;
  }
`;

const ResponsiveTextFieldSmall = styled(TextFieldSmall)`
  @media screen and (max-width: 500px) {
    width: 160px;
  }
`;

const ResponsiveSelectField = styled(SelectField)`
  margin-top: 10px;
  width: 205px;
  @media screen and (max-width: 500px) {
    width: 160px;
  }
`;

const PasswordFieldContainer = styled.div`
  width: 205px;
  @media screen and (max-width: 500px) {
    width: 160px;
  }
`;

const ResponsiveFieldset = styled.fieldset`
  margin-top: 16px;
  margin-left: -1px;
  @media screen and (max-width: 500px) {
    width: 286px;
  }
  @media screen and (max-width: 415px) {
    width: 276px;
  }
`;

const NetworkAccessContainer = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
  }
`;

const ResponsiveRow = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 217px;
  }
  & > ${MandatoryLabel} {
    width: 253px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
    }
  }
`;

const ResponsiveRow1 = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 240px;
    @media screen and (max-width: 400px) {
      width: 79px;
    }
  }
  & > ${MandatoryLabel} {
    width: 253px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
    }
  }
`;

const ResponsiveRow2 = styled(Row)`
  display: flex;
  align-items: baseline;
  & > ${TextFieldSmall} {
    flex-grow: 1;
    width: 100%;
  }
  & > div > ${TextFieldSmall}{
    width: 100%;
  }
  & > div > ${SelectField} {
    width: 217px;
  }
  & > ${MandatoryLabel} {
    width: 253px;
    padding: 0px 0px 0px 23px;
    flex-shrink: 0;
    @media screen and (max-width: 680px) {
      width: 175px;
      padding: 0px 0px 0px 17px;
    }
  }
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.9);
      background: transparent !important;
    }
    @media screen and (max-width: 480px) {
      transform: scale(0.8);
    }
    @media screen and (max-width: 450px) {
      height: 600px !important;
    }
    @media screen and (max-width: 400px) {
      transform: scale(0.75);
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NetworkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const HostPortContainer = styled.div`
  display: flex;
  margin-right: 3px;
  padding-left: 2px;
`;

const Table = styled.table`
  border-collapse: collapse; 
  table-layout: fixed;
  min-width: 100%;
`;

const FieldSetInnerContainer = styled.div`
  padding-bottom: 15px;
`;

const FieldSet=styled.fieldset`
  width:100%;
  margin-top: 15px;
  padding: 0px 10px 10px 10px;
  min-width:0px;
`;

const TextFieldLabel1 = styled(Label)`
  width: 190px;
  margin-top: 25px;
  @media screen and (max-width: 450px) {
    width: 190px;
    max-width: 245px;
  }
  
`;

const SectionHeaderRDB=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

export const OnRefreshContextRDB = React.createContext({
  onRefresh: false
});

function between(x, min, max) {
  return x >= min && x <= max;
}

 const generateDeviceRam = () => {
  axios.get(Constants.EDGE_API_ENDPOINT + '/devices/deviceinfo')
  .then(res => {
    let systemInfo = res.data.data;
    let value = systemInfo.system.ram.indexOf(',') !== -1 ? systemInfo.system.ram.substr(7, systemInfo.system.ram.indexOf(',')-7) : systemInfo.system.ram;
    let formattedData = Number(parseFloat(value.replace('MB','')).toFixed(0)).toLocaleString('en', {minimumFractionDigits: 0});
    window.localStorage.setItem('device_ram', formattedData.replace(',',''));
  })
  let ram = window.localStorage.getItem('device_ram');
  let GBram = parseInt(ram) / 1024;
  let result = '';
  if(Math.floor(GBram) <= 4) {
    result =parseInt(8, 10);
  } else if(between(GBram, 4, 8)) {
    result = parseInt(16, 10);
  } else if(between(GBram, 8, 16)) {
    result = parseInt(32, 10);
  } else if(between(GBram, 16, 32)) {
    result = parseInt(64, 10);
  }else if(between(GBram, 32, 64)) {
    result = parseInt(128, 10);
  } else if(between(GBram, 64, 128)) {
    result = parseInt(256, 10);
  } else if(Math.floor(GBram) >= 128) {
    result = parseInt(512, 10);
  }else{
    result = parseInt(16, 10);
  }
  return result.toString();
};

const Rdb = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [rdbData, setRdbData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [loading, setLoading] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [CurTimezone, setCurTimeZone]=useState();
  const [standaredTZ, setStandardTZ]=useState();
  const [retry,setRetry]=useState(false)
  const deviceIP = ls.get('device_ip') === undefined ? window.location.hostname : ls.get('device_ip');
  const deviceID = ls.get('device_id') === undefined ? '' : ls.get('device_id') + '.local';
  let pathArray = window.location.pathname.split('/');
  const basename = pathArray[2];

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '26%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };
  
  const nwNameStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };

  const hostStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '350px'
  };

  const editInstance = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewInstance = (event) => {
    setEditPopup(false);
    // setSelectedInstance(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let data = Object.assign({}, rdbData.stacks[name].services.rdb1);
    data.instance_id = name;
    data.nickname = rdbData.stacks[name].nickname;
    setSelectedInstance(data);
    setEditPopup(true);
  };

  const handleHostChange = (instName, port) => {
    let btnElement = document.getElementById(instName + '_mdns_button');
    let hostElement = document.getElementById(instName + 'host_port');
    if(btnElement.innerText === 'mDNS'){
      hostElement.innerText = deviceIP +':'+ port;
      btnElement.innerText = 'IP';
    } else {
      hostElement.innerText = deviceID +':'+ port;
      btnElement.innerText = 'mDNS';
    }
  };

  const enableToggle = (value, event) => {
    let rdbName = event.target.name;
    var actionLabel = value ? "enable" : "disable";

    var jsonData = {
      'namespace': Constants.NAMSPACES.SDK,
      'type': Constants.SDK_SERVICE_TYPES.RDB_MYSQL,
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };

    var msg = "\n\n Are you sure you want to " + actionLabel + " this instance?";
    if (value) {
      axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', jsonData)
        .then(res => {
          swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      swal({
        text: msg,
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', jsonData)
            .then(res => {
              swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      })
    }
  };

  const byteToMegaByte=(byteValue)=>{
    let convertByteToMegabyte=byteValue/1024**2;
    return convertByteToMegabyte;
  }

  const getRdb = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
         setRetry(true)
      } else if(retryCount === 15) {
        setRetry(false)
        swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
      }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/sdk/rdb/all')
      .then(res => {
        let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            if(stackData['services']['rdb1']['host_cache_size'] === 'auto') {
              stackData['services']['rdb1']['hosts'] = 'auto';
              stackData['services']['rdb1']['host_cache_size'] = 'auto';
            } else {
              stackData['services']['rdb1']['hosts'] = 'custom';
              stackData['services']['rdb1']['host_cache_size'] = stackData['services']['rdb1']['host_cache_size'];
            }
            stackData['services']['rdb1']['innodb_buffer_pool_size'] = byteToMegaByte(stackData['services']['rdb1']['innodb_buffer_pool_size'])
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            // let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            // let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return dateFormatB - dateFormatA;
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.rdb1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedInstance(instData);
          setEditPopup(true);
        }

        client.get('/sdk/rdb/status/all')
          .then(res => {
            let rdbStatusData = res.data.data;
            if (rdbStatusData && 'stacks' in rdbStatusData) {
              let rdbStatusList = rdbStatusData.stacks;
              Object.keys(rdbStatusList).map((rdbName) => {
                if (rdbName in data.stacks) {
                  data.stacks[rdbName].status = rdbStatusList[rdbName];
                }
                return rdbName;
              });
              setRdbData(data);
            } else {
              setRdbData(data);
            }
            setLoading(false);
            setOnRefresh(false);
          })
          .catch(err => {
            console.log(err);
            setRdbData(data);
            setOnRefresh(false);
            setLoading(false);
          });
      }).catch(error => {
        console.log(error);
        setRdbData([]);
        setLoading(false);
      });
  },[]);

  const refreshInstances = (event) => {
    setLoading(true);
    setOnRefresh(true);
    document.getElementById('btnRefrehInstance').textContent = 'Refreshing...';
    getRdb();
  };

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  function getRDBVersionDisplayName(version) {
    let rdbVersion = '-';
    const rdbVersions = Constants.AD_HW_VPU === 'MMAL' ? Constants.RDB_VERSION_RP4 : Constants.RDB_VERSION;
    rdbVersions.map((obj) => {
      if (obj.value === version) {
        rdbVersion = obj.name;
      }
      return obj;
    });
    return rdbVersion;
  }

  useEffect(() => {
    if (document.getElementById('btnRefrehInstance')) {
      document.getElementById('btnRefrehInstance').textContent = 'Refresh';
    }
  }, [rdbData]);

  useEffect(() => {
    generateDeviceRam();
    getRdb();
  }, [getRdb, generateDeviceRam]);

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };


  return (
    <ContentDiv>
      { (rdbData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
      }
      {rdbData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <Row>
            <SectionHeader>
              <SectionLabel>Relational Database</SectionLabel>
            </SectionHeader>
          </Row>
          <Row>
            <ActionButtonContainer>
              { rdbData.stacks && Object.keys(rdbData.stacks).length > 0 &&
                <Button primary id="btnEditInstance" name="btnEditInstance" onClick={() => editInstance()}>Edit</Button>
              }
              {rdbData.stacks && Object.keys(rdbData.stacks).length > 0 &&
                <Button primary id="btnRefrehInstance" name="btnRefrehInstance" disabled={loading} onClick={() => refreshInstances()}>Refresh</Button>
              }
              <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()}>Add</Button>
              {
                modalOpen &&
                <ResponsivePopupContainer>
                  <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                    <RdbPopup isEditPopup={isEditPopup} closePopup={closePopup} selected_rdb={isEditPopup ? selectedInstance :undefined} rdbData={rdbData} />
                  </Popup>
                </ResponsivePopupContainer>
              }
            </ActionButtonContainer>
          </Row>
          {rdbData.stacks && Object.keys(rdbData.stacks).length > 0 &&
            <TableLayout>
              <Table>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th></Th>
                  <Th style={{'text-align': 'left'}}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  <Th>Version</Th>
                  <Th>Created</Th>
                  <Th>Access</Th>
                  <Th>Host:Port<br/>(Docker Network)</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>
                { Object.keys(rdbData.sorted_list).map((index) => {
                  let instName = rdbData.sorted_list[index].name;
                  let stackData = rdbData.sorted_list[index].services.rdb1;
                  let rdbName = stackData.instance_id;
                  let nickname =  rdbData.stacks[instName].nickname ?  rdbData.stacks[instName].nickname : '';
                  let formattedDate = rdbData.stacks[instName].created_time.split(' ');
                  var newDate = new Date(formattedDate*1000);
                  let calculatedDate = getDateFormate(newDate,CurTimezone);
                  if(calculatedDate === undefined){
                    return ;
                  }
                  let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
                  let time = format(new Date(calculatedDate), 'HH:mm:ss');
                  let enabled = rdbData.stacks[instName].enabled;
                  let rdbStatus = 'recovering';
                  if ('status' in rdbData.stacks[instName]) {
                    rdbStatus = rdbData.stacks[instName].status.running_status.status;
                  }
                  let rdbVersion = getRDBVersionDisplayName(stackData.version);
                  let networkAccess = Constants.RDB_NETWORK_ACCESS[stackData.network_access];
                  let host = '-';
                  let port = '-';
                  let adminerServerParam = Constants.RDB_DEFAULT_HOST + ':' + Constants.RDB_DEFAULT_PORT;
                  if(stackData.network_access === 'deny_all'){
                    host = Constants.RDB_DEFAULT_HOST;
                    port = Constants.RDB_DEFAULT_PORT;
                    adminerServerParam = Constants.RDB_DEFAULT_HOST + ':' + port;
                  } else if(stackData.network_access === 'allow_all'){
                    host = deviceID;
                    port = stackData.port;
                    adminerServerParam = deviceID + ':' + port;
                  } else if(stackData.network_access === 'allow_inside_device'){
                    host = rdbName;
                    port = Constants.RDB_DEFAULT_PORT;
                    adminerServerParam = rdbName + ':' + port;
                  }
                  let path = '';
                  if(window.location.pathname.includes('/edgeproxy')) {
                    path = window.location.host + '/edgeproxy/' + basename;
                  } else {
                    path = window.location.host;
                  }

                  return (
                    <tr key={index}>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} name="select_radio" defaultChecked={index==='0'} value={rdbName} onClick={() => radioButtonOnChange(rdbName)}></input></Td>
                      <Td style={{'width': '10%'}}>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div style={instNameStyle} title={nickname}>{nickname}</div>
                            <div style={instNameStyle} title={instName}>({instName})</div>
                        </div>
                      </Td>
                      <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}>
                        {rdbStatus === '-' ?
                          '-' :
                          <label className={rdbStatus}>
                            {getDisplayLabel(Constants.STATUS_TYPES, rdbStatus)}
                          </label>}
                      </Td>
                      <Td>{rdbVersion}</Td>
                      <Td>{date}<br />{time} {standaredTZ}</Td>
                      <Td>{networkAccess}</Td>
                      <Td>
                        <Container>
                          <HostPortContainer id={instName + 'host_port'}><div id={'host_port'} style={hostStyle}>{host}:{port}</div></HostPortContainer>
                          { stackData.network_access === 'allow_all' &&
                          <MdnsIpButton primary id={instName + '_mdns_button'} className={'small-button'} onClick={() => handleHostChange(instName, port)}>mDNS</MdnsIpButton>
                          }
                          </Container>
                        <NetworkContainer>(<div style={nwNameStyle}>{instName + Constants.NETWORK_CONTAINER_SUFFIX}</div>)</NetworkContainer>
                      </Td>
                      <Td>
                        { stackData.enable_adminer ? 
                        <OnRefreshContextRDB.Provider value={{ onRefresh }}>
                          <RDBLink enabled={enabled} port={rdbData.stacks[instName].services.adminer1.port} adminerServerParam={adminerServerParam} username={rdbData.stacks[instName].services.rdb1.root_username} rdbStatus={rdbStatus} />
                        </OnRefreshContextRDB.Provider> : <span style={{'color': 'gray'}}> Not Available</span> }
                      </Td>
                      <Td><SelectionControl type="switch" id={rdbName} name={rdbName} value={rdbData.stacks[instName].enabled} checked={rdbData.stacks[instName].enabled} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                    </tr>
                  ); 
                })}
              </Table>
            </TableLayout>
          }
          {rdbData.stacks && Object.keys(rdbData.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>
          }
        </Wrapper>
      }
    </ContentDiv>
  );
};

function getDefaultRDBData() {
  let defaultRDBData = Constants.RDB_DEFAULT_VALUE;
  defaultRDBData.root_password = generateRandomPassword(12);
  defaultRDBData.innodb_buffer_pool_size = generateDeviceRam();
  return defaultRDBData;
}

const RdbPopup = (props) => {
  const { isEditPopup, closePopup, selected_rdb, rdbData } = props;
  const [rdbSettings, setRdbSettings] = useState(selected_rdb === undefined ? getDefaultRDBData() : selected_rdb);

  useEffect(()=>{
    if(rdbSettings.instance_id === ''){
      if(rdbData.sorted_list.length !== undefined){
        setRdbSettings({...rdbSettings, instance_id:'testdb'+(rdbData.sorted_list.length+1)});
      } else {
        setRdbSettings({...rdbSettings, instance_id:'testdb1'});
      }
    }
  },[]);

  const handleCheckBoxChange = (event) => {
    setRdbSettings({ ...rdbSettings, [event.target.name]: event.target.checked });
  };

  const handleRadioChange = (event) => {
    event.persist();
    let data = { ...rdbSettings };
    data[event.target.name] = event.target.value;
    setRdbSettings(data);
  };

  const handleOnChange = (event) => {
    let blockSpecialChar = event.target.value.replace(/^_/, "").replace(/^-/, "").replace(/^ /, "").replace(/^0/, "").replace(/^:/, "").replace(/^,/, "").replace(/[^-.\w]/g, '');
    if (event.target.name === 'instance_id') {
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if (event.target.name === 'log_retention') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
      event.target.value = event.target.value.replace(/[^\w]/g, '');
    } else if ((event.target.name === 'root_username' || event.target.name === 'root_password' ) && event.target.value.includes(' ')) {     
      event.target.value = event.target.value.replace(/\s/g, '');    
    } else if(event.target.name === 'root_username'){
      if (event.target.value.charAt(0) === '.') {
        event.target.value = event.target.value.replace('.', '');
      } else if(event.target.value){
        event.target.value = blockSpecialChar;
      }
    } if ( event.target.name === 'root_password') {     
      event.target.value = event.target.value.replace(/[{}()~*?"'\\^$|#\s]/g, '');
    } else if ( event.target.name === 'metadata_locks_cache_size') {     
      event.target.value = event.target.value.replace(/^0/, "")
      event.target.value = event.target.value.replace(/[^\w]/g, '');
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    } else if ( (event.target.name === 'query_cache_limit') || (event.target.name === 'query_cache_size') || (event.target.name === 'query_cache_min_res_unit') || (event.target.name === 'query_cache_type') || (event.target.name === 'host_cache_size') || (event.target.name === 'query_cache_wlock_invalidate') || (event.target.name === 'metadata_locks_cache_size')  || (event.target.name === 'innodb_buffer_pool_size') ) {     
      event.target.value = event.target.value.replace(/[^\w]/g, '');
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    }
     else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    }
    setRdbSettings({ ...rdbSettings, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    event.persist();
    if(event.target.name === 'hosts') {
      let data = {...rdbSettings}
      if(event.target.value === 'auto') {
        data.host_cache_size = 'auto';
        data.hosts = 'auto'
      } else if( event.target.value === 'custom')  {
        data.host_cache_size = '';
        data.hosts = 'custom'
      }
      setRdbSettings(data);
    } else {
      setRdbSettings({ ...rdbSettings, [event.target.name]: event.target.value });
    } 
  };

  const megaByteToByte=(megaByteValue)=>{
    let convertMBtoByte=megaByteValue*1024**2
    return convertMBtoByte;
  }

  const saveInstance = (event) => {
    let jsonData = Object.assign({}, rdbSettings);
    let rdbName = jsonData.instance_id;
    let action = isEditPopup ? 'updated' : 'added';
    if(jsonData.hosts === 'auto') {
      jsonData.host_cache_size = 'auto';
    } else {
      jsonData.host_cache_size = jsonData.host_cache_size;
    }

    if (isEmpty(rdbName)) {
      swal('Please enter instance id.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.root_username)) {
      swal('Please enter root user.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.root_password)) {
      swal('Please enter root password.', { icon: 'error' });
      return;
    }
    
    if (jsonData.host_cache_size === '') {
      swal('Please enter number of hosts.', { icon: 'error' });
      return;
    }
    if (jsonData.metadata_locks_cache_size === '') {
      swal('Please enter metadata locks cache size.', { icon: 'error' });
      return;
    }
    if (jsonData.innodb_buffer_pool_size === '') {
      swal('Please enter innodb buffer pool size.', { icon: 'error' });
      return;
    }
    if (jsonData.log_retention ==='') {
      swal('Please enter log retention day(s).', { icon: 'error' });
      return;
    }
    if (jsonData.log_retention <=0) {
      swal('Please enter log retention >=1 day(s).', { icon: 'error' });
      return;
    }
   
    if (jsonData.host_cache_size > 65536 && jsonData.hosts === 'custom') {
      swal('Please enter number of hosts less than or equal to 65536', { icon: 'error' });
      return;
    }
    if (jsonData.metadata_locks_cache_size > 1048576) {
      swal('Please enter metadata locks cache size less than or equal to 1048576', { icon: 'error' });
      return;
    }
    if (parseInt(jsonData.innodb_buffer_pool_size) > 10240) {
      swal('Please enter innodb buffer pool size less than or equal to 10240.', { icon: 'error' });
      return;
    }
    if (parseInt(jsonData.innodb_buffer_pool_size) < 1) {
      swal('Please enter innodb buffer pool size more than or equal to 1.', { icon: 'error' });
      return;
    }

    let finalJson = {};
    let innoDb=megaByteToByte(jsonData.innodb_buffer_pool_size)
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled = rdbData.stacks[rdbName] === undefined ? true : rdbData.stacks[rdbName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.RDB_MYSQL;
    finalJson.services = {};
    delete jsonData.nickname;
    delete jsonData.hosts;
    
    finalJson.services.rdb1 = Object.assign({}, jsonData);
    finalJson.services.rdb1.innodb_buffer_pool_size = innoDb.toString();
    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteRdb = (event) => {
    let rdbName = rdbSettings.instance_id;
    swal({
      text: 'Enter instance id to delete',
      content: 'input',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((name) => {
        // user has cancle the delete operation by clicking No
        if (name === null) {
          return;
        }
        if (name === rdbName) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all')
            .then(res => {
              swal('Instance deleted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              console.log('in error');
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        } else {
          return swal({ text: 'Instance id not match.', icon: 'error' });
        }
      });
  };

  return (
    <div>
      {rdbSettings !== undefined &&
        <PopupContainer>
          <SectionHeaderRDB style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Instance</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" style={{ 'margin': '0px' }} onClick={deleteRdb} >Delete</Button>
              </div>
            }
          </SectionHeaderRDB>
          <ResponsiveTabs defaultTab="general" style={{ 'padding': '6px' }}>
            <TabList>
              <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="advanced" className={'rwt__tab'}>Advanced</Tab>
            </TabList>
            <TabPanel tabId="general">
              <ResponsiveTabContent>
                <Row>
                  <ResponsiveMandatoryLabel>Instance ID</ResponsiveMandatoryLabel>
                  <ResponsiveTextFieldSmall id="instance_id" autoComplete="off" name="instance_id" value={rdbSettings.instance_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="63" />
                </Row>
                <Row>
                  <ResponsiveTextFieldLabel>Nickname</ResponsiveTextFieldLabel>
                  <ResponsiveTextFieldSmall id="nickname" autoComplete="off" name="nickname" value={rdbSettings.nickname} onChange={handleOnChange} maxLength="100" />
                </Row>
                <Row>
                  <ResponsiveTextFieldLabel>Version</ResponsiveTextFieldLabel>
                  <ResponsiveSelectField id="version" name="version" value={rdbSettings.version} onChange={handleSelectChange} disabled={isEditPopup}>
                    <SelectOptions items={Constants.AD_HW_VPU === 'MMAL' ? Constants.RDB_VERSION_RP4 : Constants.RDB_VERSION} />
                  </ResponsiveSelectField>
                </Row>
                <Row>
                  <ResponsiveMandatoryLabel>Root User</ResponsiveMandatoryLabel>
                  <ResponsiveTextFieldSmall id="root_username" autoComplete="off" name="root_username" value={rdbSettings.root_username} onChange={handleOnChange} maxLength="100" />
                </Row>
                <Row style={{display: 'flex', alignItems: 'baseline'}}>
                  <ResponsiveMandatoryLabel>Root Password</ResponsiveMandatoryLabel>
                  <PasswordFieldContainer>
                    <PasswordField id="root_password" type="text" width="100%" name="root_password" value={rdbSettings.root_password} setInput={handleOnChange} maxLength="100" />
                  </PasswordFieldContainer>
                </Row>
                <Row>
                  <ResponsiveFieldset>
                    <legend>Network Access</legend>
                    <Row style={{ 'marginTop': '15px' }}>
                      <NetworkAccessContainer>
                        <div>
                          <input type="radio" id="network_access" name="network_access" value="deny_all" checked={rdbSettings.network_access === 'deny_all' || false} onChange={handleRadioChange} /> {Constants.RDB_NETWORK_ACCESS.deny_all}
                        </div>
                        <div>
                          <input type="radio" id="network_access" name="network_access" value="allow_inside_device" checked={rdbSettings.network_access === 'allow_inside_device' || false} onChange={handleRadioChange} /> {Constants.RDB_NETWORK_ACCESS.allow_inside_device}
                        </div>
                        <div>
                          <input type="radio" id="network_access" name="network_access" value="allow_all" checked={rdbSettings.network_access === 'allow_all' || false} onChange={handleRadioChange} /> {Constants.RDB_NETWORK_ACCESS.allow_all}
                        </div>
                      </NetworkAccessContainer>
                    </Row>
                  </ResponsiveFieldset>
                </Row>
                <Row style={{ 'marginTop': '20px'}}>
                  <input type="checkbox" id="enable_adminer" name="enable_adminer" style={{ 'marginTop': '0', 'marginLeft': '0px' }} value={rdbSettings.enable_adminer} checked={rdbSettings.enable_adminer} onChange={handleCheckBoxChange} /> Enable Adminer
                </Row>
              </ResponsiveTabContent>
            </TabPanel>
            <TabPanel tabId="advanced">
            <ResponsiveTabContent>
                <FieldSet>
                    <legend>RDB Query</legend>
                    <FieldSetInnerContainer>
                      <ResponsiveRow1>
                        <MandatoryLabel>Host Cache Size</MandatoryLabel>
                        <div style={{'display':'flex', 'flexDirection': 'column'}}>
                        <SelectField id="hosts" name="hosts" sty value={rdbSettings.hosts} onChange={handleSelectChange}>
                          <option value='auto'>auto</option>
                          <option value='custom'>Custom</option>
                        </SelectField>
                        </div>
                      </ResponsiveRow1>
                      {rdbSettings.hosts === 'custom' &&
                      <ResponsiveRow2>
                        <MandatoryLabel>Number Of Hosts</MandatoryLabel>
                        <TextFieldSmall id="host_cache_size" autoComplete="off" maxLength='5' name="host_cache_size" value={rdbSettings.host_cache_size} onChange={handleOnChange} style={{'marginTop': '20px'}} />
                      </ResponsiveRow2>}
                      <ResponsiveRow>
                        <MandatoryLabel>Metadata Locks Cache Size (Bytes)</MandatoryLabel>
                        <TextFieldSmall id="metadata_locks_cache_size" autoComplete="off" maxLength='7' name="metadata_locks_cache_size" value={rdbSettings.metadata_locks_cache_size} onChange={handleOnChange} />
                      </ResponsiveRow>
                        {
                          !rdbSettings.innodb_buffer_pool_size && rdbSettings.innodb_buffer_pool_size.length !== 0 ? null : 
                          <ResponsiveRow>
                        <MandatoryLabel>Innodb Buffer Pool Size (MB)</MandatoryLabel>
                        <TextFieldSmall id="innodb_buffer_pool_size" autoComplete="off" maxLength='5' name="innodb_buffer_pool_size" value={rdbSettings.innodb_buffer_pool_size} onChange={handleOnChange} />
                      </ResponsiveRow>
                        }
                    </FieldSetInnerContainer>
                </FieldSet>
                <Row>
                  <MandatoryLabel style={{'marginTop': '20px'}}>Log Retention (in Days)</MandatoryLabel>
                  <TextFieldSmall id="log_retention" autoComplete="off" style={{'width': '220px'}} maxLength='4' name="log_retention" value={rdbSettings.log_retention} onChange={handleOnChange} />
                </Row>
                <Row>
                <TextFieldLabel1 style={{ 'color': 'grey', 'display': 'flex-wrap', 'width': '430px', 'marginTop': '20px'}}>Logs will expire and deleted after this amount of time to avoid the local storage full.</TextFieldLabel1>
                </Row>
                </ResponsiveTabContent>
            </TabPanel>
          </ResponsiveTabs>
          <Row>
            <PopupButtons>
              <Button style={{ margin: '0 0.5em' }} onClick={saveInstance} primary >Save</Button>
              <Button style={{ margin: '0 0.5em' }} id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Rdb;