import React, {useState, useRef, useMemo, useEffect, useCallback} from 'react'
import MapGL, { Marker, NavigationControl } from 'react-map-gl';
import ControlPanel from './ControlPanel';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { TabPanel } from 'react-web-tabs';
import { SelectionControl } from 'react-md';
import {Row, TextFieldLabel, TextFieldSmall, Button, RightDiv} from '../utils/Styles';
import styled from 'styled-components';
import axios from 'axios';
import swal from "sweetalert";
import * as Constants from '../utils/Constants';
import moment from 'moment-timezone';
import format from 'date-fns/format';
import markerIconGreen from '../images/green-camera.svg';
import markerIconGrey from '../images/grey-camera.svg';
import markerIconBlue from '../images/blue-camera.svg';
import markerIconRed from '../images/red-camera.svg';
import markerIconYellow from '../images/yellow-camera.svg';
import markerIconBlack from '../images/black-camera.svg';
import { isEqual } from 'lodash';
import {CircularProgress } from 'react-md';

const navStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px'
  };

  
const LoaderDiv = styled.div`
width: 20px;
height: 20px;
@media screen and (max-width: 425px) {
    width: 10px;
    height: 10px;
}
`;

  
  //eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

  const MapContainer = styled.div`
  margin: 20px;
  @media screen and (max-width: 500px) {
      overflow-x: auto;
  }
`;

const ResponsiveRow = styled(Row)`
    display: flex;
    // @media screen and (max-width: 1200px) {
    //     flex-direction: column;
    // }
  
`;

const DirectionInputs = styled.div`
    display: flex;
    gap: 0 20px;
    margin-bottom: 10px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;
const RightDivEdit=styled.div`
margin:0px 0px 0px 20px;
`;

  function Mapbox(props) {
    const {locationInputs, isViewPage, isSafie, showMap, stackName, readonly, recorderState, mapToken, streamStatus, updateCamera, locationData} = props;
    const [isSafieLocationAvailable, setIsSafieLocationAvailable] = useState(locationInputs.latitude !== 'NA');
    const [inputs, setInputs] = useState( isSafieLocationAvailable ? {...locationInputs, latitude: '35.65283', longitude: '139.83947'}: locationInputs);
    const [readOnly, setReadOnly] = useState(readonly);
    const [selectMode, setSelectMode] = useState('mapbox://styles/mapbox/streets-v11')
    const [safieDeviceName, setSafieDeviceName] = useState(recorderState.camera_name);
    const [loading, setLoading] = useState(false);
    const [viewport, setViewport] = useState({
        latitude: parseFloat(locationInputs.latitude),
        longitude: parseFloat(locationInputs.longitude),
        zoom: 8.5,
        bearing: 0,
        pitch: 0
      });
    const [marker, setMarker] = useState({
        latitude: parseFloat(locationInputs.latitude),
        longitude: parseFloat(locationInputs.longitude)
    });
    const [events, logEvents] = useState({ 
        latitude: parseFloat(locationInputs.latitude),
        longitude: parseFloat(locationInputs.longitude)
    });
    const center = {
        lat: 35.65283,
        lng: 139.83947,
    }
    const prevCameraRef = useRef();
    const prevLocationInputsRef = useRef(null);

    const markerIcon = () => {
      let icon = '';
      if(streamStatus === 'running') {
        icon = markerIconGreen;
      } else if (streamStatus === 'disabled') {
        icon = markerIconGrey;
      } else if (streamStatus === 'recovering') {
        icon = markerIconYellow;
      } else if (streamStatus === 'failed') {
        icon = markerIconRed;
      } else if (streamStatus === 'paused') {
        icon = markerIconBlue;
      } else if (streamStatus === 'desired') {
        icon = markerIconYellow;
      } else {
        icon = markerIconBlack;
      }
      return icon;
    }

    useEffect(() => {
      if(isSafie) {
        setTimeout(() => {
          prevCameraRef.current = recorderState.camera_name;
          setSafieDeviceName(recorderState.camera_name);
          if(updateCamera !== undefined || updateCamera !== '') {
            setSafieDeviceName(updateCamera);
            if(updateCamera !== '') {
              if(prevCameraRef.current !== updateCamera) {
              let data = {...inputs};
              data.latitude = '35.65283';
              data.longitude = '139.83947';
              setInputs(data);
              const center = {
                latitude: 35.65283,
                longitude: 139.83947,
                zoom: 8.5,
                bearing: 0,
                pitch: 0
              }
            setViewport(center);
            logEvents({
              longitude: 139.83947,
              latitude: 35.65283
            });
            setMarker({
                latitude: 35.65283,
                longitude: 139.83947
            })
            if(!isViewPage){
              props.parentCallback(data);
            }
            } else {
              setSafieDeviceName(updateCamera);
            }
          }
        }
        }, 2000);
      }
    },[updateCamera, isSafie, recorderState]);

    function round5(value) {
      return (Math.round(value * 1e5) / 1e5).toFixed(5);
    }

    useEffect(() => {
      if(!isEqual(prevLocationInputsRef.current, locationInputs)){
        prevLocationInputsRef.current = locationInputs;
        if (isViewPage) {
          setIsSafieLocationAvailable((locationInputs.latitude !== 'NA') && (locationInputs.longitude !== 'NA'));
        }
        if(locationInputs.enabled_location && (locationInputs.latitude !== 'NA') && (locationInputs.longitude !== 'NA')) {
            const center = {
                latitude:  parseFloat(locationInputs.latitude),
                longitude: parseFloat(locationInputs.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            }
            setViewport(center);
        } else {
          const center = {
            latitude: 35.65283,
            longitude: 139.83947,
            zoom: 8.5,
            bearing: 0,
            pitch: 0
          }
          setViewport(center);
        }
      }
    },[isViewPage, locationInputs])

    const onToggle = (value, event) => {
        if(value){
          let data = {...inputs, [event.target.id]: value};
          data.latitude = '35.65283';
          data.longitude = '139.83947';
          if(isSafie){
            data.sync_safie_location = false;
          }
          setInputs(data);
          const center = {
              latitude: 35.65283,
              longitude: 139.83947,
              zoom: 8.5,
              bearing: 0,
              pitch: 0
          }
          setViewport(center);
          logEvents({
            longitude: 139.83947,
            latitude: 35.65283
          });
          setMarker({
              latitude: 35.65283,
              longitude: 139.83947
          })
          if(!isViewPage){
            props.parentCallback(data);
          }
          setReadOnly(false);
          
        } else {
          let data = {...inputs, [event.target.id]: value};
          data.latitude = '';
          data.longitude = '';
          setInputs(data);
          if(isViewPage){
            if(!isViewPage){
              props.parentCallback(data);
          }
          let jsonData = {
            "namespace": "default"
          }
          jsonData["location"] = data;
          axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
              .then(res => {
                swal({ text: 'Geolocation disabled successfully.', icon: 'success' });
              })
              .catch(error => {
                  if(error.response){
                      var errorObj = error.response.data;
                      swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                  }else{
                      swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                  }
              });
        }
          }
    }

    const onCheckboxChange = (event) => {
      if(event.target.checked) {
        setLoading(true)
        let jsonData = {
          "status_code": "string",
          "message": "string",
          "data": {"device_id": safieDeviceName === '' ? recorderState.camera_name : safieDeviceName}
        }
        axios.post(Constants.EDGE_API_ENDPOINT + '/devices/safie/location', jsonData)
        .then((res) => {
          let dataSafie = res.data.data;
          if(dataSafie.gps_status === 'on') {
            let data = {...inputs, ['sync_safie_location']: true};
            setInputs(data);
            const center = {
              latitude: data.location.latitude,
              longitude: data.location.longitude,
              zoom: 8.5,
              bearing: 0,
              pitch: 0
            }
            setViewport(center);
            logEvents({
              longitude: data.location.longitude,
              latitude: data.location.latitude
            });
            setMarker({
                latitude: data.location.latitude,
                longitude: data.location.longitude
            })
            if(!isViewPage){
              props.parentCallback(data);
            }
            setIsSafieLocationAvailable(false);
            setLoading(false)
            swal({ text: 'Safie GPS API is available but the geolocation cannot be determined.', icon: 'error' });
            return;
          } else if(dataSafie.gps_status === 'off') {
            let data = {...inputs, ['sync_safie_location']: true};
            setInputs(data);
            setIsSafieLocationAvailable(false);
            setLoading(false)
            swal({ text: 'Safie GPS API is available but turned off. Please check the camera settings.', icon: 'error' });
            if(!isViewPage){
              props.parentCallback(data);
            }
            return;
          } else if(dataSafie.gps_status === 'active') {
            let data = {...inputs, 'sync_safie_location':  true};
            data.longitude =  round5(dataSafie.location.longitude);
            data.latitude =  round5(dataSafie.location.latitude);
            setInputs(data);
            setIsSafieLocationAvailable(true);
            setMarker({
              longitude: parseFloat(dataSafie.location.longitude),
              latitude: parseFloat(dataSafie.location.latitude)
            });
            setViewport({
                latitude:  parseFloat(dataSafie.location.latitude),
                longitude:  parseFloat(dataSafie.location.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            });
            logEvents({
                longitude: parseFloat(dataSafie.location.longitude),
                latitude: parseFloat(dataSafie.location.latitude)
            });
            if(!isViewPage){
              props.parentCallback(data);
            }
            // swal('Latitude: '+  round5(dataSafie.location.latitude)  +'\nLongitude: ' + round5(dataSafie.location.longitude) + '\nTime '+ dataSafie.time,{ timer: 4000 });
          } else {
            setLoading(false)
            swal({ text: 'Could not fetch device location, please check safie connector settings.', icon: 'error' });
            let data = {...inputs, ['sync_safie_location']: true};
            setIsSafieLocationAvailable(false);
            setInputs(data);
            if(!isViewPage){
              props.parentCallback(data);
            }
          }
        }) .catch(error => {
          if(error.response){
              var errorObj = error.response.data;
              swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
          }else{
              swal({text: "Unable to connect to the edge-api service" , icon: "error"});
          }
          setIsSafieLocationAvailable(false);
      });
    } else {
      let data = {...inputs, ['sync_safie_location']: event.target.checked};
      setInputs(data);
      if(!isViewPage){
        props.parentCallback(data);
      }
    }
    }

    const handleOnChange = (event) => {
      let data = { ...inputs };
      if(event.target.name === 'latitude') {
          event.target.value = event.target.value.replace(/[^.-\d]/g, '');
          if((event.target.value > 90  ||  event.target.value <= -90)) {
              return '';
          } else if(event.target.value.split('.').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.split('-').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
              event.target.value = event.target.value.replace('-', '');
          } else {
              data[event.target.name] = event.target.value;
              setInputs(data);
          }
      } else if(event.target.name === 'longitude') {
          event.target.value = event.target.value.replace(/[^.-\d]/g, '');
          if((event.target.value > 180  ||  event.target.value < -180)) {
              return '';
          } else if(event.target.value.split('.').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.split('-').length>2){
              event.target.value = event.target.value.replace(/\.+$/,"");
          } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
              event.target.value = event.target.value.replace('-', '');
          } else {
              data[event.target.name] = event.target.value;
              setInputs(data);
          }
      } else {
          data[event.target.name] = event.target.value;
          setInputs(data); 
          if(!isViewPage){
            props.parentCallback(data);
          }
      }
  };

  const onBlurInputs = (event) => {
      let data = {...inputs};
      if (event.target.id === 'latitude' && (event.target.value.length === 1 && (event.target.value === '-' || event.target.value === '.') )) {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          return;
        } else if (event.target.id === 'longitude' && (event.target.value.length === 1 && (event.target.value === '-' || event.target.value === '.'))) {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          return;
      } else if (event.target.id === 'longitude' && event.target.value === '') {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          data.longitude = 0;
          setInputs(data);
          setMarker({
            longitude: parseFloat(data.longitude),
            latitude: parseFloat(data.latitude)
          });
          setViewport({
              latitude: parseFloat(data.latitude),
              longitude: parseFloat(data.longitude),
              zoom: 8.5,
              bearing: 0,
              pitch: 0
          });
          logEvents({
              longitude: parseFloat(data.longitude),
              latitude: parseFloat(data.latitude)
          });
          if(!isViewPage){
            props.parentCallback(data);
          }
          return;
      } else if (event.target.id === 'latitude' && event.target.value === '') {
          document.getElementById(event.target.id).style.borderColor = 'lightgray';
          data.latitude = 0;
          setInputs(data);
          setMarker({
            longitude: parseFloat(data.longitude),
            latitude: parseFloat(data.latitude)
          });
          setViewport({
              latitude: parseFloat(data.latitude),
              longitude: parseFloat(data.longitude),
              zoom: 8.5,
              bearing: 0,
              pitch: 0
          });
          logEvents({
              longitude: parseFloat(data.longitude),
              latitude: parseFloat(data.latitude)
          });
          return;
      } else {
        if(data.latitude === '-' || data.latitude === '.') {
          document.getElementById('latitude').style.borderColor = 'lightgray';
          return;
      } else if(data.longitude === '-' || data.longitude === '.') {
          document.getElementById('longitude').style.borderColor = 'lightgray';
          return;
      } else {
        data[event.target.id] = round5(parseFloat(event.target.value)).toString();
        setInputs(data);
        setMarker({
          longitude: parseFloat(data.longitude),
          latitude: parseFloat(data.latitude)
        });
        setViewport({
            latitude: parseFloat(data.latitude),
            longitude: parseFloat(data.longitude),
            zoom: 8.5,
            bearing: 0,
            pitch: 0
        });
        logEvents({
            longitude: parseFloat(data.longitude),
            latitude: parseFloat(data.latitude)
        });
        if(!isViewPage){
          props.parentCallback(data);
        }
      }
      }
  }

  const onSaveLocation = () => {
    let location = {...inputs}
    let data = {...inputs}
    let lat = location.latitude;
        let lon = location.longitude;
        data.longitude = round5(data.longitude.toString());
        data.latitude = round5(data.latitude.toString());
        // if(lat === undefined || lat === '' || lat === '0.00000' || lat === '0.0000'|| lat === '0.000'|| lat === '0.00' || lat === '0.0' || lat === '0' || lat === 0 ){
        //     swal('Please enter latitude.', { icon: 'error'});
        //     return;
        // }
        // if(lon === undefined || lon === '' || lon === '0.00000' || lon === '0.0000'|| lon === '0.000'|| lon === '0.00'|| lon === '0.0'|| lon === '0' || lon === 0){
        //     swal('Please enter longitude.', { icon: 'error'});
        //     return;
        // }
        if((lat.length === 1 && lat === '-')){
            swal('Invalid Latitude.', { icon: 'error'});
            return;
        }
        if(lon.length === 1 && lon === '-'){
            swal('Invalid Longitude.', { icon: 'error'});
            return;
        }
    let jsonData = {
      "namespace": "default"
    }
    jsonData["location"] = location;
    axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
          swal("Stream location updated successfully.", { icon: "success"});
          setTimeout(function(){ window.location.reload() }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
  }

  useEffect(() => {
    if(locationData) {
      if(updateCamera !== '') {
        if(prevCameraRef !== updateCamera) {
          onSaveLocation();
        }
      }
    }
  },[locationData, updateCamera]);

  const onEdit = () => {
    setReadOnly(false);
  }

  const getMode = (childData) => {
    setSelectMode(childData);
  }

  const onMarkerDragStart = useCallback(event => {
    logEvents({ 
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
    });
  }, []);

  const onMarkerDrag = useCallback(event => {
    logEvents({ 
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
    });
  }, []);

  const onMarkerDragEnd = useCallback(event => {
    logEvents({
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
    });
    setMarker({
      longitude: event.lngLat[0],
      latitude: event.lngLat[1]
    });
    let data = {...inputs}
    data.longitude = round5(event.lngLat[0])
    data.latitude = round5(event.lngLat[1])
    setInputs(data);
  }, [inputs]);

    return (
      <TabPanel tabId="geoLocation">
        <ResponsiveRow style={{'flex-direction':'column',  'padding': '10px 10px 10px 2%'}}>
          <div style={{'display':'flex', 'justifyContent': 'flex-end', 'marginTop': '5px'}}>
            <SelectionControl style={{height: 'auto'}} id='enabled_location' type="switch" label={inputs.enabled_location ? "Enabled": "Disabled"} name="enabled_location" labelBefore="true" value={inputs.enabled_location} checked={inputs.enabled_location} onChange={onToggle} />
          </div>
         {inputs.enabled_location &&
         <>
         <div id="map">
           {showMap && 
             <MapContainer>
             <MapGL
                 {...viewport}
                 width={'100%'}
                 height={window.innerWidth <= 340 ? '350px' : '500px'}
                 mapStyle={selectMode}
                 onViewportChange={setViewport}
                 mapboxApiAccessToken={mapToken}
             >
                 <Marker
                     longitude={marker.longitude}
                     latitude={marker.latitude}
                     offsetTop={-20}
                     offsetLeft={-10}
                     draggable={isViewPage ? readOnly ? false : !inputs.sync_safie_location : !inputs.sync_safie_location }
                     onDragStart={onMarkerDragStart}
                     onDrag={onMarkerDrag}
                     onDragEnd={onMarkerDragEnd}
                 >
                     <img src={markerIcon()} />
                 </Marker>
                 <div className="nav" style={navStyle}>
                     <NavigationControl />
                 </div>
                 <ControlPanel events={events} mode={getMode} />
             </MapGL>
         </MapContainer>}
         </div>
         <DirectionInputs style={{'display': 'flex', 'marginLeft': '20px', 'marginTop': '20px'}}>
         <div>
             <TextFieldLabel style={{'width': '80px'}}>Latitude</TextFieldLabel>
             <TextFieldSmall id="latitude" name='latitude' title={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.latitude} maxLength={8}  value={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.latitude} disabled={readOnly || inputs.sync_safie_location} style={{'width': '150px', 'cursor':'pointer'}} onChange={handleOnChange} onBlur={onBlurInputs} />
         </div>
         <div>
             <TextFieldLabel style={{'width': '80px'}}>Longitude</TextFieldLabel>
             <TextFieldSmall id="longitude" name='longitude' title={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.longitude} maxLength={9} value={(inputs.sync_safie_location && !isSafieLocationAvailable) ? 'NA': inputs.longitude} disabled={readOnly || inputs.sync_safie_location} style={{'width': '150px', 'cursor':'pointer'}} onChange={handleOnChange} onBlur={onBlurInputs} /> 
         </div>
         {isSafie && 
          <div style={{'display': 'flex', 'alignItems':'baseline', 'marginTop': '25px'}}>
          { loading ?
            <>
            <CircularProgress style={{height:'18px',width:'18px',margin:'0 5px 0 0px'}} /> &nbsp; <label htmlFor="">Sync location using safie</label>
            </> :
             <>
         <input type="checkbox" checked={inputs.sync_safie_location} value={inputs.sync_safie_location} disabled={readOnly} name="sync_safie_location" id='sync_safie_location' onChange={onCheckboxChange} />&nbsp;Sync location using safie
              </>
          }
          </div>}
         </DirectionInputs>
         </>}
       </ResponsiveRow>
       <ResponsiveRow style={{'display':'flex', 'justifyContent': 'flex-end'}}>
       <RightDivEdit style={{'margin': '0 30px 0 0'}}>
         {isViewPage && inputs.enabled_location &&
         <div>
           {readOnly ? <Button id="editBroadcastererButton" primary onClick={onEdit}>Edit</Button> : 
           <div>   
             <Button primary onClick={onSaveLocation}>Save</Button>
             <Button onClick={() => window.location.reload()}>Cancel</Button>
           </div>}
         </div>}
     </RightDivEdit>
       </ResponsiveRow>
   </TabPanel>
    );
  
}

export default React.memo(Mapbox);
