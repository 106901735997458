import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Loader from './Loader';
import { Column, Row,  Wrapper, MessageLabel, SectionHeader, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { SelectionControl } from 'react-md';
import swal from 'sweetalert';
import styled from 'styled-components';
import axiosRetry from 'axios-retry';

const MessageContaier = styled.div`
  padding: 0 5px 1px 15px;
  width: 50%;
  @media screen and (max-width: 950px) {
    width: 80%;
  }
  @media screen and (max-width: 330px) {
    width: 90%;
  }
`;

const ContentDiv=styled.div`
  width: 100%;
  padding-bottom: 10px;

  & fieldset {
    min-width: 0;
  }
`;

const Bugherd = () => {
  const [bugherdMode, setBugherdMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const getMode = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 4,
      retryDelay: (retryCount, error) => {
          if(retryCount === 4) {
            swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
          }
        return 3000;
      },
      retryCondition: (error) => {
        return true;
      },
    });
    client.get('/system/bugherd')
      .then(res => {
        setBugherdMode(res.data.data.bugherd_enabled)
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  },[]);

  useEffect(() => {
    getMode();
  },[]);

  const handleToggle = (value) => {
    setBugherdMode(value)
    let jsonData={'bugherd_enabled': value};
      let modeLabel = value ? 'enabled' : 'disabled';
      let modeLabel1 = value ? 'enable' : 'disable';
      swal({
        text: 'Are you sure you want to ' + modeLabel1 +  ' the bugherd?',
        buttons: ['No', 'Yes'],
        dangerMode: true,
        icon: 'warning'
      })
        .then((isConfirm) => {
          if (isConfirm) {
            axios.put(Constants.EDGE_API_ENDPOINT + '/system/bugherd', jsonData)
              .then(res => {
                swal('Bugherd ' + modeLabel + ' successfully.', { icon: 'success', timer: '3000' });
                setTimeout(function () { window.location.reload(); }, 2000);
              })
              .catch(error => {
                if (error.response) {
                  var errorObj = error.response.data;
                  swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                } else {
                  swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                }
              });
          } else {
            setBugherdMode(!value)
          }
        });
  };

  return(
    <ContentDiv>
      <Wrapper>
        <Row>
            <SectionHeader>
              <SectionLabel>BugHerd</SectionLabel>
            </SectionHeader>
        </Row>
      <Loader isLoading={loading} />
      {!loading && 
        <>
          <Row style={{'marginLeft': '5px'}}>
            <Column style={{'paddingLeft': '0', width: 'max-content', display:'flex', alignItems: 'center'}}>
              <SelectionControl id='bugherd_enabled' type='switch' label={bugherdMode ? 'Enabled' : 'Disabled'} name='bugherd_enabled' labelBefore='true' value={bugherdMode} checked={bugherdMode} onChange={handleToggle} style={{'margin-left': '16px', 'marginTop': '10px'}} />
            </Column>
          </Row >
            <MessageContaier style={{'marginLeft': '5px'}}>
              <MessageLabel style={{'text-size-adjust': '100%'}}>
                <b>Note:</b> You additionally need to install the browser extension to use <a href='https://support.bugherd.com/hc/en-us/articles/204171360-BugHerd-Browser-Extensions' target='_blank'>BugHerd.</a><br/>
              </MessageLabel>
            </MessageContaier>
        </>}
      </Wrapper>
    </ContentDiv>
  );
};

export default Bugherd;