import { useEffect, useCallback, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { isEqual } from 'lodash';

function TabLeafletConsumer({ onDoubleClkPosition = () => {}, mapViewType, isViewPage, streamPosition = [], devicePosition = [], isStreamEnabled = false, stackName, onMapDragEnd = () => {}, getMapCurrentView = () => {}, }) {
  const map = useMap();
  const streamPositionRef = useRef();
  const devicePositionRef = useRef();
  const mapViewTypeRef = useRef();
  const isStreamEnabledRef = useRef(isStreamEnabled);
  const isProgramaticUncheck = useRef(false);
  const isProgramaticZoom = useRef(false);
  const isMount = useRef(true);
  const isMounted = useRef(true);

  // for map drag-end event
  const handleMapDragEnd = useCallback(() => {
    isProgramaticUncheck.current = true;
    onMapDragEnd();
    setTimeout(() => {
      isProgramaticUncheck.current = false;
    }, 200);
  }, [onMapDragEnd]);

  useEffect(() => {
    map.on('dragend', handleMapDragEnd);
    return () => {
      map.removeEventListener('dragend', handleMapDragEnd)
    }
  }, [map, handleMapDragEnd]);

  const getBoundingList = useCallback((mapViewType, streamLatLng, devicePosition, isStreamEnabled) => {
    let boundingList = [];
    if (mapViewType === 'StreamDevice') {
      if (devicePosition !== undefined) {
        boundingList.push(L.marker(devicePosition));
      }
      if (isStreamEnabled) {
        boundingList.push(L.marker(streamLatLng));
      }
    } else if (mapViewType === 'Stream') {
      if (isStreamEnabled) {
        boundingList.push(L.marker(streamLatLng));
      }
    } else if (mapViewType === 'Device') {
      if (devicePosition !== undefined) {
        boundingList.push(L.marker(devicePosition));
      }
    }
    return boundingList;
  }, []);

  const shouldBoundingUncheck = useCallback(() => {
    return new Promise((resolve, reject) => {
      let isUncheck = true;
      setTimeout(() => {
        const boundingList = getBoundingList(mapViewTypeRef.current, streamPositionRef.current, devicePositionRef.current, isStreamEnabledRef.current)
        if (boundingList.length !== 0) {
          const group = new L.featureGroup(boundingList);
          const glatlngBounds = group.getBounds();
          const glatlngCenter = glatlngBounds.getCenter();
          const mlatlngCenter = map.getBounds().getCenter();
          let mapZoom = map.getZoom();
          let maxBoundsZoom = map.getBoundsZoom(glatlngBounds);
          if (mapZoom <= maxBoundsZoom) {
            isUncheck = false;
            if (!isEqual(glatlngCenter, mlatlngCenter)) {
                const currentMapZoom = map.getZoom();
                map.fitBounds(glatlngBounds, {maxZoom: currentMapZoom});
            }
          } else {
            isUncheck = true;
          }
        }
        resolve(isUncheck);
      }, 100);
    });
  }, [map, getBoundingList]);

  // for map zoom-end event
  const handleMapZoomStart = useCallback(async () => {
    isProgramaticUncheck.current = true;
    if (!isProgramaticZoom.current) {
      const zoom = map.getZoom();
      const latLng = map.getCenter();
      if (isViewPage) {
        localStorage.setItem(`viewStreamMapView${stackName}`, {latLng, zoom});
      } else {
        localStorage.setItem('addStreamMapView', {latLng, zoom});
      }
      const isUncheck = await shouldBoundingUncheck();
      if (isUncheck) {
        onMapDragEnd();
      }
    }
    setTimeout(() => {
      isProgramaticUncheck.current = false;
    }, 200);
  }, [onMapDragEnd, shouldBoundingUncheck, map, isViewPage, stackName]);

  useEffect(() => {
    map.addEventListener('zoomend', handleMapZoomStart);
    return () => {
      map.removeEventListener('zoomend', handleMapZoomStart)
    }
  }, [map, handleMapZoomStart]);

  const handleLocalSave = useCallback(() => {
    let mapCurrentView = {latLng: map.getCenter(), zoom: map.getZoom()};
    if (!isMount.current) {
      if (isViewPage) {
        localStorage.setItem(`viewStreamMapView${stackName}`, JSON.stringify(mapCurrentView));
      } else {
        localStorage.setItem(`addStreamMapView`, JSON.stringify(mapCurrentView));
      }
    } else {
      isMount.current = false;
    }
    getMapCurrentView(mapCurrentView);
  }, [map, stackName, isViewPage, getMapCurrentView]);

  useEffect(() => {
    map.addEventListener('moveend zoomend', handleLocalSave);
    return () => {
      map.removeEventListener('moveend zoomend', handleLocalSave)
    }
  }, [map, handleLocalSave]);

  // for changing device location on double click in map
  const handleDoubleClick = useCallback((e) => {
    if (!isStreamEnabled) return;
    onDoubleClkPosition(e.latlng);
  }, [isStreamEnabled, onDoubleClkPosition]);

  useEffect(() => {
    map.on('dblclick', handleDoubleClick);
    return () => {
      map.removeEventListener('dblclick', handleDoubleClick)
    }
  }, [map, handleDoubleClick]);

  const setMapBounds = useCallback((streamLatLng = [], deviceLatLng = [], isboundDevice) => {
    if (isProgramaticUncheck.current) {
      return;
    }
    isProgramaticZoom.current = true;
    let boundingList = [];
    if (isboundDevice) {
      boundingList.push(L.marker(deviceLatLng));
    }
    if (isStreamEnabled && streamLatLng.length !== 0) {
      boundingList.push(L.marker(streamLatLng));
    }
    if (boundingList.length === 0) {      
      const defaultMapView = {latLng: L.latLng(38.16911, 137.79403), zoom: 5};
      let localView = JSON.parse(localStorage.getItem(`viewStreamMapView${stackName}`));
      if (!isViewPage) {
        localView = JSON.parse(localStorage.getItem('addStreamMapView'));
      }
      if (localView !== null) {
        map.setView(localView.latLng, localView.zoom);
        if (isViewPage) {
          localStorage.setItem(`viewStreamMapView${stackName}`, JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
        } else {
          localStorage.setItem(`addStreamMapView`, JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
        }
      } else {
        map.setView(defaultMapView.latLng, defaultMapView.zoom);
        if (!isViewPage) {
          localStorage.setItem(`addStreamMapView`, JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
        }
      }
      return;
    }
    const group = new L.featureGroup(boundingList);
    const glatlngBounds = group.getBounds();
    let prevMapZoom = map.getZoom();
    if (prevMapZoom === undefined || prevMapZoom > 5) {
      prevMapZoom = 5;
    }
    let localView = null;
    if (isViewPage) {
      localView = JSON.parse(localStorage.getItem(`viewStreamMapView${stackName}`));
    } else {
      localView = JSON.parse(localStorage.getItem('addStreamMapView'));
    }
    if (localView !== null) {
      prevMapZoom = parseInt(localView.zoom);
    }
    let maxBoundsZoom = map.getBoundsZoom(glatlngBounds);
    let maxZoom;
    if (prevMapZoom <= maxBoundsZoom) {
      maxZoom = prevMapZoom;
    } else {
      maxZoom = maxBoundsZoom;
    }
    if (!isMounted.current) {
      const isInside = map.getBounds().contains(glatlngBounds);
      if (!isInside) {
        map.fitBounds(glatlngBounds, {maxZoom});
      } else {
        if (map.getBounds().getCenter() !== glatlngBounds.getCenter()) {
          const currentMapZoom = map.getZoom();
          map.setView(glatlngBounds.getCenter(), currentMapZoom);
        }
      }
    } else {
      map.fitBounds(glatlngBounds, {maxZoom});
    }
    if (isViewPage) {
      localStorage.setItem(`viewStreamMapView${stackName}`, JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
    } else {
      localStorage.setItem(`addStreamMapView`, JSON.stringify({latLng: map.getCenter(), zoom: map.getZoom()}));
    }
    setTimeout(() => {
      isProgramaticZoom.current = false;
    }, 100);
  }, [map, isStreamEnabled, stackName, isViewPage]);

  useEffect(() => {
    if (!isStreamEnabled) {
      isStreamEnabledRef.current = undefined;
      return;
    }
  }, [isStreamEnabled]);

  useEffect(() => {
    return () => {
      if (localStorage.getItem('keepRedirectedMapView') !== null) {
        localStorage.removeItem('keepRedirectedMapView')
      }
    };
  }, []);

  const handleUncheckOnRedirection = useCallback((devicePosition, streamPosition) => {
    const boundingList = [];
    if (devicePosition) {
      boundingList.push(L.marker((devicePosition.map(item => parseFloat(item)))));
    }
    if (streamPosition) {
      boundingList.push(L.marker(streamPosition.map(item => parseFloat(item))));
    }
    if (boundingList.length > 0) {
      const group = new L.featureGroup(boundingList);
      const glatlngBounds = group.getBounds();
      const glatlngCenter = glatlngBounds.getCenter();
      const mlatlngCenter = map.getBounds().getCenter();
      const isInside = (Math.abs(Math.trunc(mlatlngCenter.lat) - Math.trunc(glatlngCenter.lat)) === 1) && (Math.abs(Math.trunc(mlatlngCenter.lng) - Math.trunc(glatlngCenter.lng)) === 1);
      if (!isInside) {
        onMapDragEnd();
      }
    } else {
      onMapDragEnd();
    }
    if (localStorage.getItem('keepRedirectedMapView') !== null) {
      localStorage.removeItem('keepRedirectedMapView')
    }
  }, [map, onMapDragEnd]);

  useEffect(() => {
    if (!isEqual(mapViewTypeRef.current, mapViewType) || !isEqual(streamPositionRef.current, streamPosition) || !isEqual(devicePositionRef.current, devicePosition) || !isEqual(isStreamEnabled, isStreamEnabledRef.current)) {
      streamPositionRef.current = streamPosition;
      devicePositionRef.current = devicePosition;
      mapViewTypeRef.current = mapViewType;
      isStreamEnabledRef.current = isStreamEnabled;

      const localRedirectedView = JSON.parse(localStorage.getItem('keepRedirectedMapView'));
      if (localRedirectedView !== null && window.location.search.includes('redirection=true') && isMounted.current) {
        const {keep, latLng, zoom} = localRedirectedView;
        if (keep) {
          isProgramaticZoom.current = true;
          map.setView(latLng, zoom);
          localStorage.setItem('keepRedirectedMapView', JSON.stringify({keep: true, latLng, zoom}));
          handleUncheckOnRedirection(devicePosition, streamPosition);
          setTimeout(() => {
            isProgramaticZoom.current = false;
          }, 100);
        }
        return;
      }
      isProgramaticUncheck.current = false;
      if (mapViewType === 'StreamDevice') {
        setMapBounds(streamPosition, devicePosition, true);
      } else if (mapViewType === 'Stream') {
        setMapBounds(streamPosition, [], false);
      } else if (mapViewType === 'Device') {
        setMapBounds([], devicePosition, true);
      } else if (mapViewType === 'NoStreamDevice') {
        setMapBounds([], [], false);
      }
    }
    if (isEqual(mapViewTypeRef.current, mapViewType)) {
      isMounted.current = false;
    }
  }, [map, mapViewType, streamPosition, devicePosition, isStreamEnabled, setMapBounds, onMapDragEnd, handleUncheckOnRedirection]);

  return null;
}

export default TabLeafletConsumer;