import React,{useState, useEffect} from 'react'
import { ContentDiv, Column, Row, TextField, Wrapper, Button, SectionHeader, RightDiv, SectionLabel  } from '../utils/Styles';
import { SelectionControl, CircularProgress } from 'react-md';
import PasswordField from './PasswordField.js'
import * as Constants from '../utils/Constants';
import swal from "sweetalert";
import FieldLabel from './FieldLabel'
import axios from 'axios'
import styled from 'styled-components';
import axiosRetry from 'axios-retry';

const WrapperContainer = styled(Wrapper)`
    @media screen and (max-width: 500px){
        font-size: 0.8rem;
    }
    @media screen and (max-width: 415px){
        font-size: 0.65rem;
    }
`;

const FtpContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1140px){
        flex-direction: column;
    }
    
`;

const RangeInputContainer = styled.div`
    width: 100%;
    margin-left: 26px;
    display: flex;
    align-items: center;
    & > ${TextField} {
        width: 100%;
        @media screen and (min-width: 1141px) {
            max-width: 80px;
        }
    }
    @media screen and (max-width: 500px){
        margin-left: 29px;
        gap: 5px 0;
        flex-direction: column;
    }
    @media screen and (max-width: 415px){
        margin-left: 0;
    }
`;

const PortRangeContainer = styled.div`
    display: flex;
    padding-top: 23px;
    padding-bottom: 10px;
    & > label {
        width: 117px;
        @media screen and (max-width: 500px){
            width: 77px;
        }
    }
`;

const PasswordContainer = styled.div`
    margin-left: 29px;
    flex-grow: 1;
    & > input {
        width: 100% !important;
    }
    @media screen and (max-width: 415px){
        margin-left: 0;
    }
`;

const ResponsiveSelectionControl = styled(SelectionControl)`
    & > label > span {
        width: 135px;
        @media screen and (min-width: 500px){
            width: 135px;
        }
        @media screen and (max-width: 500px){
            width: 100px;
        }
        @media screen and (max-width: 415px){
            width: 75px;
        }
    }
`;

const FieldSetContainer = styled.div`
    width: 45%;
    min-width: 425px;
    padding: 10px 20px;
    & > fieldset > div > label {
        display: flex;
        flex-shrink: 0;
    }
    & > fieldset > div > input {
        margin-left: 29px;
    }
    @media screen and (max-width: 1140px){
        width: 55%;
    }
    @media screen and (max-width: 500px){
        min-width: 325px;
    }
    @media screen and (max-width: 415px){
        min-width: 300px;

        & > fieldset > div > input {
            margin-left: 0;
        }
    }
    @media screen and (max-width: 395px){
        min-width: 275px;
    }
    @media screen and (max-width: 375px){
        min-width: 256px;
    }
`;

const ValidateMsg ={
    listen_port:"listen port",
    data_port:"data port",
    enable_passive_mode : "passive mode",
    port_from : "port from",
    port_to:"port to",
    username:"username",
    password:"password"
}

function FTPServerSetting() {
    const [readOnly, setReadOnly] = useState(true);
    const [inputs, setInputs]=useState(Constants.FTP_SERVER_DEFAULT_VALUES);
    const [isChanged, setChanged] = useState(false);
    const [retry,setRetry]=useState(false)
    const handleOnChange = (event) => {
        event.persist();
        let data = {...inputs, [event.target.name]: event.target.value};
        if( event.target.name === 'password') {     
            data['password'] = event.target.value.replace(/[{}()~*?"'\\^$|#\s]/g, '');
        }
        setInputs(data);
    }

    const handleToggleChange = (value, event) => {
        if(!readOnly){
            setInputs({...inputs, [event.target.id]: value});
            setChanged(true);
        }
    };

    const handleNumberOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
            setChanged(true);
        } else {
            if(event.target.value === "" || event.target.value === "0"){
                setInputs({...inputs, [event.target.name]: ""});
                setChanged(true);
            }
        }
    }

    const editSetting =() => {
        setReadOnly(false)
    }

    const saveSetting = () => {
        let jsonData = Object.assign({}, inputs);
        Object.keys(inputs).forEach(function(key){
            if(jsonData["enable_passive_mode"] === true && (key === "port_from" || key === "port_to") ){
                if(jsonData[key] ==="" ||  jsonData[key] === undefined){
                swal(`Please enter ${ValidateMsg[key]}`);
                return;
                }
                if(parseInt(jsonData["port_from"], 10) > parseInt(jsonData["port_to"],10)){
                    swal(`Please enter valid port range`);
                    return; 
                }
            }
            if(jsonData["enable_user_login"] === true && (key === "username" || key === "password") ){
                if(jsonData[key] === undefined || jsonData[key] ===""){
                    swal(`Please enter ${ValidateMsg[key]}`);
                    return;
                }
            }
           if(( jsonData[key] === undefined || jsonData[key] ==="") && (key !=="port_from" && key !== "port_to" && key !== "username" && key !== "password" )){
                swal(`Please enter ${ValidateMsg[key]}`);
                return;
            }
        });

        jsonData["type"] = "internal_ftp";
        axios.put(Constants.EDGE_API_ENDPOINT + '/system/services/internal_ftp', jsonData)
        .then(res => {
            swal("FTP server setting updated successfully.", { icon: "success"});
            setReadOnly(true);
            setTimeout(function(){ window.location.reload() }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const cancelSetting = () => {
        if(!isChanged){
            setReadOnly(readOnly => (true));
            return;
        } else {
            window.location.reload();
        }
    }

    useEffect(() => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount < 15 && retryCount > 5) {
                    setRetry(true)
                 } else if(retryCount === 15) {
                   setRetry(false)
                   swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                 }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
        client.get('/system/services/internal_ftp')
        .then(res => {
            let ftpData = res.data['data'];
            if(Object.keys(ftpData).length > 0){
                setInputs(ftpData);
            } else {
                setReadOnly(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);


    return (
        <ContentDiv>
            { (inputs === undefined) && 
                <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
                    <CircularProgress/>
                </div>
            }
            { inputs &&
            <WrapperContainer style={{"padding": "20px"}}>
            <SectionHeader>
                <SectionLabel>FTP Server</SectionLabel>
                </SectionHeader>
                <FtpContainer>
                    <FieldSetContainer>
                        <fieldset style={{"padding": "10px 15px 20px 15px"}}>
                            <legend>
                                Server Configuration
                            </legend>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <FieldLabel style={{margin: '4px 0px 0px 0px'}} required={true}>Listen Port</FieldLabel>
                                <TextField style={{marginTop:'-3px'}} id="listen_port" name="listen_port" value={inputs.listen_port} maxLength="5" onChange={handleNumberOnChange} disabled={true}/>
                            </div>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <FieldLabel required={true}>Data Port</FieldLabel>
                                <TextField style={{marginTop:'-3px'}} id="data_port" name="data_port" value={inputs.data_port} maxLength="5" onChange={handleNumberOnChange} disabled={true}/>
                            </div>
                            <ResponsiveSelectionControl id="enable_passive_mode" type="switch" label="Enable Passive Mode" name="enable_passive_mode" labelBefore="true"  style={{paddingTop:"23px"}} checked={inputs.enable_passive_mode || false} onChange={handleToggleChange}/>
                            {inputs.enable_passive_mode && 
                            <PortRangeContainer>
                                <FieldLabel required={inputs.enable_passive_mode}>Port Range</FieldLabel>
                                <RangeInputContainer>
                                    <TextField id="port_from" name="port_from" value={inputs.port_from} maxLength="5" onChange={handleNumberOnChange} disabled={true}/>
                                    <span style={{padding:"0px 14px 0px 14px"}}>To</span>
                                    <TextField id="port_to" name="port_to" maxLength="5" value={inputs.port_to} onChange={handleNumberOnChange} disabled={true}/>
                                </RangeInputContainer>
                            </PortRangeContainer>}
                        </fieldset>
                    </FieldSetContainer>
                    <FieldSetContainer>
                        <fieldset style={{"padding": "10px 15px 20px 15px"}}>
                            <legend>
                                Login Details
                            </legend>
                            <ResponsiveSelectionControl id="enable_user_login" type="switch" label="Enable User Login" name="enable_user_login" labelBefore="true" style={{paddingTop:"23px",marginBottom: "10px"}} checked={inputs.enable_user_login || false} onChange={handleToggleChange}/>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <FieldLabel required={true}>Username</FieldLabel>
                                <TextField id="username" name="username" value={inputs.username} maxLength="100" onChange={handleOnChange} disabled={!inputs.enable_user_login || readOnly}/>
                            </div>
                            <div style={{display:"flex", paddingTop:"20px"}}>
                                <FieldLabel required={true}> Password</FieldLabel>
                                <PasswordContainer>
                                    <PasswordField id="password" name="password" value={inputs.password} setInput={handleOnChange} readOnly={!inputs.enable_user_login || readOnly} />
                                </PasswordContainer>
                            </div>
                            <ResponsiveSelectionControl id="enable_anonymous_access" type="switch" label="Allow Anonymous Access" name="enable_anonymous_access" labelBefore="true" style={{paddingTop:"23px",marginBottom: "10px"}} checked={inputs.enable_anonymous_access || false} onChange={handleToggleChange}/>
                        </fieldset>
                        <RightDiv>
                        { readOnly === true &&
                            <Button id="editRecorderButton" primary onClick={editSetting}>Edit</Button>
                        }
                        { readOnly === false &&
                            <div>
                            <Button id="saveRecorderButton" primary onClick={saveSetting}>Save</Button>
                            <Button id="cancelRecorderButton" onClick={cancelSetting}>Cancel</Button>
                            </div>
                        }
                        </RightDiv>
                    </FieldSetContainer>
                </FtpContainer>
            </WrapperContainer>
            }
        </ContentDiv>
    )
}

export default FTPServerSetting
