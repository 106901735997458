import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { CircularProgress } from 'react-md';
import { ContentDiv, Spacer, Column, Row, Label, MandatoryLabel, TextFieldSmall, Th, Td, TextField, TableContainer, SelectField, Wrapper, RightDiv, Button, SectionHeader, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import swal from "sweetalert";
import Popup from 'reactjs-popup';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import axiosRetry from 'axios-retry';

const SelectFieldContainer = styled.div`
    margin-top: 10px;
    display: flex;
    ${Label} {
        width: 200px;
    }
    ${SelectField} {
        width: 407px;
    }
    @media screen and (max-width: 890px) {
        ${SelectField} {
            width: 290px;
        }
    }
    @media screen and (max-width: 775px) {
        ${SelectField} {
            width: 200px;
        }
    }
    @media screen and (max-width: 500px) {
        ${Label} {
            width: 180px;
        }
        ${SelectField} {
            width: 180px;
        }
    }
    @media screen and (max-width: 460px) {
        ${Label} {
            width: 140px;
        }
        ${SelectField} {
            width: 160px;
        }
    }
    @media screen and (max-width: 400px) {
        ${Label} {
            width: 110px;
        }
        ${SelectField} {
            width: 130px;
        }
    }
`;

const MessageContaier = styled.div`
    padding-top: 15px;
`;

const TextFieldLabel = styled(Label)`
    width: 300px;
    margin-top: 25px;
    white-space: nowrap;
`;

const MessageLabel = styled(Label)`
    width: 100%;
    color: gray;
    font-size: 0.9em;
`;

const TableButton = styled(Button)`
    font-size: 12px;
    padding: 5px 5px;
    margin: 0px 5px;
`;

const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media screen and (max-width: 550px) {
        width: 280px;
        align-items: center;
    }
`;

const RuleLabel = styled.label`
    font-weight: 600;
    white-space: nowrap;
`;

const PopupButtons = styled.div`
    display: flex;
    justify-content: center;
    gap: 0 16px;
    ${Button} {
        margin-left: 0;
    }
`;

const AddRuleContainer = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-bottom: 10px;
`;

const FieldContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const LabelContainer = styled.div`
    display: flex;
    justify-content: center;
`;

/*const InstructionsContainer = styled.div`
    paddingTop: 20px;
    color: gray;
    fontSize: 0.9em;
`; */

const CloseIcon = styled.span`
    float: right;
    position: absolute;
    right: 13px;
    top: 2px;
    font-size: 1.5em;
    color: gray;
    cursor: pointer;

    &: hover {
        color: black;
    }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ResponsiveRow = styled(Row)`
  width: 500px;
  padding-left: 20px;
  @media screen and (max-width: 750px) {
    width: 425px;
  }
  @media screen and (max-width: 500px) {
    width: 330px;
  }
  @media screen and (max-width: 400px) {
    width: 275px;
  }
`
const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const TableTooltip = styled(Td)`
    text-align: left;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 215px;
    @media screen and (max-width: 750px) {
        max-width: 180px;
    }
    @media screen and (max-width: 400px) {
        max-width: 95px;
    }
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ResponsiveWrapper = styled(Wrapper)`
    @media screen and (max-width: 460px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.75rem;
    }
`;

const NamingRules = (props) => {
    const [selectedTab, setSelectedTab] = useState('webcam');
    const params = useLocation().search;
    const [historyParams] = useState(params);
    const history = useHistory();

    const setParams = useCallback(() => {
        const params = '?' +
          `${selectedTab === 'webcam' ? 'webcam' : '&selectedTab=' + selectedTab}`
        if (params === '?') {
          return '';
        }
        return params;
      }, [selectedTab]);
    
      const fetchHistoryParams = useCallback(() => {
        const string = new URLSearchParams(historyParams);
        let newParams = {};
        newParams.selectedTab = string.get('selectedTab');
    
        if (newParams.selectedTab) {
          setSelectedTab(newParams.selectedTab);
        }
      }, [historyParams]);
    
      useEffect(()=>{
        fetchHistoryParams();
      },[fetchHistoryParams]);
    
      useEffect(() => {
        history.push('/naming-rules' + setParams());
      }, [history, setParams]);

      function onTabChange(tabId){
        setSelectedTab(tabId);
      }

    return (
        <ContentDiv>
            <ResponsiveWrapper>
                <SectionHeader>
                    <SectionLabel>Naming Rules</SectionLabel>
                </SectionHeader>
                <Tabs defaultTab={selectedTab} onChange={onTabChange}>
                    <TabList>
                        <Tab tabFor="webcam" className={'rwt__tab'}>Webcam</Tab>
                        <Tab tabFor="wifi" className={'rwt__tab'}>Wi-Fi</Tab>
                    </TabList>
                    <TabPanel tabId="webcam">
                        <WebcamDevices />
                    </TabPanel>
                    <TabPanel tabId="wifi">
                        <WifiDevices />
                    </TabPanel>
                </Tabs>
            </ResponsiveWrapper>
        </ContentDiv>
        )
}

const WebcamDevices = (props) => {
    const [readOnly, setReadOnly] = useState(true);
    const [inputs, setInputs] = useState(false);
    const [disableRule, setDisableRule] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [retry,setRetry]=useState(false)

    useEffect(() => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client,{
            retries: 15,
            retryDelay: (retryCount, error) => {
                if(retryCount < 15 && retryCount > 5) {
                    setRetry(true)
                 } else if(retryCount === 15) {
                   setRetry(false)
                   swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
                 }
                return 3000;
            },
            retryCondition: (error) => {
                return true;
            },
          });
          client.get('/system/videos')
            .then(res => {
                if(res.data["data"]){
                    setInputs(res.data["data"]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                swal(error.toString(), { icon: "error"});
            });
    }, []);

    const handleOnChange = (event) => {
        event.persist();
        setInputs({...inputs, [event.target.name]: event.target.value});
    }

    const cancelVideoDeviceRule = (event) => {
        setReadOnly(true);
        window.location.reload();
    }

    const saveVideoDeviceRule = (event) => {
        let jsonData = Object.assign({}, inputs);
        axios.patch(Constants.EDGE_API_ENDPOINT + '/system/videos', jsonData)
        .then(res => {
            swal("Video devices name rule updated successfully.", { icon: "success"});
            setReadOnly(true);
            setTimeout(function(){ window.location.reload() }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const editVideoDeviceRule = (event) => {
        setReadOnly(false);
    }

    function getStreamList(){
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
            .then(res => {
                const stacks = res.data.data.stacks;
                const filteredObject = Object.keys(stacks).filter((stack) => {
                     return  stacks[stack].services.recorder1.recorder_type === "stream_recorder" && stacks[stack].services.recorder1.camera_type === "USB";
                 })
                 if(filteredObject.length > 0){
                    setDisableRule(true);
                 } else {
                    setDisableRule(false);
                 }
                 setIsLoading(false);
            }).catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });
    }

    useEffect(() => {
        getStreamList();
    }, []);

    return (
        <>
            {isLoading &&
                <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
                    <CircularProgress/>
                </div>}
            {inputs &&
            <div style={{width: 'min-content'}}>
            <Row>
                <Column style={{ 'display': 'flex', 'width': '100%', 'padding': '10px 20px'}}>
                    <SelectFieldContainer>
                        <Label style={{'margin-top': '5px'}}>Select Naming Rules</Label>
                        <SelectField name="name_rule" value={inputs.name_rule} onChange={handleOnChange} disabled={readOnly}>
                            <SelectOptions items={Constants.DEVICE_NAME_RULE}/>
                        </SelectField>
                    </SelectFieldContainer>
                </Column>
            </Row>
            <Row>
                <Column style={{ 'display': 'flex', 'width': '100%', 'padding': '10px 20px'}}>
                { inputs.name_rule === "default" &&
                <MessageContaier>
                    <MessageLabel><b>Note: </b>The name of Webcam (UVC) will be video&lt;N&gt; where &lt;N&gt; is a sequential number as it is detected by SCORER Edge  e.g. video0 for the first detected device</MessageLabel>
                </MessageContaier>
                }
                { inputs.name_rule === "by_position" &&
                <MessageContaier>
                    <MessageLabel><b>Note: </b>The name of Webcam (UVC) will be video-L&lt;Loc&gt; where the pair of &lt;Loc&gt; represents the physical port location this camera is connected to.  e.g. video-L2-2.2</MessageLabel>
                </MessageContaier>
                }
                { inputs.name_rule === "by_product" &&
                <MessageContaier>
                    <MessageLabel><b>Note: </b>The name of Webcam (UVC) will be video-P&lt;VID&gt;:&lt;PID&gt; where &lt;VID&gt; is the vendor ID and &lt;PID&gt; is the product ID.  Note that you can only connect one device of the same model.  e.g. video-P8086:0120</MessageLabel>
                    </MessageContaier>
                }
                </Column>
            </Row>
            <Row>
                <Column style={{ 'display': 'flex', 'justifyContent': 'flex-end', 'width': '100%', 'padding': '10px 20px'}}>
                    <div>
                        <RightDiv>
                        { readOnly === true ?
                        <div>
                            { disableRule ?
                                <Button id="btnEditVideoRule" primary style={{"float": "right", "background":'lightgray', "border":"none" }}>Edit</Button>
                                :
                                <Button id="btnEditVideoRule" primary style={{"float": "right"}} onClick={editVideoDeviceRule} disabled={disableRule}>Edit</Button>
                            }
                            { disableRule &&
                            <MessageContaier>
                                <MessageLabel style={{"color": "#ff4343"}}> You need to delete all "Webcam(UVC) Streams" before changing the naming rule for Webcam(UVC) devices. </MessageLabel>
                            </MessageContaier>
                            }
                        </div>
                        :
                        <div>
                            <Button id="btnSaveVideoRule" primary onClick={saveVideoDeviceRule}>Save</Button>
                            <Button id="btnCancelVideoRule" onClick={cancelVideoDeviceRule}>Cancel</Button>
                        </div>
                        }
                        </RightDiv>
                    </div>
                </Column>
            </Row>
            </div>}
        </>
    )
}

const WifiDevices = () => {
    const [rules, setRules] = useState({});
    const [rule, setRule] = useState({product_name: '', if_name_prefix: ''});
    const [modalOpen, setModalOpen] = useState(false);
    const [isEditPopup, setEditPopup] = useState(false);
    const [loadinRule, setLoadingRule] = useState(false);

    const contentStyle = {
        margin: 'auto',
        background: 'rgb(255, 255, 255)',
        width: 'fit-content',
        height: 'auto',
        padding: '5px',
        minWidth: '26%',
        border: '2px solid #d7d7d7'
      };

    useEffect(() => {
        setLoadingRule(true)
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/wifi_devices_naming/all')
            .then(res => {
                if(res.data.data.services){
                    setRules(res.data.data.services);
                    setLoadingRule(false);
                };
            })
            .catch(error => {
                console.log(error.response)
                setLoadingRule(false);
            });
            
    }, []);

    const closeModal = () => {
        setModalOpen(false);
        setRule({product_name: '', if_name_prefix: ''})
    }

    const editInstance = () => {
        setEditPopup(true);
        setModalOpen(true);
      };

    const addNewInstance = (event) => {
    setEditPopup(false);
    setModalOpen(true);
    };

    const radioButtonOnChange = (key, ifName) => {
        setRule({product_name: key, if_name_prefix: ifName});
        setEditPopup(true);
    };
    
    const closePopup = (event) => {
        setModalOpen(false);
      };

    const overlayStyle = { background: 'rgba(0,0,0,0.1)' };

    return (
        <>
            {loadinRule && 
                <div className="loading-msg">
                    <label>Loading...</label>
                    <CircularProgress />
                </div>}
                {!loadinRule && 
                <>
                <ResponsiveRow>
                    <ActionButtonContainer>
                        { isEditPopup &&
                            <Button primary id="btnEditInstance" name="btnEditInstance" onClick={() => editInstance()}>Edit</Button>
                        }
                        <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()} >Add</Button>
                        <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closeModal} {...{ contentStyle, overlayStyle }}>
                            <RulePopupdemo isEditPopup={isEditPopup} rule={rule} rules={rules} closePopup={closePopup} />
                        </Popup>
                    </ActionButtonContainer>
                </ResponsiveRow>
                <ResponsiveRow>
                    <TableLayout>
                        <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                            <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                            <Th style={{'width': '50px'}}></Th>
                            <Th style={{'text-align': 'left'}}>Product Name</Th>
                            <Th>Interface Name Prefix</Th>
                            </tr>
                            {
                                Object.keys(rules).map((key) => {
                                    return(
                                        <tr>
                                            <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} name="select_radio"  value={key} onClick={() => radioButtonOnChange(key, rules[key].if_name_prefix)}></input></Td>
                                            <TableTooltip title={decodeURIComponent(key)}>{decodeURIComponent(key)}</TableTooltip>
                                            <TableTooltip title={rules[key].if_name_prefix}>{rules[key].if_name_prefix}</TableTooltip>
                                        </tr>
                                    ); 
                                })}
                            </table>
                    </TableLayout>
                </ResponsiveRow>
            </>}
        </>
    )
}

export default NamingRules;

const RulePopupdemo = (props) => {
    const { rule, isEditPopup, closePopup, rules } = props;
    const [ruleSettings, setRuleSettings] = useState(rule);

    const handleOnChange = (event) => {
        event.persist();
        if (event.target.name === 'product_name') {
            if (event.target.value.charAt(0) === '_') {
              return event.target.value.replace(/[^\w]/g, '');
            } else {
              event.target.value = event.target.value.replace(/[^\w]/g, '');
            }
        } 
        setRuleSettings({...ruleSettings, [event.target.name]: event.target.value});
    }

    const deleteRule = () => {
        const productName = rule.product_name;
        axios.delete(Constants.EDGE_API_ENDPOINT + '/system/wifi_devices_naming/'+ encodeURIComponent(productName) )
        .then(res => {
           if(res.status === 200){
               swal('Naming rule deleted successfully', { icon: 'success'});
               setTimeout(function(){ window.location.reload() }, 2000);
           }
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const saveRule = (action) => {
        let method;
        let product_name;
        let jsonData;
        if(action === "add"){
            if(encodeURIComponent(ruleSettings.product_name) in rules){
                swal('Product name already exists', { icon: 'error'});
                setRuleSettings({product_name: '', if_name_prefix: ''})
                return;
            }
            method = 'put';
            product_name = encodeURIComponent(encodeURIComponent(ruleSettings.product_name));
            jsonData = { "if_name_prefix":  ruleSettings.if_name_prefix}
        } else {
            method = 'patch';
            jsonData = { "old_if_name_prefix": rules[ruleSettings.product_name].if_name_prefix, "if_name_prefix":  ruleSettings.if_name_prefix};
            product_name = encodeURIComponent(ruleSettings.product_name);
        }

        axios({
            method: method,
            url: Constants.EDGE_API_ENDPOINT + '/system/wifi_devices_naming/'+ product_name,
            data: jsonData
            })
        .then(res => {
           if(res.status === 200){
               swal('Naming rule '+ (action ==="add" ? "added" : "updated") +' successfully', { icon: 'success'});
               setTimeout(function(){ window.location.reload() }, 2000);
           }
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const validate = () => {
        if(ruleSettings.product_name === ''){
            swal('Product name is required', { icon: "error"});
            return;
        }
        if(ruleSettings.if_name_prefix === ''){
            swal('Interface name prefix is required', { icon: "error"});
            return;
        } 
        if(isEditPopup){
            saveRule('update');
        } else {
            saveRule('add');
        }
    }

    return(
        <div>
        <PopupContainer>
          <SectionHeader style={{ 'display': 'flex', width: '100%' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Naming Rule </SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" style={{ 'margin': '0px' }} onClick={deleteRule} >Delete</Button>
              </div>
            }
          </SectionHeader>
          <Container>
            <Row>
              <MandatoryLabel>Product Name</MandatoryLabel>
              <TextFieldSmall id="product_name" autoComplete="off" name="product_name" onChange={handleOnChange} value={decodeURIComponent(ruleSettings.product_name)} readOnly={isEditPopup} />
            </Row>
            <Row>
              <MandatoryLabel>Interface Name Prefix</MandatoryLabel>
              <TextFieldSmall id="if_name_prefix" autoComplete="off" name="if_name_prefix" onChange={handleOnChange} value={ruleSettings.if_name_prefix} maxLength="100" />
            </Row>
          </Container>
          <Row>
            <PopupButtons>
              <Button primary onClick={validate} >Save</Button>
              <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      
        </div>
    )
}
