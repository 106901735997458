import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios';
import { ContentDiv, Wrapper, Row, Column, SectionHeader, Button, SectionLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import ls from 'local-storage';
import Loader from './Loader';

const DeviceReset = (props) => {
    const [inputs, setInputs] = useState({});
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageloading] = useState(true);
    const deviceId = ls.get('device_id') === undefined ? '' : ls.get('device_id');

    const enableToggle = (value, event) => {
      if(!loading) {
        setInputs({ ...inputs, [event.target.name]: value });
      }
    }

    const getResetStatus = useCallback(() => {
      let interval;
      interval = setInterval(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_reset')
        .then(res => {
          setPageloading(false)
          if(res.data.message === 'Not Running') {
            setLoading(false);
            clearInterval(interval);
          } else {
            setLoading(true);
          }
        })
        .catch(error => {
          console.error(error);
          setLoading(false);
          setPageloading(false);
        });
      }, 3000);
    },[]);

    useEffect(() => {
      if (loading) {
        getResetStatus();
      }
    }, [loading]);

    const onClickReset = () => {
        let jsonData = Object.assign({}, inputs);
        if(jsonData.keep_cloud_conf === undefined) {
          jsonData.keep_cloud_conf = false;
        }
        let finalJson = {};
        finalJson.action = 'StartDeviceReset'
        finalJson.data = {}
        finalJson.data.keep_cloud_conf = jsonData.keep_cloud_conf;
        finalJson.data.keep_custom_stack = true
        finalJson.data.post_cleanup_state = 'none'
        finalJson.source = ''
        swal({
          text: "Confirm device reset operation by typing 'Device Id'",
          content: 'input',
          buttons: ['Cancel', 'Confirm'],
          dangerMode: true,
          icon: 'warning'
        }) 
        .then((name) => {
          // user has cancle the reset operation by clicking No
          if (name === null) {
            return;
          }
          if(name === '') {
            return swal({ text: 'Please enter device id.', icon: 'error' });
          } else if(name === deviceId && name !== '') {
              axios.post(Constants.EDGE_API_ENDPOINT + '/system/device_reset', finalJson)
              .then(res => {
                if(res.data.message === 'Success') {
                  swal({ text: "Device reset request submitted successfully", icon: 'success' });
                } else {
                  swal(res.data.message, { icon: 'success' });
                }
                setLoading(true);
                getResetStatus();
              })
              .catch(error => {
                if (error.response) {
                  var errorObj = error.response.data;
                  swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                } else {
                  swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                }
              });
          } else if(name !== deviceId && name !== '') {
            return swal({ text: 'Device id did not match.', icon: 'error' });
          }
        });
    }; 

    return (
      <ContentDiv>
          <Wrapper>
              <SectionHeader>
                  <SectionLabel>Device Reset</SectionLabel>
              </SectionHeader>
              <Loader isLoading={pageLoading} />
              {inputs && !pageLoading &&
              <Row>
              <Column>
                <SelectionControl type="switch" label='Keep Device Registration' id='keep_cloud_conf' name='keep_cloud_conf' readonly={loading} disabled={loading} value={inputs.keep_cloud_conf} checked={inputs.keep_cloud_conf || false} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} />
                  <Button primary onClick={onClickReset} disabled={loading} style={{'marginLeft':'0'}}>
                    <div style={{'display': 'flex'}}>
                    {loading && <span>Reset</span>}
                    {!loading && <span>Reset</span>}
                    {loading && (
                      <div class="loader" style={{'marginLeft':'10px', 'marginTop': '2px'}}></div>
                    )}
                    </div>
                  </Button>
                  {loading && <div style={{'display':'flex'}}>
                    <p style={{'color':'orange'}}>Device Reset is currently in progress... </p>
                  </div>}
              </Column>
              </Row>}
          </Wrapper>
      </ContentDiv>
    );
}

export default DeviceReset;