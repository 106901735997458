import React, { useState, useEffect } from 'react';

const WebRTCPlayer = (props)=> {

    var webSocket;
    var peerConnection;
    var connectAttempts = 0;
    var maxConnectionAttempts = 30;
    var rtcConfiguration = {};
    var peerAddress = props.peerAddress;
    var videoContainer;
    const [webSocketState, setWebSocketState] = useState();

    useEffect(() => {
        console.debug("WebRTCPlayer: " + props.for + " " + props.enabled);
        videoContainer = document.getElementById(props.for);
        if(props.enabled === true){
            videoContainer.style.display = "block";
            connectToPeer();
        } else {
            videoContainer.style.display = "none";
            if(webSocketState != undefined){
                webSocketState.close(1000, "WebRTC Disabled");
            }
        }
    }, [props.enabled]);

    return (
        false
    );

    function getPeerId() {
        return "WRP_" + props.id + "_" + Math.floor(Math.random() * (9000 - 10) + 10).toString();
    }

    function getVideoElement() {
        return videoContainer.querySelector("#stream");
    }

    function getStatusElement() {
        return videoContainer.querySelector("#status");
    }

    function setStatus(text) {
        console.debug(text);
        var span = getStatusElement();
        // Don't set the status if it already contains an error
        if (span !== null && !span.classList.contains('error')){
            span.textContent = text;
        }
    }

    function setError(text) {
        console.error(text);
        var span = document.getElementById("status")
        if(span !== null){
            span.textContent = text;
            span.classList.add('error');
        }
    }

    function resetState() {
        console.debug("WebSocket reset");
        webSocket.close();
    }

    function handleIncomingError(error) {
        setError("ERROR: " + error);
        resetState();
    }

    // SDP offer received from peer, set remote description and create an answer
    function onIncomingSDP(sdp) {
        peerConnection.setRemoteDescription(sdp).then(() => {
            setStatus("Remote Description set");
            if (sdp.type !== "offer"){
                return;
            }
            setStatus("Sending Answer");

            peerConnection.createAnswer()
                .then(function(answer) {
                    console.debug("Got local description: " + JSON.stringify(answer));
                    return peerConnection.setLocalDescription(answer);
                })
                .then(function() {
                    let sdp = {'sdp': peerConnection.localDescription}
                    sdp["sdp"]["ice-option"] = null;
                    setStatus("Sending Local Description");
                    console.debug("Local Description:" + JSON.stringify(sdp));
                    webSocket.send(JSON.stringify(sdp));
                    setStatus("Connected");
                })
                .catch(function(err) {
                    console.log(err.name + ': ' + err.message);
                    window.location.reload();
              });
    })};

    // ICE candidate received from peer, add it to the peer connection
    function onIncomingICE(ice) {
        var candidate = new RTCIceCandidate(ice);
        peerConnection.addIceCandidate(candidate).catch(setError);
    }

    function onServerMessage(event) {
        switch (event.data) {
            case "HELLO":
                console.debug("Received HELLO");
                setStatus("Registered with server, waiting for offer.");
                return;
            default:
                if (event.data.startsWith("ERROR")) {
                    console.error("Received " + event.data);
                    handleIncomingError(event.data);
                    return;
                }
                // Handle incoming JSON SDP and ICE messages
                let msg;
                try {
                    msg = JSON.parse(event.data);
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        handleIncomingError("Error parsing incoming JSON: " + event.data);
                    } else {
                        handleIncomingError("Unknown error parsing response: " + event.data);
                    }
                    return;
                }

                // Incoming JSON signals the beginning of a call
                if (!peerConnection){
                    createCall(msg);
                }

                if (msg.sdp != null) {
                    console.debug("Received Remote SDP:" + JSON.stringify(msg.sdp));
                    onIncomingSDP(msg.sdp);
                } else if (msg.ice != null) {
                    console.debug("Received Remote ICE:" + JSON.stringify(msg.ice));
                    onIncomingICE(msg.ice);
                } else {
                    handleIncomingError("Unknown incoming JSON: " + msg);
                }
        }
    }

    function onServerClose(event) {
        setStatus('Disconnected from server');
        if (peerConnection) {
            peerConnection.close();
            peerConnection = null;
        }
        // Do not retry when WebRTC is disabled (Toggle is OFF)
        if(event !== null && event.code !== 1000){
            window.setTimeout(connectToPeer, 3000);
        }
    }

    function onServerError(event) {
        setError("Unable to connect to server")
        if (peerConnection) {
            peerConnection.close();
            peerConnection = null;
        }
        resetState();
    }

    function connectToPeer() {
      try{
        connectAttempts++;
        if (connectAttempts > maxConnectionAttempts) {
            setError("Too many connection attempts, aborting. Refresh page to try again");
            return;
        }
        // Clear errors in the status span
        var span = document.getElementById("status");
        if(span !== null){
            span.classList.remove('error');
            span.textContent = '';
        }

        let peerId = getPeerId();
        setStatus("Connecting to server " + peerAddress);
        webSocket = new WebSocket(peerAddress);
        setWebSocketState(webSocket);
        /* When connected, immediately register with the server */
        webSocket.addEventListener('open', (event) => {
            const interval = setInterval(() => {
                try {
                    webSocket.send('HELLO ' + peerId);
                    clearInterval(interval);
                    setStatus("Registering with server (sent HELLO) for peer id: " + peerId);
                } catch(error) {
                    console.log(error)
                }
            }, 3000);
        });
        webSocket.addEventListener('error', onServerError);
        webSocket.addEventListener('message', onServerMessage);
        webSocket.addEventListener('close', onServerClose);
      }catch(error) {
        console.log(error)
      }
    }

    function onRemoteTrack(event) {
        var videoElement = getVideoElement()
        if (videoElement !== null && videoElement.srcObject !== event.streams[0]) {
            console.debug('Incoming stream');
            videoElement.srcObject = event.streams[0];
        }
    }

    function createCall(msg) {
        // Reset connection attempts because we connected successfully
        connectAttempts = 0;
        console.debug('Creating RTCPeerConnection');

        peerConnection = new RTCPeerConnection(rtcConfiguration);
        peerConnection.ontrack = onRemoteTrack;

        if (!msg.sdp) {
            console.debug("WARNING: First message wasn't an SDP message!?");
        }

        peerConnection.onicecandidate = (event) => {
            if (event.candidate == null) {
                console.debug("All local ICE Candidates sent.");
                return;
            }
            // We have a local ICE candidate, send it to the remote party with the same uuid
            console.debug("Sending ICE Candidate:", event.candidate, event);
            webSocket.send(JSON.stringify({'ice': event.candidate}));
        };

        setStatus("RTCPeerConnection created, waiting for SDP");
    }
}

export default WebRTCPlayer;
