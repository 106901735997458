import React, { useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import axios from 'axios';
import { Row, Button, SectionHeader, SectionLabel, CenterDiv, Label, TableContainer, SelectField, Td, Th, overlayStyle, PopupContainer, TextFieldLabel, PopupButtons, MandatoryLabel,TextField } from '../utils/Styles';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import * as Constants from '../utils/Constants';
import swal from "sweetalert";
import { isEmpty } from '../utils/Utils';
import { CircularProgress } from 'react-md';
import Popup from 'reactjs-popup';
import axiosRetry from 'axios-retry';
import { convertToTimeZone } from 'date-fns-timezone';
import { format, toDate } from 'date-fns';

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const TextFieldSmall = styled(TextField)`
  width: 300px;
  @media screen and (max-width: 500px) {
    width: 200px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ResponsiveTabs = styled(Tabs)`
  @media screen and (max-width: 500px) {
    width: 320px;
  }
`;

const ResponsiveTabContent = styled.div`
  padding: 5px 16px 10px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: #A8B5BD7D transparent;
  ::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #6262627d;
    border-radius: 10px;
  }
`;

const InputFieldContainer = styled.div`
  display: flex;
  align-items: baseline;
  & > label {
    width: 150px;
    flex-shrink: 0;
    @media screen and (max-width: 750px) {
      width: 105px;
    }
    @media screen and (max-width: 400px) {
      width: 90px;
    }
  }
`;

const ResponsivePopupContainer = styled.div`
  width:  100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  z-index: 5;
  & > div {
    @media screen and (max-width: 750px) {
      transform: scale(0.9);
      background: transparent !important;
    }
    @media screen and (max-width: 480px) {
      transform: scale(0.8);
    }
    @media screen and (max-width: 450px) {
      height: 600px !important;
    }
    @media screen and (max-width: 400px) {
      transform: scale(0.75);
    }
  }
`;

const ResponsiveMandatoryLabelGeneral = styled(MandatoryLabel)`
  @media screen and (max-width: 575px) {
    width: 140px;
  }
  @media screen and (max-width: 450px) {
    width: 120px;
  }
`;

const SectionHeaderVolume = styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

export const OnRefreshContext = React.createContext({
  onRefresh: false
});

const Volumes = () => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [volumeData, setVolumeData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [loading, setLoading] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const [CurTimezone, setCurTimeZone] = useState();
  const [standaredTZ, setStandardTZ] = useState();
  const [retry, setRetry] = useState(false);
  const [selectedInstanceID, setSelectedInstanceID] = useState();
  const [availableVolumes, setAvailableVolumes] = useState();

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  const nickNameStyle = {
    textAlign: 'left',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  useEffect(() => {
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  }, []);

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewInstance = (event) => {
    setEditPopup(false);
    // setSelectedInstance(undefined);
    setModalOpen(true);
  };

  const editInstance = () => {
    setEditPopup(true);
    setModalOpen(true);
  };
  
  const radioButtonOnChange = (name) => {
    let instData = Object.assign({}, volumeData.stacks[name].services.volume1);
    instData.instance_id = name;
    instData.nickname = volumeData.stacks[name].nickname;
    setSelectedInstance(instData);
    setSelectedInstanceID(instData.instance_id);
    if(instData.volume_type === 'nfs'){
      instData.volume_type = true;
    }else if(instData.volume_type === 'named'){
      instData.volume_type = false;
    }
    setEditPopup(true);
  };

  const getVolume = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client, {
      retries: 15,
      retryDelay: (retryCount, error) => {
        if (retryCount < 15 && retryCount > 5) {
          setRetry(true)
        } else if (retryCount === 15) {
          setRetry(false)
          swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
        }
        return 3000;
      },
      retryCondition: (error) => {
        return true;
      },
    });
    client.get('/sdk/volume/all')
      .then(res => {
        let data = res.data.data;
        if (Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if ('stacks' in data) {
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function (a, b) {
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            return dateFormatB - dateFormatA;
          });
        }
        data["sorted_list"] = sortedData;
        if (Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.volume1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedInstance(instData);
          setSelectedInstanceID(instData.instance_id);
          setEditPopup(true);
          if(instData.volume_type === 'nfs'){
            instData.volume_type = true;
          }
          if(instData.volume_type === 'named'){
            instData.volume_type = false
          }
        }

        client.get('/sdk/volume/status/all')
          .then(res => {
            let volumeStatusData = res.data.data;
            if ('stacks' in volumeStatusData) {
              let volStatusList = volumeStatusData.stacks
              Object.keys(volStatusList).map((mName) => {
                if (mName in data.stacks) {
                  data.stacks[mName].status = volStatusList[mName];
                }
                return mName;
              });
              setVolumeData(data);
            } else {
              setVolumeData(data);
            }
            setLoading(false);
            setOnRefresh(false);
          })
          .catch(err => {
            console.log(err);
            setVolumeData(data);
            setLoading(false);
            setOnRefresh(false);
          });
      }).catch(error => {
        console.log(error);
        setVolumeData([]);
        setLoading(false);
      });
  }, []);

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  const refreshInstances = (event) => {
    setLoading(true);
    setOnRefresh(true);
    document.getElementById('btnRefreshInstance').textContent = 'Refreshing...';
    getVolume();
  };

  useEffect(() => {
    if (document.getElementById('btnRefreshInstance')) {
      document.getElementById('btnRefreshInstance').textContent = 'Refresh';
    }
  }, [volumeData]);

  useEffect(() => {
    getVolume();
  }, [getVolume]);

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
      .then(res => {
        let data = res.data.data.timezone;
        let splitTimeZone = data.split(' (');
        setCurTimeZone(splitTimeZone[0])
        setStandardTZ(splitTimeZone[1])
        let splitZone = splitTimeZone[1].split(',');
        setStandardTZ(splitZone[0])
      }).catch(error => {
        console.error('clock data');
      });
  }, []);

  useEffect(() => {
    getClockStatus()
  }, [])

  // Project get API //
  const getProjectData = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client, {
      retries: 4,
      retryDelay: (retryCount, error) => {
        if (retryCount === 4) {
          swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
        }
        return 3000;
      },
      retryCondition: (error) => {
        return true;
      },
    });
    client.get('/sdk/project/all')
      .then(res => {
        let data = res.data.data;
        if (Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if ('stacks' in data) {
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
        }
        data["sorted_list"] = sortedData;
        setAvailableVolumes(data["sorted_list"])
      }).catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getProjectData();
  }, [getProjectData])

  const getDateFormate = (Date, CurTimezone) => {
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
    <ContentDiv>
      {(volumeData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>
      }
      {volumeData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <SectionHeader>
            <SectionLabel>Volume</SectionLabel>
          </SectionHeader>
          <Row>
            <ActionButtonContainer>

              { volumeData.stacks && Object.keys(volumeData.stacks).length > 0 &&
                <Button primary id="btnEditInstance" name="btnEditInstance" onClick={() => editInstance()}>Edit</Button>
              }

              {/* {volumeData.stacks && Object.keys(volumeData.stacks).length > 0 &&
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" onClick={deleteVolume} >Delete</Button>
              } */}
              {volumeData.stacks && Object.keys(volumeData.stacks).length > 0 &&
                <Button primary id="btnRefreshInstance" name="btnRefreshInstance" disabled={loading} onClick={() => refreshInstances()}>Refresh</Button>
              }
              <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()}>Add</Button>
              {modalOpen &&
                <ResponsivePopupContainer>
                  <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                    <VolumePopup isEditPopup={isEditPopup} availableVolumes={availableVolumes} selectedInstanceID={selectedInstanceID} closePopup={closePopup} selected_volume={isEditPopup ? selectedInstance : undefined} volumeData={volumeData} />
                  </Popup>
                </ResponsivePopupContainer>}
            </ActionButtonContainer>
          </Row>
          {volumeData.stacks && Object.keys(volumeData.stacks).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{ 'width': '50px' }}></Th>
                  <Th style={{ 'text-align': 'left', 'width': '18%' }}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  {/* <Th>Type</Th> */}
                  <Th>Created</Th>
                  {/* <Th>Mount Path</Th> */}
                  <Th>Permission</Th>
                </tr>

                {Object.keys(volumeData.sorted_list).map((index) => {
                  let instName = volumeData.sorted_list[index].name;
                  let vData = volumeData.sorted_list[index].services.volume1;
                  let nickname = volumeData.stacks[instName].nickname ? volumeData.stacks[instName].nickname : '';
                  let created = volumeData.stacks[instName].created_time;
                  let volumeType = volumeData.sorted_list[index].services.volume1.volume_type;
                  let mountPoint = volumeData.sorted_list[index].services.volume1.mount_path;
                  let permission = volumeData.sorted_list[index].services.volume1.permission;
                  var newDate = new Date(created * 1000);
                  let calculatedDate = getDateFormate(newDate, CurTimezone);
                  if (calculatedDate === undefined) {
                    return;
                  }
                  let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
                  let time = format(new Date(calculatedDate), 'HH:mm:ss');
                  let mStatus = 'recovering';
                  if ('status' in volumeData.stacks[instName]) {
                    mStatus = volumeData.stacks[instName].status.running_status.status;
                  }

                  return (
                    <tr style={{ 'height': '40px' }}>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={index === '0'} name="select_radio" value={vData.instance_id} onClick={() => radioButtonOnChange(vData.instance_id)}></input></Td>
                      <Td>
                        <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                          <div style={nickNameStyle} title={nickname}>{nickname}</div>
                          <div style={instNameStyle} title={instName}>({instName + ')'}</div>
                        </div>
                      </Td>
                      <Td>
                        {mStatus === '-' ?
                          '-' :
                          <label className={mStatus + '-sdk'}>
                            {getDisplayLabel(Constants.STATUS_TYPES, mStatus)}
                          </label>}
                      </Td>
                      {/* <Td>{volumeType}</Td> */}
                      <Td>{date} {time} {standaredTZ}</Td>
                      {/* <Td>{mountPoint}</Td> */}
                      <Td>{permission === "rw" ? 'read-write' : 'read-only'}</Td>
                    </tr>
                  );
                })}
              </table>
            </TableLayout>
          }
          {volumeData.stacks && Object.keys(volumeData.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>
          }
        </Wrapper>}
    </ContentDiv>
  );
};

function getDefaultVolumeData() {
  let DeafultVolumeData = Constants.VOLUME_POPUP_DEFAULT_STATUS;
  return DeafultVolumeData;
}

const VolumePopup = (props) => {
  const { isEditPopup, closePopup, selected_volume, volumeData,availableVolumes,selectedInstanceID } = props;
  const [volumeSettings, setVolumeSettings] = useState(selected_volume === undefined ? getDefaultVolumeData() : selected_volume);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (volumeSettings.instance_id === '') {
      if (volumeData.sorted_list?.length !== undefined) {
        setVolumeSettings({ ...volumeSettings, instance_id: 'volume' + (volumeData.sorted_list.length + 1) });
      } else {
        setVolumeSettings({ ...volumeSettings, instance_id: 'volume1' });
      }
    }
  }, []);


  const handleOnChange = (event) => {
    if (event.target.name === 'instance_id') {
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if (event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    }
    setVolumeSettings({ ...volumeSettings, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    if(volumeSettings.volume_type === true){
      volumeSettings.volume_type = 'nfs';
    }else if(volumeSettings.volume_type === false){
      volumeSettings.volume_type = 'named'
    }
    setVolumeSettings({ ...volumeSettings,[event.target.name]: event.target.checked });
  };
  
  const handlePermissionChange = (event)=>{
    setVolumeSettings({ ...volumeSettings, [event.target.name]: event.target.value });
  }

  const handleVolumeOnChange = (event) => {
    if(event.target.name === 'mount_path'){
      event.target.value = event.target.value.replace(/[$:]/g,'')
    }
    if (event.target.name === 'server_ip') {
      const trimmedValue = event.target.value.trim();
      setVolumeSettings({ ...volumeSettings, [event.target.name]: trimmedValue });
    } else {
      setVolumeSettings({ ...volumeSettings, [event.target.name]: event.target.value });
    }
  };

  function validateLinuxPath(path) {
    // Check Linux path validation regex pattern
    const pattern = /^\/(?:[^/]+\/)*[^/]+$/;
    return pattern.test(path);
  }

  const ValidateIPaddress = (ipaddress) => {
    const hostNameRegex = /^([a-zA-Z0-9]+([a-zA-Z0-9\-]*[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/;

    const isValidIPv4Address = Constants.IPv4_regex.test(ipaddress);
    const isValidIPv6Address = Constants.IPv6_regex.test(ipaddress);
    const isValidHostnameAddress = hostNameRegex.test(ipaddress);

    if (isValidIPv4Address || isValidIPv6Address || isValidHostnameAddress) {
      return true;
    } else {
      return false;
    }
  };

  const saveInstance = (event) => {
    let jsonData = Object.assign({}, volumeSettings);
    let volumeName = jsonData.instance_id;
    let action = isEditPopup ? 'updated' : 'added';
    if (jsonData.volume_type === true) { 
      jsonData.volume_type = 'nfs';
      jsonData.mount_path = jsonData.mount_path;
      jsonData.server_ip = jsonData.server_ip;
      delete jsonData.named_mount_path;
    } else if (jsonData.volume_type === false) {
      jsonData.volume_type = 'named';
      jsonData.mount_path = jsonData.named_mount_path;
      delete jsonData.named_mount_path;
      jsonData.permission = 'rw';
    }

    let volumeList = Object.keys(volumeData.sorted_list)
    if (volumeList.length > 9) {
      swal(`Error Code: 10600 \n Error Message: Maximum limit is reached, up to 10 instances can be added`, { icon: 'error' });
      return;
    }

    if (isEmpty(volumeName)) {
      swal('Please enter instance id.', { icon: 'error' });
      return;
    }

    if(volumeName.length <= 1){
      swal('Instance id length should be > 1.', { icon: 'error' });
      return;
    }

    if ((jsonData.volume_type === 'nfs' && jsonData.server_ip === '') || (jsonData.volume_type === 'nfs'&&  jsonData.server_ip === undefined)) {
      swal('Please enter nfs server.', { icon: 'error' });
      return;
    }

    if(jsonData.volume_type === 'nfs' && !ValidateIPaddress(jsonData.server_ip)){
      swal('Please enter valid nfs server.', { icon: 'error' });
      return;
    }
    if ((jsonData.volume_type === 'nfs' && jsonData.mount_path === '') || (jsonData.volume_type === 'nfs' && jsonData.mount_path === undefined)) {
      swal('Please enter mount path.', { icon: 'error' });
      return;
    }

    if(jsonData.volume_type === 'nfs' && !validateLinuxPath(jsonData.mount_path)){
      swal('Please enter valid mount path.', { icon: 'error' });
      return;
    }


    let finalJson = {};
    finalJson.nickname = jsonData.nickname;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.VOLUME;
    finalJson.enabled = true;
    finalJson.services = {};
    delete jsonData.nickname;
    finalJson.services.volume1 = Object.assign({}, jsonData);

    setLoading(true);
    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/volume/' + volumeName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
      } else{
        axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/volume/' + volumeName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
     }
  };

  const deleteVolume = (instanceId) => {
    let volumeFound = false;
    let projectName = '';
    let volumeNameArr = [];
    if (Array.isArray(availableVolumes)) {
      for (const item of availableVolumes) {
        const instance = item.services.project1;
        Object.keys(instance.volumes).map((idx) => {
          Object.keys(instance.volumes[idx]).map((i) => {
            volumeNameArr.push(i);
          })
        });

        if (volumeNameArr && volumeNameArr.some(volume => volume === selectedInstanceID)) {
          volumeFound = true;
          projectName = instance.project_name; // Get the project name
          break;
        }
      }
    }
    if (volumeFound) {
      swal('This volume is attached with SDK project(s). Please delete the project(s) or detach the volume from the project(s).', { icon: 'error' });
    } else {
      swal({
        text: 'Enter instance id to delete',
        content: 'input',
        buttons: ['No', 'Yes'],
        dangerMode: true,
        icon: 'warning'
      }).then((isConfirm) => {
        if(isConfirm === null){
          return;
        }
        if (isConfirm === selectedInstanceID) {
          axios
            .delete(Constants.EDGE_API_ENDPOINT + '/sdk/volume/' + selectedInstanceID + '/all')
            .then((res) => {
              swal('Instance delete request submitted successfully.', { icon: 'success' });
              setTimeout(function () {
                window.location.reload();
              }, 2000);
            })
            .catch((error) => {
              if (error.response) {
                var errorObj = error.response.data;
                swal(
                  'Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message,
                  { icon: 'error' }
                );
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        } else {
          return swal({ text: 'Instance id not match.', icon: 'error' });
        }
      });
    }
  };

  // volumeSettings.named_mount_path = `/var/lib/docker/volumes/${volumeSettings.instance_id}/_data`;

  return (
    <div>
      {volumeSettings !== undefined &&
        <PopupContainer>
          <SectionHeaderVolume style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Instance</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteVolume" name="btnDeleteVolume" style={{ 'margin': '0px' }} onClick={deleteVolume}>Delete</Button>
              </div>
            }
          </SectionHeaderVolume>
          <ResponsiveTabs defaultTab="general" style={{ 'padding': '6px' }}>
              {/* <TabList>
                <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              </TabList> */}
            <TabPanel tabId="general">
              <ResponsiveTabContent>
                <Row>
                <Row style={{ 'marginTop': '20px'}}>
                  <div style={{'display':'flex','marginTop':'-20px'}}>
                  <input style={{ 'margin': '1px 6px 0 0px' }} type="checkbox" id="volume_type" name="volume_type"  value={volumeSettings.volume_type} checked={volumeSettings.volume_type} disabled={isEditPopup ? true : false} onChange={handleSelectChange} /> 
                  <Label style={{'marginTop':'5px', 'color' : isEditPopup ? '#7e7d7d' : '' }} htmlFor="">NFS</Label>
                  </div>
                  </Row>
                  <InputFieldContainer>
                  <MandatoryLabel>Instance ID</MandatoryLabel>
                  <TextFieldSmall id="instance_id" autoComplete="off" name="instance_id" value={volumeSettings.instance_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="63" />
                  </InputFieldContainer>
                </Row>
                <InputFieldContainer>
                  <TextFieldLabel>Nickname</TextFieldLabel>
                  <TextFieldSmall id="nickname" autoComplete="off" name="nickname" value={volumeSettings.nickname} onChange={handleOnChange} maxLength="100" />
                </InputFieldContainer>
                {volumeSettings.volume_type === true &&
                  <div>
                  <InputFieldContainer>
                    <MandatoryLabel>NFS Server</MandatoryLabel>
                    <TextFieldSmall id="server_ip" autoComplete="off" name="server_ip" placeholder='192.168.10.1' value={volumeSettings.server_ip}  disabled={isEditPopup ? true : false} onChange={handleVolumeOnChange} style={{ 'marginTop': '20px' }} />
                  </InputFieldContainer>
                  <InputFieldContainer style={{'marginTop':'21px'}}>
                  <Label>Permission</Label>
                    <SelectField id="permission" name="permission" sty value={volumeSettings.permission}  disabled={isEditPopup ? true : false} onChange={handlePermissionChange}>
                      <option value='ro'>read-only</option>
                      <option value='rw'>read-write</option>
                    </SelectField>
                    </InputFieldContainer>
                    <InputFieldContainer>
                    <MandatoryLabel>Mount Path</MandatoryLabel>
                    <TextFieldSmall id="mount_path" autoComplete="off" placeholder='/nfsshare' name="mount_path" value={volumeSettings.mount_path} disabled={isEditPopup ? true : false}s onChange={handleVolumeOnChange} style={{ 'marginTop': '20px' }} />
                  </InputFieldContainer>
                  </div>
                }
                {/* {volumeSettings.volume_type === false &&
                  <ResponsiveRow2>
                    <MandatoryLabel>Mount Path</MandatoryLabel>
                    <TextFieldSmall id="named_mount_path" autoComplete="off" name="named_mount_path" value={volumeSettings.named_mount_path} readOnly={true} onChange={handleOnChange} style={{ 'marginTop': '20px' }} />
                  </ResponsiveRow2>
                } */}
              </ResponsiveTabContent>
            </TabPanel>
          </ResponsiveTabs>
          <Row>
            <PopupButtons>
              <Button onClick={saveInstance} primary >Save</Button>
              <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Volumes;