import React, { useCallback, useEffect, useRef, useState } from 'react';
import swal from "sweetalert";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { CircularProgress } from 'react-md';
import { Spacer } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import IPv6RoutingSettings from './IPv6RoutingSettings';
import IPv4RoutingSettings from './IPv4RoutingSettings';

const IPRoutingContainer = () => {

    const [routeDataIpv4, setRouteDataIpv4] = useState();
    const [routeDataIpv6, setRouteDataIpv6] = useState();
    const [retryIpv4, setRetryIpv4]=useState(false);
    const [retryIpv6, setRetryIpv6]=useState(false);
    const [loadingIpv4, setIsLoadingIpv4]=useState(true);
    const [loadingIpv6, setIsLoadingIpv6]=useState(true);

    const getRoutesIpv4 = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
            retries: 6,
            onRetry: (retryCount, error) => {
                if (retryCount < 5 && retryCount > 2) {
                    setRetryIpv4(true)
                } else if (retryCount === 6) {
                    swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
                }
            },
            retryDelay: () => { return 50; },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/system/network/interfaces/show_ipv4_routes/status/all')
            .then(res => {
                let ifData = res.data.data;
                let sortedData = [];
                Object.keys(ifData).map((idx) => {
                    let data = {};
                    let pData = ifData[idx];
                    data.destination = pData[1].destination
                    data.gateway = pData[1].gateway
                    data.genmask = pData[1].genmask
                    data.metric = pData[1].metric
                    data.inf_name = pData[0]
                    sortedData.push(data);
                    return idx;
                });
                sortedData.sort(function (a, b) {
                    return a.metric - b.metric;
                });
                setRouteDataIpv4(sortedData);
                setIsLoadingIpv4(false)
            }).catch(err => {
                setIsLoadingIpv4(false)
                console.log(err);
            }).finally(() => {
                setIsLoadingIpv4(false);
            });
    }

    const getRoutesIpv6 = () => {
        const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
        axiosRetry(client, {
            retries: 6,
            onRetry: (retryCount, error) => {
                if (retryCount < 5 && retryCount > 2) {
                    setRetryIpv6(true)
                } else if (retryCount === 6) {
                    swal('HTTP Error: ' + error.response.status + ' (' + error.response.statusText + '). Please check your network.', { icon: 'error' });
                }
            },
            retryDelay: () => {
                return 500;
            },
            retryCondition: (error) => {
                return true;
            },
        });
        client.get('/system/network/interfaces/show_ipv6_routes/status/all')
            .then(res => {
                let ifData = res.data.data;
                let sortedData = [];
                Object.keys(ifData).map((idx) => {
                    let data = {};
                    let pData = ifData[idx];
                    data.destination = pData[1].destination
                    data.next_hop = pData[1].next_hop
                    data.metric = pData[1].metric
                    data.inf_name = pData[0]
                    sortedData.push(data);
                    return idx;
                });
                sortedData.sort(function (a, b) {
                    return a.metric - b.metric;
                });
                setRouteDataIpv6(sortedData);
                setIsLoadingIpv6(false)
            }).catch(err => {
                setIsLoadingIpv6(false)
                console.log(err);
            }).finally(() => {
                setIsLoadingIpv6(false)
            });
    }

    useEffect(() => {
        getRoutesIpv4();
        getRoutesIpv6();
    }, [])

    return (<>
        {(loadingIpv4 || loadingIpv6) ?
            <div className="loading-msg">
                <label>{(retryIpv4 || retryIpv6) ? 'Retrying...' : 'Loading...'}</label>
                <CircularProgress />
            </div>
        :
        routeDataIpv4 === undefined && routeDataIpv6 ?
            <>
                <IPv6RoutingSettings initialRouteData={routeDataIpv6} isLoading={loadingIpv6} />
                <Spacer />
                <IPv4RoutingSettings initialRouteData={routeDataIpv4} isLoading={loadingIpv4} />
                <Spacer />
            </>
        :
            <>
                <IPv4RoutingSettings initialRouteData={routeDataIpv4} isLoading={loadingIpv4} />
                <Spacer />
                <IPv6RoutingSettings initialRouteData={routeDataIpv6} isLoading={loadingIpv6} />
                <Spacer />
            </>
        }
    </>);
}

export default IPRoutingContainer;