import React from 'react';
import { CircularProgress } from 'react-md';

const Loader = ({ isLoading, showLabel, label }) => {

    return (
        isLoading ?
            <div className="loading-msg">
                {
                    showLabel && <label>{label}</label>
                }
                <CircularProgress />
            </div> :
            null
    )
}

Loader.defaultProps = {
    showLabel: true,
    label: "Loading..."
};

export default Loader;