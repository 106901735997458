import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { SelectionControl } from 'react-md';
import { Row, Wrapper, Button, RightDiv, SectionHeader, SectionLabel, Label, CopyButton, SelectField, TextField, FieldSet } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import swal from 'sweetalert';
import PasswordField from './PasswordField.js';
import ls from 'local-storage';
import {validateNetworkAddress,onBlurIpAddress} from './NetworkSettings.js';

const Column = styled.div`
  width: 50%;
  float:left;
  padding: 10px 0px
`;

const ContentDiv=styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TextFieldLabel = styled(Label)`
  width: 185px;
  margin-top: 25px;
  @media screen and (max-width: 1024px) {
    width: 165px;
  }
  @media screen and (max-width: 700px) {
    width: 150px;
  }
  @media screen and (max-width: 500px) {
    width: 120px;
  }
  @media screen and (max-width: 400px) {
    width: 100px;
  }
`;

const CopyButtonContainer = styled(CopyButton)`
  @media screen and (max-width: 500px) {
    transform: translateY(3px);
  }
`;

const IpInputContainer = styled(Row)`
  display: flex;
  align-items: baseline;
  @media screen and (max-width: 860px) {
    flex-direction: column;
    ${TextFieldLabel} {
      margin-top: 12px;
    }
  }
`;

const IpTextFieldContainer = styled.div`
  @media screen and (max-width: 860px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const IpTextFieldSmall = styled(TextField)`
  width: 50px;
  @media screen and (max-width: 500px) {
    width: 40px;
  }
  @media screen and (max-width: 400px) {
    width: 35px;
  }
`;

const TextFieldSmall = styled(TextField)`
  width: 205px;
  @media screen and (max-width: 1024px) {
    width: 185px;
  }
  @media screen and (max-width: 890px) {
    width: 100%;
    flex-grow: 1;
  }
`;

const SelectFieldLabel = styled(Label)`
  width: 185px;
  margin-top: 25px;
  @media screen and (max-width: 1024px) {
    width: 165px;
  }
  @media screen and (max-width: 700px) {
    width: 150px;
  }
  @media screen and (max-width: 500px) {
    width: 120px;
  }
  @media screen and (max-width: 400px) {
    width: 100px;
  }
`;

const SelectFieldSmall = styled(SelectField)`
  width: 205px;
  @media screen and (max-width: 1024px) {
    width: 185px;
  }
`;

const ResponsiveRow = styled(Row)`
  width: 550px;
  @media screen and (max-width: 890px) {
    width: 525px;
  }
  @media screen and (max-width: 860px) {
    width: 435px;
  }
  @media screen and (max-width: 750px) {
    width: 390px;
  }
  @media screen and (max-width: 700px) {
    width: 360px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 400px) {
    width: 275px;
  }
  @media screen and (max-width: 375px) {
    width: 245px;
  }
`;
const ResponsiveColumn = styled(Column)`
  padding-top: 0px;
  padding-bottom: 5px;
  width: 550px;
  @media screen and (max-width: 890px) {
    width: 525px;
  }
  @media screen and (max-width: 860px) {
    width: 435px;
  }
  @media screen and (max-width: 750px) {
    width: 390px;
  }
  @media screen and (max-width: 700px) {
    width: 360px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 400px) {
    width: 275px;
  }
  @media screen and (max-width: 375px) {
    width: 245px;
  }
`;

const FieldsetContainer = styled.div`
  clear: both;
  margin-top: 5px;
  marginLeft:26px;
  @media screen and (max-width: 450px) {
    marginLeft: 0;
  }
`;

const setNetworkAccessIP = (networkAddressIP) => {
  let ipData = networkAddressIP.split('.');
  let joinAddress = ipData[0] + '.' + ipData[1] + '.' + ipData[2] + '.0/24';
  return joinAddress;
};

const setDHCPRange= (network_ip) => {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.([1]\/?([1-9]|[12][0-9]|3[0-2])?)$/.test(network_ip)){
    const Netmask = require('netmask').Netmask;
    let na = network_ip;
    let allData = new Netmask(na);
    let firstIp = allData.first.split('.');
    firstIp[3] = parseInt(firstIp[3])+1;
    let lastIp = allData.last.split('.');
    let firstAddress=firstIp.join('.');
    let lastAddress = lastIp.join('.');
    return `[${firstAddress} - ${lastAddress}]`;
  }
  else {
    return 'Invalid Network Address';
  }
};

const generateRandomPassword = (length) => {
  let result = '';
  let characters = 'ABCDEFGHJKLMNPRSUVWXYZadefhmnrt23456789:@.,/+-!=';
  let charLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

function OnBoardWifi(props) {
  const [readOnly, setReadOnly]=useState(true);
  const [brcmSettings, setbrcmSettings] = useState();
  const deviceId = ls.get('device_id') === undefined ? '' : ls.get('device_id');
  const [allowEnableBRCM, setAllowEnableBRCM]=useState(true);
  const [allowEnableAP, setAllowEnableAP] = useState(true);

  useEffect(() =>{
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/brcm/all')
      .then(res =>{
        let brcmData = res.data.data;
        delete brcmData.jsonData;
        if('password' in brcmData && brcmData.password === ''){
          brcmData.password = generateRandomPassword(12);
        }
        if(!brcmData.ap_wifi_type){
          brcmData.ap_wifi_type = ['wifi_client'];
        }
        if(brcmData.ip_address) {
          let ipData = brcmData.ip_address.split('.');
          brcmData.ip_address1 = ipData[0];
          brcmData.ip_address2 = ipData[1];
          brcmData.ip_address3 = ipData[2];
        }
        setbrcmSettings(brcmData);
       
        setNetworkAccessIP(brcmData.ip_address);
        setDHCPRange(brcmData.ip_address);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
    
  useEffect(() =>{
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/ports/all')
      .then(res =>{
        let portData = res.data.data;
        if(portData){
          if('wlan0' in portData.services){
            setAllowEnableBRCM(false);
          }
          if('ap0' in portData.services){
            setAllowEnableAP(false);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleOnChange= (event) =>{
    setReadOnly(readOnly);
    if(event.target.name === 'password'){
      event.target.value = event.target.value.replace(/[T{}()~*?"';\\^$|#\s]/g, '');
    } 
    setbrcmSettings({...brcmSettings, [event.target.name]: event.target.value});
  };

  const selectHandleOnchange= (event)=>{
    let data = {...brcmSettings, [event.target.name]: event.target.value};
    if(event.target.value === 'default'){
      data = {...data, 'ssid': deviceId};
    }
    else{
      data = {...data, 'ssid': ''};
    }
    setbrcmSettings(data);
  };

  const clickToCopy = (elementName) => {
    let copyElement = document.getElementsByName(elementName)[0];
    const textarea = document.createElement('textarea');
    textarea.id = 'copyTextArea';
    textarea.value = copyElement.value;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.width = '0px';
    textarea.style.height = '0px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    copyElement.blur();
    document.getElementById('copyTextArea').remove();
  };

  const updateEnabledToggle = (value, event) => {
    let jsonData = Object.assign({}, brcmSettings);
    let fullIpAddress = brcmSettings.ip_address1 + '.' +  brcmSettings.ip_address2 + '.' +  brcmSettings.ip_address3 + '.' +  '1/24';
    delete jsonData.ip_address1;
    delete jsonData.ip_address2;
    delete jsonData.ip_address3;

    if(jsonData.ap_wifi_type.includes('wifi_client') && !jsonData.ap_wifi_type.includes('access_point')) {
      jsonData.ap_enabled = false;
      jsonData.brcm_wifi = true;
    } else if(!jsonData.ap_wifi_type.includes('wifi_client') && jsonData.ap_wifi_type.includes('access_point')) {
      jsonData.ap_enabled = true;
      jsonData.brcm_wifi = false;
    } else if(jsonData.ap_wifi_type.includes('wifi_client') && jsonData.ap_wifi_type.includes('access_point')) {
      jsonData.ap_enabled = true;
      jsonData.brcm_wifi = true;
    } else if(Object.keys(jsonData.ap_wifi_type).length === 0) {
      jsonData.ap_enabled = false;
      jsonData.brcm_wifi = false;
      delete jsonData.ap_wifi_type;
      jsonData.ap_wifi_type = '';
    }

    jsonData.subnet_prefix_length = '24'
    jsonData.ip_address = fullIpAddress;
    jsonData.brcm_enabled = value;
    delete jsonData.jsonData;

    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/brcm/all', jsonData)
      .then(res => {
        setbrcmSettings({...brcmSettings, brcm_enabled: value});
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
        }
      });
  }

  const handleToggleBRCMEnable = (value, event) => {
    if(event.target.name === 'brcm_enabled' && !allowEnableBRCM) {
      swal('Onboard Wi-Fi setting cannot be changed. Please delete \'wlan0\' from Ports to change the setting.', { icon: 'error'});
      return 
    } else if(event.target.name === 'brcm_enabled' && !allowEnableAP) {
      swal('Onboard Wi-Fi setting cannot be changed. Please delete \'ap0\' from Ports to change the setting.', { icon: 'error'});
      return;
    } else {
      updateEnabledToggle(value, event);
    }
    updateEnabledToggle(value, event);
  };

  const handleToggleChange = (value, event) => {
    let data = {...brcmSettings, [event.target.id]: value};
    setbrcmSettings(data); 
  };

  const handleRadioChange = (event) => {
    if(event.target.value === 'wifi_client' && !allowEnableBRCM) {
      swal('Onboard Wi-Fi setting cannot be changed. Please delete \'wlan0\' from Ports to change the setting.', { icon: 'error'});
      let data = { ...brcmSettings };
      setbrcmSettings(data);
      return;
    } else if(event.target.value === 'access_point' && !allowEnableAP){
      let data = { ...brcmSettings };
      setbrcmSettings(data);
      swal('Onboard Wi-Fi setting cannot be changed. Please delete \'ap0\' from Ports to change the setting.', { icon: 'error'});
      return;
    }
    const checkboxes = document.querySelectorAll('input[name="ap_wifi_type"]:checked');
    let items = [];
      checkboxes.forEach((checkbox) => {
        items.push(checkbox.value);
      });
      let data = { ...brcmSettings };
      data[event.target.name] = items;
      setbrcmSettings(data);
    }

  const handleCheckboxChange =(event) =>{
    if(event.target.id === 'brcm_enable_at_reboot' && allowEnableBRCM === false){
      swal('Onboard Wi-Fi setting cannot be changed. Please delete \'wlan0\' from Ports to change the setting.', { icon: 'error'});
      return;
    }
    if(event.target.name === 'brcm_enable_at_reboot' && event.target.checked === true){
      let data = {...brcmSettings, [event.target.name]: event.target.checked};
      setbrcmSettings(data);
    } else {
      setbrcmSettings({...brcmSettings, [event.target.name]: event.target.checked});
    }
  };

  const validateIP = (fullIpAddress) => {
    let re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.([1]\/?([1-9]|[12][0-9]|3[0-2])?)$/;
    return re.test(fullIpAddress);
  }

  const saveBrcm=(event) =>{
    let jsonData = Object.assign({}, brcmSettings);
    let fullIpAddress = brcmSettings.ip_address1 + '.' +  brcmSettings.ip_address2 + '.' +  brcmSettings.ip_address3 + '.' +  '1/24';

    if (!validateIP(fullIpAddress)) {
      swal({ text: 'Invalid Access Point',icon: 'error' });
      return;
    }
    if(brcmSettings.ssid_type ==='default'){
      brcmSettings.ssid = 'default';
    }
    if(brcmSettings.ssid_type === 'custom'){
      if(isEmpty(brcmSettings.ssid)){
        swal('Please enter SSID.', { icon: 'error'});
        return;
      }
    }
    if(isEmpty(brcmSettings.password)){
      swal('Please enter password.', { icon: 'error'});
      return;
    } else if ((brcmSettings.password).length < 8){
      swal('Password length must be >= 8 characters.', { icon: 'error'});
      return;
    }

    delete jsonData.ip_address1;
    delete jsonData.ip_address2;
    delete jsonData.ip_address3;

    if(jsonData.ap_wifi_type.includes('wifi_client') && !jsonData.ap_wifi_type.includes('access_point')) {
      jsonData.ap_enabled = false;
      jsonData.brcm_wifi = true;
    } else if(!jsonData.ap_wifi_type.includes('wifi_client') && jsonData.ap_wifi_type.includes('access_point')) {
      jsonData.ap_enabled = true;
      jsonData.brcm_wifi = false;
    } else if(jsonData.ap_wifi_type.includes('wifi_client') && jsonData.ap_wifi_type.includes('access_point')) {
      jsonData.ap_enabled = true;
      jsonData.brcm_wifi = true;
    } else if(Object.keys(jsonData.ap_wifi_type).length === 0) {
      swal('Please check atleast one ap_wifi_type', { icon: 'error'});
      return;
    }

    jsonData.subnet_prefix_length = '24'
    jsonData.ip_address = fullIpAddress;
    delete jsonData.jsonData;

    axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/brcm/all', jsonData)
      .then(res => {
        swal('Onboard Wi-Fi (BRCM) settings updated successfully.', { icon: 'success', timer: '3000'});
        setReadOnly(true);
        setbrcmSettings({...brcmSettings, jsonData})
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
        }
      });
  };

  const cancelBrcm=(event) =>{
    window.location.reload();
  };

  const editBrcm=(event) =>{
    setReadOnly(false);
  };

  return (
    <ContentDiv>
      { brcmSettings  && 
        <Wrapper style={{'marginTop':'5px'}}>
          <SectionHeader>
            <SectionLabel>Onboard Wi-Fi (BRCM)</SectionLabel>
          </SectionHeader>
          <Row style={{'marginLeft':'23px', 'width': 'auto'}}>
            <Column style={{display:'flex'}}>
              <SelectionControl id="brcm_enabled" type="switch" label={brcmSettings.brcm_enabled ? 'Enabled' :'Disabled'} checked={brcmSettings.brcm_enabled || false } value={brcmSettings.brcm_enabled} onChange={handleToggleBRCMEnable } name="brcm_enabled" labelBefore="true" style={{marginTop: '10px'}} />
            </Column>
          </Row>
              {brcmSettings.brcm_enabled &&
                <>
                  <Row style={{'display':'flex', 'flexDirection':'column', 'marginLeft':'23px', width: 'auto'}}>
                    <Column style={{'display': 'flex', 'width': '100%'}}>
                      <input type="checkbox" id="wifi_client" name="ap_wifi_type" value="wifi_client" onChange={handleRadioChange} disabled={readOnly} checked={(brcmSettings.ap_wifi_type.includes('wifi_client'))}></input><span style={{'paddingLeft':'10px'}}>Use as Wi-Fi Client (wlan0)</span>
                    </Column>
                    {(brcmSettings.ap_wifi_type.includes('wifi_client')) &&
                      <div style={{'marginLeft':'25px'}}>
                        <Row>
                          <Column style={{'width': '100%'}}>
                            <input type="checkbox" id="brcm_enable_at_reboot" name="brcm_enable_at_reboot" value={brcmSettings.brcm_enable_at_reboot} disabled={readOnly} checked={brcmSettings.brcm_enable_at_reboot} onChange={handleCheckboxChange} /> 
                            <span style={{'paddingLeft':'10px', width:'500px'}}>Disable if external Wi-Fi client device is working</span>
                          </Column>
                            { brcmSettings.brcm_enable_at_reboot  &&
                            <Column style={{'width': '100%', 'marginLeft':'5px'}}>
                              <TextFieldLabel style={{'marginTop':'0'}}>Activation Wait (Secs)</TextFieldLabel>
                              <TextFieldSmall id="activation_wait" disabled={readOnly} value={brcmSettings.activation_wait} onChange={handleOnChange} name="activation_wait" style={{'width':'175px'}} />
                            </Column>}
                        </Row>
                      </div>}
                      <Column style={{'display': 'flex', 'width': '100%'}}>
                        <input type="checkbox" id="access_point" name="ap_wifi_type" value="access_point" onChange={handleRadioChange} disabled={readOnly} checked={(brcmSettings.ap_wifi_type.includes('access_point'))}></input><span style={{'paddingLeft':'10px'}}>Use as Access Point (ap0)</span>
                      </Column>
            {(brcmSettings.ap_wifi_type.includes('access_point')) &&
              <FieldsetContainer>
                <ResponsiveColumn>
                  <FieldSet style={{'width': 'auto'}}>
                    <legend>Access Point Configuration</legend>
                    <Row>
                      <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <TextFieldLabel style={{flexShrink: '0'}}>Network Name (SSID)</TextFieldLabel>
                        <SelectFieldSmall name="ssid_type" disabled={readOnly} value={brcmSettings.ssid_type} onChange={selectHandleOnchange} style={{'width': '100%', flexGrow: '1'}}>
                          { Object.keys(Constants.SSID).map((idx) => {
                            return(<option value={Constants.SSID[idx].value}>{Constants.SSID[idx].name}</option>);
                          })}
                        </SelectFieldSmall>
                      </div>
                      {brcmSettings.ssid_type==='default' &&
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                          <TextFieldLabel style={{flexShrink: '0'}}>&nbsp;</TextFieldLabel>
                          <div style={{width: '100%', flexGrow: '1', display: 'flex', alignItems: 'baseline'}}>
                            <TextFieldSmall id="ssid" disabled={readOnly} onChange={handleOnChange} style={{'marginTop':'25px', 'width': '100%'}} value={deviceId} name="ssid" title={brcmSettings.ssid} />
                            <CopyButtonContainer style={{'marginLeft': '6px', flexShrink: '0'}} onClick={() => clickToCopy('ssid')}/>
                          </div>
                        </div>}
                      {brcmSettings.ssid_type==='custom' &&
                      <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <TextFieldLabel style={{flexShrink: '0'}}>&nbsp;</TextFieldLabel>
                        <div style={{width: '100%', flexGrow: '1', display: 'flex', alignItems: 'baseline'}}>
                          <TextFieldSmall id="ssid" placeholder="Enter SSID" value={brcmSettings.ssid} disabled={readOnly} style={{'marginTop':'25px', 'width': '100%'}} onChange={handleOnChange} name="ssid" title={brcmSettings.ssid}/>
                          <CopyButtonContainer style={{'marginLeft': '6px', flexShrink: '0'}} onClick={() => clickToCopy('ssid')}/>
                        </div>
                      </div>}
                    </Row>
                    <IpInputContainer style={{display: 'flex', alignItems: 'baseline'}}>
                      <TextFieldLabel style={{flexShrink: '0'}}>Access Point IP</TextFieldLabel>
                      <IpTextFieldContainer>
                        <IpTextFieldSmall disabled={readOnly} id="ip_address1" name="ip_address1" value={brcmSettings.ip_address1} maxLength={3}  onChange={handleOnChange} /> &nbsp;.&nbsp;
                        <IpTextFieldSmall disabled={readOnly} id="ip_address2" name="ip_address2" value={brcmSettings.ip_address2} maxLength={3}  onChange={handleOnChange} /> &nbsp;.&nbsp;
                        <IpTextFieldSmall disabled={readOnly} id="ip_address3" name="ip_address3" value={brcmSettings.ip_address3} maxLength={3}  onChange={handleOnChange} /> &nbsp;.&nbsp;
                        <IpTextFieldSmall disabled={true} id="ip_address4" name="ip_address4" value='1' maxLength={3} /> &nbsp;/&nbsp;
                        <IpTextFieldSmall disabled={true} id="ip_address5" name="ip_address5" value='24' maxLength={3} />
                      </IpTextFieldContainer>
                    </IpInputContainer>
                    <Row style={{display: 'flex', alignItems: 'baseline'}}>
                      <SelectFieldLabel style={{flexShrink: '0'}}>Authentication Mode</SelectFieldLabel>
                      <SelectFieldSmall name="auth_mode" value={brcmSettings.auth_mode} disabled={readOnly} onChange={handleOnChange} style={{'width': '100%', flexGrow: '1'}}>
                        { Object.keys(Constants.AUTHENTICATION_MODE_TYPES ).map((idx) => {
                          return(<option value={Constants.AUTHENTICATION_MODE_TYPES[idx].value}>{Constants.AUTHENTICATION_MODE_TYPES[idx].name}</option>);
                        })}
                      </SelectFieldSmall>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'baseline'}}>
                      <SelectFieldLabel style={{flexShrink: '0'}}> Password</SelectFieldLabel>
                      <div style={{'width': '100%', flexGrow: '1', display: 'flex', alignItems: 'baseline'}}>
                        <PasswordField id="password" readOnly={readOnly} value={brcmSettings.password} name="password" setInput={handleOnChange} width ='100%' />
                        <CopyButtonContainer style={{'marginLeft': '14px', flexShrink: '0'}} onClick={() => clickToCopy('password')}/>
                      </div>
                    </Row>
                    <div style={{display:'flex', alignItems: 'baseline'}}>
                      <SelectFieldLabel style={{flexShrink: '0'}}>Wi-Fi Frequency Band </SelectFieldLabel>
                      <SelectFieldSmall name="frequency" disabled={readOnly} value={brcmSettings.frequency} onChange={handleOnChange} style={{'width': '100%', flexGrow: '1'}}>
                        { Object.keys(Constants.FREQUENCY ).map((idx) => {
                          return(<option value={Constants.FREQUENCY[idx].value}>{Constants.FREQUENCY[idx].name}</option>);
                        })}
                      </SelectFieldSmall>
                    </div>
                  </FieldSet>
                </ResponsiveColumn>
                <ResponsiveRow>
                  <FieldSet>
                    <legend>DHCP Service</legend>
                    <Row >
                      <SelectionControl id="dhcp" label={brcmSettings.dhcp ? 'Enabled':'Disabled'} type="switch"  checked={brcmSettings.dhcp || false } value={brcmSettings.dhcp} onChange={handleToggleChange } name="dhcp" style={{'marginTop':'16px'}} labelBefore="true" />
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'baseline'}}>
                      <TextFieldLabel style={{flexShrink: '0'}}>Network Address</TextFieldLabel>
                      <TextFieldSmall style={{flexGrow: '1'}} value={setNetworkAccessIP(brcmSettings.ip_address)} disabled={true} id="network_access_ip" name="network_access_ip" />
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'baseline'}}>
                      <TextFieldLabel style={{flexShrink: '0'}}>DHCP Range</TextFieldLabel>
                      <TextFieldLabel style={{flexGrow: '1'}}>{setDHCPRange(brcmSettings.ip_address)}</TextFieldLabel>
                    </Row>
                    
                    <div style={{'color':'grey', 'marginTop': '5px'}}>Access point and broadcast addresses are excluded.</div>
                  </FieldSet>
                </ResponsiveRow>
                {/* <Column>
                  <FieldSet>
                      <legend style={{"marginLeft":"3px"}}>Bridge</legend>
                      <Row>
                          <div style={{display:"flex", "marginTop":"5px"}}>
                              <SelectFieldLabel style={{"marginLeft":"4px"}} >Enabled</SelectFieldLabel>
                              <SelectionControl id="bridge_enabled" type="switch"  checked={brcmSettings.bridge_enabled || false } value={brcmSettings.bridge_enabled} onChange={handleToggleChange } name="bridge_enabled" style={{"marginLeft":"0px","marginTop":"16px"}} labelBefore="true" />
                          </div>
                      </Row>
                  { brcmSettings.bridge_enabled  &&
                      <div>
                      <SelectFieldLabel>Target Link</SelectFieldLabel>
                      { linksData !== undefined &&
                          <SelectFieldSmall name="bridge_if_name" value={brcmSettings.bridge_if_name} onChange={handleOnChange} style={{"width":"160px","marginLeft":"10px"}} disabled={readOnly}>
                          { Object.keys(linksData.services).map((link_name) => {
                              let data = linksData["services"][link_name]
                              let nick_link = "";
                              if("nickname" in data && data["nickname"].length > 0 ){
                                nick_link =  linksData["services"][link_name]["nickname"] + "(" + link_name + ")";
                              } else {
                                  nick_link = link_name
                              }
                              return(<option value={decodeURIComponent(link_name)}>{decodeURIComponent(nick_link)}</option>)
                          })}
                          </SelectFieldSmall>
                      }
                      </div>
                  }
                  </FieldSet>
                  <FieldSet>
                    <legend style={{"marginLeft":"3px"}}>IP Masquerading </legend> 
                      <div style={{display:"flex", "marginTop":"5px"}}>
                              <SelectFieldLabel style={{"marginLeft":"4px"}} >Enabled</SelectFieldLabel>
                              <SelectionControl id="ipmasque_enabled" type="switch"  checked={brcmSettings.ipmasque_enabled || false } value={brcmSettings.ipmasque_enabled} onChange={handleToggleChange } name="ipmasque_enabled" style={{"marginLeft":"0px","marginTop":"16px"}} labelBefore="true" />
                      </div>
                  { brcmSettings.ipmasque_enabled &&
                  <div>
                      <div>
                      <SelectFieldLabel>Target Link</SelectFieldLabel>
                      { linksData !== undefined &&
                          <SelectFieldSmall name="ipmasque_if_name" value={brcmSettings.ipmasque_if_name} onChange={handleOnChange} style={{"width":"160px","marginLeft":"10px"}} disabled={readOnly}>
                          { Object.keys(linksData.services).map((link_name) => {
                              let data = linksData["services"][link_name]
                              let nick_link = "";
                              if("nickname" in data && data["nickname"].length > 0 ){
                                nick_link =  linksData["services"][link_name]["nickname"] + "(" + link_name + ")";
                              } else {
                                  nick_link = link_name
                              }
                              return(<option value={decodeURIComponent(link_name)}>{decodeURIComponent(nick_link)}</option>)
                          })}
                          </SelectFieldSmall>
                      }
                      </div>
                  </div>
                  }
                  </FieldSet>
                </Column> */}
              </FieldsetContainer>}
              <ResponsiveRow>
                  { readOnly === true ?
                    <RightDiv>
                      <Button primary onClick={editBrcm}>Edit</Button>
                    </RightDiv>
                    :
                    <RightDiv>
                      <Button id="saveBrcmButton" primary onClick={saveBrcm}>Save</Button>
                      <Button id="cancelBrcmButton" onClick={cancelBrcm}>Cancel</Button>
                    </RightDiv>}
                </ResponsiveRow>
          </Row>
      </>}
    </Wrapper>}
    </ContentDiv>
  );
}

export default OnBoardWifi;
