import React from 'react'
import { useCallback } from 'react';

export default function CustomLink(props) {

  const {label, to, hasSubMenu, activateMe, toggleSubMenu, externalLink, children} = props;

  const handleClick = useCallback((e) => {
    if (hasSubMenu) {
      toggleSubMenu(e);
    } else if(externalLink) {
      window.open(
        to,
        '_blank' // <- This will open link in new window.
      )
    } else {
      activateMe({
        newLocation: to,
        selectedMenuLabel: label
      });
    }
  },[label, to, hasSubMenu, activateMe, toggleSubMenu]);

  return (
    <div className="metismenu-link" onClick={handleClick}>
      {children}
    </div>
  );
}
