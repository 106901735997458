
import MapGL, { Marker, NavigationControl, Popup } from 'react-map-gl';
import React, {useState, useRef, useMemo, useEffect, useCallback} from 'react'
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';
import markerIconGreen from '../images/green-camera.svg';
import markerIconGrey from '../images/grey-camera.svg';
import markerIconBlue from '../images/blue-camera.svg';
import markerIconRed from '../images/red-camera.svg';
import markerIconYellow from '../images/yellow-camera.svg';
import markerIconBlack from '../images/black-camera.svg';
import markerIconMap from '../images/marker-icon.png';

const navStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px'
  };

const MapContainer = styled.div`
margin: 20px;
@media screen and (max-width: 500px) {
    overflow-x: auto;
}
`;

const ToolTipText = styled.pre`
  visibility: hidden;
  margin: 0;
  padding: 5px;
  font-size: 10px;
  line-height: 1.2;
  text-align: left;
  border: 1px solid hsl(195deg 5% 60% / 72%);
  box-shadow: 2px 2px 4px hsl(195deg 16% 72% / 72%);
  background-color: #f8f9fa;
  outline: none !important;

  /* Position of the tooltip */
  position: absolute;
  top: -75%;
  left: 20%;
  z-index: 1;
`;


const DivCopyWrapper = styled.div`
  cursor: pointer;
  margin-left:15px;
  margin-top:2px;
  position: relative;
  &:hover ${ToolTipText} {
    top: -110%;
    visibility: ${props => props.tooltipState === true ? 'visible' : 'hidden'};
  }
`;


//eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function Mapbox(props) {
    const {mapToken, streamStatus, stackList, getStreamStatus, getStackTooltipDetails,position} = props;
    const [selectMode, setSelectMode] = useState('mapbox://styles/mapbox/streets-v11');
    const [viewport, setViewport] = useState({
        latitude: 35.65283,
        longitude: 139.83947,
        zoom: 8.5,
        bearing: 0,
        pitch: 0
      });
      const center = {
        lng: '139.83947',
        lat: '35.65283',
      }
      
    const markerIcon = (stack_name) => {
      let icon = '';
      let status = getStreamStatus(stack_name);
      if(status === 'running') {
        icon = markerIconGreen;
      } else if (status === 'disabled') {
        icon = markerIconGrey;
      } else if (status === 'recovering') {
        icon = markerIconYellow;
      } else if (status === 'failed') {
        icon = markerIconRed;
      } else if (status === 'paused') {
        icon = markerIconBlue;
      } else if (status === 'desired') {
        icon = markerIconYellow;
      } else {
        icon = markerIconBlack;
      }
      return icon;
    }
    
    const pins = useMemo(
      () =>
      Object.keys(stackList).map((stack_name) => {
        const {location, description, services} = stackList[stack_name];
        let des = description === '' ? '-' : description;
        let text =getStackTooltipDetails(stack_name, services['recorder1']['camera_type']) + `${ description !== '' && description !=='-' && description==false ? null : description &&  `\n${des}` }`; 
        if(location.latitude && location.longitude) {
          return(
            <>
            {location.latitude && location.longitude &&
            <Marker
              key={stack_name}
              longitude={parseFloat(location.longitude)}
              latitude={parseFloat(location.latitude)}
              anchor="bottom"
              >
              <img style={{'cursor':'pointer'}} src={markerIcon(stack_name)} title={text} onClick={(e) => props.history.push('./view-stream?stream=' + stack_name)} />
            </Marker>}
            </>
          );
        }
        }),
      []
    );

    return (
        <MapContainer>
            <MapGL
             {...viewport}
              mapStyle={selectMode}
              width={'100%'}
              height={window.innerWidth <= 550 ? '350px' : '500px'}
              onViewportChange={setViewport}
              mapboxApiAccessToken={(mapToken === ' ' || mapToken === '') ? 'nomap' : mapToken}
            >
              {pins}
              <Marker
                    longitude={parseFloat(position.lon)}
                    latitude={parseFloat(position.lat)}
              >
                <img style={{'cursor':'pointer'}} src={markerIconMap} onClick={(e) => props.history.push('./location?general')} title={`This Device`} />
              </Marker>
            
              <div className="nav" style={navStyle}>
                <NavigationControl />
              </div>
            </MapGL>
        </MapContainer>
    )
}

export default React.memo(Mapbox);