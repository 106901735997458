import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link1, LinkIcon } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import axios from 'axios';
import { onRefreshApiTester } from './APITester';

const APITesterLink = (props) => { 
    const {onRefresh} = useContext(onRefreshApiTester);
    const [status, setStatus] = useState('');

    const getAPITesterDetailsStatus = useCallback(() => {
        if(onRefresh) {
          return;  
        }
        axios.get(Constants.EDGE_API_TESTER + props.port + '/')
        .then((res) => {
            if(res.status !== 200) {
                setStatus(res.status);
            } else {
                setStatus(res.status);
            }
        }).catch(err => {
        console.log(err)
        // setStatus(false);
        })
    },[onRefresh]);

    useEffect(() => {
        getAPITesterDetailsStatus();
    },[getAPITesterDetailsStatus]);

    return(
        <div>
            {props.enabled ? 
            <>
            {status !==200 ? 
            <div style={{'display': 'flex', 'align-items': 'center', 'justifyContent': 'center'}}>
                <div class="spinner-1" ></div>
                <div>&nbsp; &nbsp; Fetching dashboard link... </div>
            </div>:  
            <>
                <LinkIcon />
                <Link1 target="_blank" href={window.location.protocol + '//' + props.path + '/apitester-' + props.port + '/'}>Open Dashboard</Link1>
            </>}
            </> : '-' }
        </div>
    );
}

export default APITesterLink;