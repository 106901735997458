import React, { Component } from 'react';


const SelectOptions = (props) => {
    function createSelectOption(item) {
        // console.log(props);
        if(item.name == undefined){
            return <option value={item}>{item}</option>
        } else {
            return <option value={item.value}>{item.name}</option>
        }
    }

    return (
        props.items.map((item, key) =>
            createSelectOption(item)
        )
    );
}

export default SelectOptions;