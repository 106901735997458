import React, {useState} from 'react';
import { SelectionControl } from 'react-md';
import styled from 'styled-components';

const MapControlPannel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;

  @media screen and (max-width: 750px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 510px) {
    width: 140px;
    padding: 12px 14px;
    margin: 10px;
    font-size: 0.6rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.6rem;  
  }
`;

const ResponsiveSelectionControl = styled(SelectionControl)`
    height: 40px !important;
    @media screen and (max-width: 750px) {
        height: 30px !important;
    }
`;

function round5(value) {
  return (Math.round(value * 1e5) / 1e5).toFixed(5);
}

function ControlPanel(props) {
  const {events} = props;
  const [darkMode, setDarkMode] = useState(false);
  const [value, setValue] = useState();

  const handleToggle = (value, event) =>{
    setDarkMode(value);
    if(value) {
      props.mode('mapbox://styles/mapbox/dark-v9');
    } else {
      props.mode('mapbox://styles/mapbox/streets-v11');
    }
  }

  return (
    <MapControlPannel>
      <div>
        <b>Latitude: </b> <i>{round5(events.latitude)}</i><br/>
        <b>Longitude: </b> <i>{round5(events.longitude)}</i>
      </div>
      <ResponsiveSelectionControl id="mode" type="switch" label={darkMode ? "Light Mode" :"Dark Mode"} labelBefore='false' name="mode" checked={darkMode} value={darkMode} onChange={handleToggle} />
    </MapControlPannel>
  );
}

export default React.memo(ControlPanel);