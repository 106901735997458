import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { CircularProgress, SelectionControl } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, MdnsIpButton, CenterDiv, Label, SelectField, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, MandatoryLabel, Link1, LinkIcon, MessageLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import ls from 'local-storage';
import axiosRetry from 'axios-retry';
import { convertToTimeZone } from 'date-fns-timezone';
import { format, toDate } from 'date-fns';

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const TextArea = styled.textarea`
  max-width:0px;
  min-width:100%;
  min-height: 50px;
  max-height: 120px;
  font-size: 1.0em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 750px) {
    font-size: 0.95rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 0.68rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 0.65rem;
  }
`;

const ContentDiv = styled.div`
  overflow-y: auto;
  padding-bottom: 10px;
`;

const InputFieldContainer = styled.div`
  display: flex;
  align-items: baseline;
  & > label {
    width: 150px;
    flex-shrink: 0;
    @media screen and (max-width: 750px) {
      width: 105px;
    }
    @media screen and (max-width: 400px) {
      width: 90px;
    }
  }
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
  @media screen and (max-width: 475px) {
    width: 300px;
  }
  @media screen and (max-width: 400px) {
    width: 275px;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SectionHeaderProxy=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

const WebRedirect = (props) => {
  const [isEditPopup, setEditPopup] = useState(false);
  const [cameraData, setCameraData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState({});
  const [cameraNameList, setCameraNameList] = useState([]);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const deviceIP = ls.get('device_ip') === undefined ? window.location.hostname : ls.get('device_ip');
  const deviceID = ls.get('device_id') === undefined ? '' : ls.get('device_id') + '.local';
  const [isHikvisionList, setIsHikisionList] = useState(false);
  const [isAxisList, setIsAxisList] = useState(false);
  const [CurTimezone, setCurTimeZone]=useState();
  const [standaredTZ, setStandardTZ]=useState();
  const [retry,setRetry]=useState(false)

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  const nickNameStyle = {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: window.innerWidth >= 1920 ? '300px' : '200px'
  };

  const descriptionStyle = {
    padding: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };


  const urlStyle = {
    textAlign: 'center',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    maxWidth: '350px'
  };

  const editCamera = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewCamera = (event) => {
    setEditPopup(false);
     // setSelectedCamera(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let data = Object.assign({}, cameraData[0][name]);
    setSelectedCamera(data);
    setEditPopup(true);
  };

  const truncate = (input) =>{
    if(isMobileBrowser) {
      if (input.length > 12) {
        return input.substring(0, 12) + '...';
     }
    } else {
      if(window.innerWidth <= 1024) {
        if (input.length > 15) {
          return input.substring(0, 15) + '...';
       }
      } else {
        if (input.length > 28) {
          return input.substring(0, 28) + '...';
       }
      }
    }
    return input;
  };

  const enableToggle = (value, event) => {
    let ruleId = event.target.name;
    var actionLabel = value ? "enable" : "disable";

    var jsonData = {
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };

    var msg = "\n\n Are you sure you want to " + actionLabel + " this instance?";

    if (value) {
      axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + ruleId, jsonData)
        .then(res => {
          swal('Web proxy rule ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      swal({
        text: msg,
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          axios.post(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + ruleId, jsonData)
            .then(res => {
              swal('Web proxy rule ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      })
    }
  };

  const getCameraData = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    axiosRetry(client,{
      retries: 15,
      retryDelay: (retryCount, error) => {
        if(retryCount < 15 && retryCount > 5) {
          setRetry(true)
       } else if(retryCount === 15) {
         setRetry(false)
         swal('HTTP Error: ' +  error.response.status + ' (' +  error.response.statusText + '). Please check your network.',{icon: 'error'});
       }
          return 3000;
      },
      retryCondition: (error) => {
          return true;
      },
    });
    client.get('/system/network/webproxies/all')
      .then(res => {
        let data = res.data.data;
        let list = [];
        Object.keys(data).map((stackName) => {
          const stackData = data[stackName];
          Object.keys(stackData).map((i) => {
            if(stackData[i].action === 'DeleteService'){
              delete stackData[i];
            } else {
              list.push(stackData[i].webredirect_id)
            }
            return i;
          });
          return stackName;
        });
        setCameraNameList(list);
        let sortedData = [];
        Object.keys(data).map((stackName) => {
          const stackData = data[stackName];
          Object.keys(stackData).map((i) => {
            if(stackData[i].action === 'DeleteService'){
              delete stackData[i];
            } else {
              sortedData.push(stackData[i]);
            }
            return i;
          });
          return stackName;
        });
        sortedData.sort(function (a, b) {
          const dateFormatB = b.created_time.split(' ');
          const dateFormatA = a.created_time.split(' ');
          // let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
          // let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
          return dateFormatB - dateFormatA;
        });
        Object.keys(sortedData).map((stackName) => { 
          if(sortedData[stackName].type === 'hikvision') {
            setIsHikisionList(true);
          }else if(sortedData[stackName].type === 'axis'){
            setIsAxisList(true)
          }
        })
        data.sorted_list = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].webredirect_id;
          let instData = Object.assign({}, data[0][name]);
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedCamera(instData);
          setEditPopup(true);
        }
        setCameraData(data);
      }).catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getCameraData();
  }, [getCameraData]);

  const handleHostChange = (instName, type) => {
    let btnElement = document.getElementById(instName + '_mdns_button');
    let btnLink = document.getElementById(instName + '_link');

    function isHostnameAddress() {
      const address = window.location.hostname;

      // Check if the hostname or address is a valid hostname.
      if (/[a-z0-9-]+\.[a-z]+$/.test(address)) {
        // Machine is running on hostname.
        return true;
      } else {
        // Machine is running on IP address.
        return false;
      }
    }
  
    if(btnElement.innerText === 'mDNS'){
      btnElement.innerText = 'IP';
      if(type === 'direct') {
        if(window.location.protocol === 'http:') {
          if(isHostnameAddress()) {
            btnLink.href = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
            btnLink.innerText = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
            btnLink.title = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
          } else {
            btnLink.href = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName  + '/';
            btnLink.innerText = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName + '/ ';
            btnLink.title = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName  + '/';
          }  
        } else {
          btnLink.href = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
          btnLink.innerText = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
          btnLink.title = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
        }
      } else if(type === 'hikvision') {
        if(window.location.protocol === 'http:') {
          if(isHostnameAddress()) {
            btnLink.href = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
            btnLink.innerText = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
            btnLink.title = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
          } else {
            btnLink.href = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName  + '/';
            btnLink.innerText = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName + '/ ';
            btnLink.title = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName  + '/';
          }
        } else {
          btnLink.href = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
          btnLink.innerText = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
          btnLink.title = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
        }
      } else if( type === 'scorer_edge') {
        if(window.location.protocol === 'http:') {
          if(isHostnameAddress()) { 
            btnLink.href = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/stream-viewer';
            btnLink.innerText = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer" + ' ';
            btnLink.title = window.location.protocol + '//' + deviceIP  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer";
          } else {
            btnLink.href = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName + '/stream-viewer';
            btnLink.innerText = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName + "/stream-viewer" + ' ';
            btnLink.title = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName + "/stream-viewer";
          }
        } else {
          btnLink.href = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/stream-viewer';
          btnLink.innerText = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer" + ' ';
          btnLink.title = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer";
        }
      }else if(type === 'axis') {
        if(window.location.protocol === 'http:') {
          if(isHostnameAddress()) {
            btnLink.href = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
            btnLink.innerText = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
            btnLink.title = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
          } else {
            btnLink.href = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName  + '/';
            btnLink.innerText = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName + '/ ';
            btnLink.title = window.location.protocol + '//' + window.location.host + '/proxy/'+ instName  + '/';
          }
        } else {
          btnLink.href = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
          btnLink.innerText = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
          btnLink.title = window.location.protocol + '//' + deviceIP + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName  + '/';
        }
      }
    } else {
      btnElement.innerText = 'mDNS';
      if(type === 'direct') {
        btnLink.href = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
        btnLink.innerText = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/ ';
        btnLink.title = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
      } else if(type === 'hikvision') {
        btnLink.href = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
        btnLink.innerText = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
        btnLink.title = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
      }else if( type === 'axis') {
        btnLink.href = window.location.protocol + '//' + deviceID  + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
        btnLink.innerText = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
        btnLink.title = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + '/';
      } else if( type === 'scorer_edge') {
        btnLink.href = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer";
        btnLink.innerText = window.location.protocol + '//' + deviceID + ':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer" + ' ';
        btnLink.title = window.location.protocol + '//' + deviceID +':' + Constants.EDGE_UI_PORT + '/proxy/'+ instName + "/stream-viewer";
      } 
    }
  };

  const getClockStatus = useCallback(() => {
    const client = axios.create({ baseURL: Constants.EDGE_API_ENDPOINT });
    client.get('/system/clock/status/all')
    .then(res => {
      let data=res.data.data.timezone;
      let splitTimeZone = data.split(' (');
      setCurTimeZone(splitTimeZone[0])
      setStandardTZ(splitTimeZone[1])
      let splitZone = splitTimeZone[1].split(',');
      setStandardTZ(splitZone[0])
    }).catch(error => {
      console.error('clock data');
    });
  },[]);

  useEffect(()=>{
    getClockStatus()
  },[])

  const getDateFormate = (Date,CurTimezone) => {     
    try {
      const zonedTargetDate = convertToTimeZone(Date, { timeZone: CurTimezone });
      return zonedTargetDate;
    } catch (err) {
      console.error(err);
      // return new Date(Date);
    }
  };

  return (
    <ContentDiv>
      { (cameraData === undefined) &&
        <div className="loading-msg">
          <label>{retry ? 'Retrying...' : 'Loading...'}</label>
          <CircularProgress />
        </div>}
      {cameraData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <SectionHeader>
            <SectionLabel>Web Proxy Rules</SectionLabel>
          </SectionHeader>
          <Row>
            <ActionButtonContainer>
              { cameraData.sorted_list && Object.keys(cameraData.sorted_list).length > 0 &&
                <Button primary id="btnEditCamera" name="btnEditCamera" onClick={() => editCamera()}>Edit</Button>
              }
              <Button primary id="btnAddCamera" name="btnAddCamera" onClick={() => addNewCamera()}>Add</Button>
              <Popup modal closeOnDocumentClick={false} open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <WebRedirectPopup isEditPopup={isEditPopup} isHikvisionList={isHikvisionList} isAxisList={isAxisList} cameraNameList={cameraNameList} closePopup={closePopup} selected_camera={isEditPopup ? selectedCamera: undefined} cameraData={cameraData} />
              </Popup>
            </ActionButtonContainer>
          </Row>
            {cameraData.sorted_list && Object.keys(cameraData.sorted_list).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse', whiteSpace: 'normal' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{'width': '50px'}}></Th>
                  <Th style={{'text-align': 'left', 'width':window.innerWidth >= 1920 ? '15%' : '20%'}}>Nickname <br /> (Rule ID)</Th>
                  <Th>Description</Th>
                  <Th>Rule</Th>
                  <Th>Type</Th>
                  <Th>Created</Th>
                  <Th>Enabled</Th>
                </tr>
                { Object.keys(cameraData.sorted_list).map((index) => {
                  let cData = cameraData.sorted_list[index];
                  let name = cData.webredirect_id;
                  let url = cData.target_url;
                  let formattedDate = cData.created_time.split(' ');
                  var newDate = new Date(formattedDate*1000);
                  let calculatedDate = getDateFormate(newDate,CurTimezone);
                  if(calculatedDate === undefined){
                    return ;
                  }
                  let date = format(new Date(calculatedDate), 'yyyy/MM/dd');
                  let time = format(new Date(calculatedDate), 'HH:mm:ss');
                  let description = cData.description;
                  let nickname = cData.nickname;
                  let type = Constants.WEB_REDIRECT_TYPE[cData.type];
                  let host = '';
                  if(cData.type === 'direct') {
                    host = window.location.protocol + '//' + deviceID +  ":" + Constants.EDGE_UI_PORT + '/proxy/' + name + '/';
                  } else if(cData.type === 'hikvision') {
                    host = window.location.protocol + '//' + deviceID + ":" + Constants.EDGE_UI_PORT + '/proxy/'+ name + '/';
                  }else if( cData.type === 'axis') {
                    host = window.location.protocol + '//' + deviceID + ":" + Constants.EDGE_UI_PORT + '/proxy/' + name + '/';
                  } else if( cData.type === 'scorer_edge') {
                    host = window.location.protocol + '//' + deviceID + ":" + Constants.EDGE_UI_PORT + '/proxy/' + name + '/stream-viewer';
                  }
                
                  return (
                    <tr key={index}>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={index==='0'} name="select_radio" value={name} onClick={() => radioButtonOnChange(name)}></input></Td>
                      <Td>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div style={nickNameStyle} title={nickname}>{nickname}</div>
                            <div style={instNameStyle} title={name}>({name + ')'}</div>
                        </div>
                      </Td>
                      <Td title={description} style={descriptionStyle}>{description ? description : '-'}</Td>
                      <Td style={{'width': cData.enabled ? '380px' : 'auto'}}>
                        {cData.enabled ? 
                        <>
                        <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                          <div style={{'marginRight': '5px'}}>
                          <Link1 style={urlStyle} id={name + '_link'} title={host} target="_blank" href={host}>{host}</Link1>
                          </div>
                          <MdnsIpButton style={{'marginLeft': '5px'}} primary id={name + '_mdns_button'} className={'small-button'} onClick={() => handleHostChange(name, cData.type)}>mDNS</MdnsIpButton>
                        </div>
                        {' --> '} {url}  <br/>
                        </> : <>
                        {url} {' --> '} <br/>
                        <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                          <div style={{'marginRight': '5px'}}>
                          <div style={urlStyle} id={name + '_link'} title={host}>{host}</div>
                          </div>
                          <MdnsIpButton style={{'marginLeft': '5px'}} primary id={name + '_mdns_button'} className={'small-button'} onClick={() => handleHostChange(name, cData.type)}>mDNS</MdnsIpButton>
                        </div>
                        </> }
                      </Td>
                      <Td>{type}</Td>
                      <Td style={{'whiteSpace': 'nowrap'}}>{date} <br /> {time} {standaredTZ} </Td>
                      <Td><SelectionControl type="switch" labelBefore="true" id={name} name={name} value={cData.enabled} checked={cData.enabled} onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                    </tr>
                  );
                })}
              </table>
            </TableLayout>}
              {cameraData && cameraData.sorted_list.length === 0 &&
            <CenterDiv>
              <Label>No web proxy rules found.</Label>
            </CenterDiv>}
      </Wrapper>}
    </ContentDiv>
  );
};

function getDefaultCameraData() {
  return Constants.WEB_REDIRECT;
}

const WebRedirectPopup = (props) => {
  const { isEditPopup, closePopup, selected_camera, cameraData, cameraNameList, isHikvisionList,isAxisList} = props;
  const [cameraSetting, setCameraSetting] = useState(selected_camera === undefined ? getDefaultCameraData() : selected_camera);

  useEffect(()=>{
    if(cameraSetting.webredirect_id === ''){
      if(cameraData.sorted_list.length !== undefined){
        setCameraSetting({...cameraSetting, webredirect_id:'rule'+(cameraData.sorted_list.length+1)});
      } else {
        setCameraSetting({...cameraSetting, webredirect_id:'rule1'});
      }
    }
  },[cameraData.sorted_list.length]);

  const handleOnChange = (event) => {
    let data = {...cameraSetting};
    if(event.target.name === 'webredirect_id'){
      if (event.target.value.charAt(0) === '_') {
        event.target.value = event.target.value.replace('_','');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    } else if(event.target.name === 'ws_port') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    } else if(event.target.name === 'type' ) {
      data.ws_port = '';
    }
    data[event.target.name] = event.target.value;
    setCameraSetting(data);
  };

  const saveCameraIp = (event) => {
    let jsonData = Object.assign({}, cameraSetting);
    let webridirectId = jsonData.webredirect_id;
    let targetUrl = jsonData.target_url;
    let type = jsonData.type;
    let action = isEditPopup ? 'updated' : 'added';

    if (isEmpty(webridirectId)) {
      swal('Please enter rule id.', { icon: 'error' });
      return;
    }
    if (isEmpty(targetUrl)) {
      swal('Please enter target url.', { icon: 'error' });
      return;
    }
    if(cameraNameList.includes(webridirectId) && action === 'added') {
      swal('Rule id already exist. Please try with another id.', { icon: 'error' });
      return;
    }
    if(cameraNameList.length >= 10 && action === 'added') {
      swal('Maximum 10 numbers of web proxy rules can be added', { icon: 'error' });
      return;
    }
    if(type === 'hikvision' && jsonData.ws_port === '') {
      swal('Please enter port.', { icon: 'error' });
      return;
    }
    var targetUrlFormat = /^(http|https?):?\/\/./;
      if (!targetUrlFormat.test(targetUrl)) {
          swal('Target url is invalid', { icon: 'error' });
          return;
      }
    
    jsonData.source = 'edge_ui';
    jsonData.enabled = true;
    if(type !== 'hikvision') {
      delete jsonData.ws_port;
    }
    if (action === 'added') {
      if(isHikvisionList && type === 'hikvision') {
        swal('Web proxy rule for Hikvision is already present, only one rule can be added for Hikvision', { icon: 'error' });
        return;
      }else if(isAxisList && type === 'axis'){
        swal('Web proxy rule for Axis is already present, only one rule can be added for Axis', { icon: 'error' });
        return;
      }
      axios.put(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + webridirectId, jsonData)
        .then(res => {
          swal('Web proxy rule ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + webridirectId, jsonData)
        .then(res => {
          swal('Web proxy rule ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteCameraIp = (event) => {
    let WebRedirectName = cameraSetting.webredirect_id;
    swal({
      text: 'Are you sure you want to delete this web proxy rule?',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + WebRedirectName)
            .then(res => {
              swal({text:'Web proxy rule delete request submitted successfully.', icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });
  };

  return (
    <div>
      {cameraSetting !== undefined &&
        <PopupContainer>
          <SectionHeaderProxy style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Web Proxy Rule</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteCameraIP" name="btnDeleteCameraIP" style={{ 'margin': '0px' }} onClick={deleteCameraIp} >Delete</Button>
              </div>
            }
          </SectionHeaderProxy>
          <Container>
            <InputFieldContainer>
              <MandatoryLabel>Rule ID</MandatoryLabel>
              <TextFieldSmall id="webredirect_id" autoComplete="off" name="webredirect_id" value={cameraSetting.webredirect_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="63" style={{'flex-grow': '1'}} />
            </InputFieldContainer>
            <InputFieldContainer>
              <TextFieldLabel>Nickname</TextFieldLabel>
              <TextFieldSmall id="nickname" autoComplete="off" name="nickname" value={cameraSetting.nickname} onChange={handleOnChange} maxLength="100" style={{'flex-grow': '1'}} />
            </InputFieldContainer>
            <InputFieldContainer>
              <MandatoryLabel>Target URL</MandatoryLabel>
              <TextFieldSmall id="target_url" autoComplete="off" name="target_url" placeholder='http://192.168.10.32' value={cameraSetting.target_url} onChange={handleOnChange} maxLength="100" style={{'flex-grow': '1'}} />
            </InputFieldContainer>
            <InputFieldContainer>
              <TextFieldLabel>Type</TextFieldLabel>
              <SelectField id="type" name="type" style={{ 'margin-top': '10px', 'flex-grow': '1' }} disabled={isEditPopup} value={cameraSetting.type} onChange={handleOnChange}>
              <option value='hikvision'>Hikvision</option>
              <option value='axis'>Axis</option>
              <option value='scorer_edge'>SCORER Edge</option>
              <option value='direct'>Direct</option>
              </SelectField>
            </InputFieldContainer>
            {cameraSetting.type === 'hikvision' &&
            <InputFieldContainer>
              <MandatoryLabel>Websocket Port</MandatoryLabel>
              <TextFieldSmall id="ws_port" autoComplete="off" name="ws_port" placeholder='7681' value={cameraSetting.ws_port} onChange={handleOnChange} maxLength="5" style={{'flex-grow': '1'}} />
            </InputFieldContainer>}
            {cameraSetting.type === 'hikvision' &&
            <InputFieldContainer>
                <TextFieldLabel></TextFieldLabel> 
                <TextFieldLabel style={{ 'color': 'grey', 'display': 'flex-wrap', 'width': '205px', 'marginTop': '10px'}}>Please check NAT settings of camera configuration.</TextFieldLabel>
            </InputFieldContainer>}
            <InputFieldContainer style={{'align-items': 'center'}}>
              <TextFieldLabel>Description</TextFieldLabel>
              <div style={{'marginTop':'20px', 'flex-grow': '1'}}>
                <TextArea id="description" style={{'height': '200px'}} autoComplete="off" name="description" maxLength="255" value={cameraSetting.description} onChange={handleOnChange} />    
              </div>
            </InputFieldContainer>
          </Container>
          <Row>
            <PopupButtons>
              <Button onClick={saveCameraIp} primary >Save</Button>
              <Button id="btnCancel" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};
export default WebRedirect;
