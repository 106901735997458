import React, { useState, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossSVG } from '../images/cross.svg';
import { tagStandardColors } from '../../src/utils/Constants';

const CrossIcon = styled(CrossSVG)`
    object-fit: contain;
    cursor: pointer;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  ${CrossIcon} {
    width: 15px;
    height: 15px;
    > path {
      fill: #6d6d6d;
    }
  }
`;

const TagInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  max-width: 100%;
  padding: 0 8px;
  border: 1px solid hsla(120,1.3%,85.3%,1.000);
  color: hsla(207,5%,56.7%,1.000);
  border-radius: 3px;
  background: ${props => props.theme === true ? '#f2f2f2' : ''};
  &:focus {
    outline: none;
    border-color: 1px solid hsla(120,1.3%,85.3%,1.000);
  }
`;

const TagList = styled.ul`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.theme === true ? '0' : '0 0 5px 0'};
  margin: ${props => props.theme === true ? '0' : '8px 0 0 0'};
  ::-webkit-scrollbar {
    display: none;
  }
  line-height: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 5px;
  scroll-behavior: smooth;
`;

const TagListName = styled.li`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 4px;
  color: rgb(96, 96, 96);
  padding: 0 8px;
  font-size: 12px;
  list-style: none;
  border-radius: 8px;
  background: ${({bgColor}) => bgColor};
  box-shadow: inset 0 0 0 1px #A8B5BD;
`;
const TagListTitle = styled.span`
  padding-right: 2px;
  padding-left: 2px;
  font-weight: 500;
  color: ${({brightness}) => (brightness < 130) ? '#FFFFFF' : '#000000'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width:100px;
`;

const CustomColorPicker = styled.input`
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const ColorPickerLabel = styled.div`
  position: relative;
  display: block;
  width: max-content;
  height: max-content;
  margin: 0 5px 2px 0;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
`;

const TagListClose = styled.span`
  cursor: pointer;
  pointer-events: ${props => props.theme === true ? 'none' : 'auto'};
  > svg > path {
    fill: ${({brightness}) => (brightness < 130) ? '#FFFFFF' : '#000000'};
  }
`;

const Container = styled.div`
  max-width:100%;
  position: relative;
  height: 100%;
  outline: none;
  margin-top: 20px;
`;

const InputContainer = styled.div`
  width: min-content;
  min-width: 50px;
  flex-grow: 1;
  & > input {
    width: 100%;
  }
  input {
    flex: 1;
    border: none;
    font-size: 14px;
    border-radius: 3px;
    padding-top: ${props => props.theme === true ? '10px' : '0'};
    &:focus {
      outline: transparent;
    }
    ::placeholder {
      color: hsla(207,5%,56.7%,1.000);
      opacity: 0.65;
      font-size: 12px;
      font-style: italic;
    }
  }
`;

const ColorPallete = styled.label`
  width: 40px;
  height: 24px;
  border-radius: 2.5px;
  background-color: ${({bgColor}) => bgColor};
  outline: ${({isSelected}) => isSelected ? '2px solid #0089ff' : 'none'};
  outline-offset: ${({isSelected}) => isSelected ? '3px' : 'none'};
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: 450px) {
    width: 30px;
    height: 18px;
  }
  @media screen and (max-width: 380px) {
    width: 27px;
    height: 17px;
  }
`;

const ColorPicker = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  background-color: white;
  box-shadow: 2px 2px 5px #ABD;
  border: 1px solid #ABD;
  border-radius: 5px;
  position: absolute;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  cursor: default;
`;

const ColorTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 0;
`;

const ColorContainer = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: auto auto auto auto auto auto;
  @media screen and (max-width: 450px) {
    gap: 10px;
  }
`;

const ColorTypeHeader = styled.div`
  color: #413f3f;
  font-size: 13px;
  font-weight: 500;
`;

const TagsInput = (props) => {

  const { newTags, tags, disabled, enteredTag, onTagEnter } = props;
  const [tagName, setTagName] = useState(tags);
  const [expanded, setExpanded] = useState(false);
  const [openedColorPicker, setOpenedColorPicker] = useState(-1);
  const colorPickerRef = useRef(null);

  const removeTags = useCallback((indexToRemove) => {
    setTagName(prev => {
      const filteredTags = [...prev.filter((_, index) => index !== indexToRemove)];
      newTags(filteredTags);
      return filteredTags;      
    });
    if (openedColorPicker !== -1) {
      setOpenedColorPicker(-1);
    }
  }, [openedColorPicker]);

  const addTags = (event) => {
    event.persist();
    const tagValue = event.target.value.trim();
    if (event.key === 'Enter' && tagValue !== '') {
      const tagDetails = {
        name: event.target.value,
        color: '#c4d3dc'
      }
      setTagName(prev => {
        if (prev.findIndex(tag => (tag.name === tagDetails.name)) !== -1) {
          return prev;
        } else {
          newTags([...tagName, {...tagDetails}]);
          return [...prev, {...tagDetails}];
        }
      });
      setTimeout(() => {
        event.target.value = '';
        onTagEnter('');
      }, 10);
    }
  };
  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onBlur = useCallback((e) => {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        console.log('component officially blurred');
        setExpanded(false);
      }
    }, 0);
  }, []);

  const getBrightness = (hex) => {
    const r = parseInt(hex.substr(1,2), 16);
    const g = parseInt(hex.substr(3,2), 16);
    const b = parseInt(hex.substr(5,2), 16);
    const brightness = Math.round(Math.sqrt(((r * r * 0.241) + (g * g * 0.691) + (b * b * 0.068))));
    return brightness;
  };

  const onColorPick = useCallback((color, id) => {
    setTagName(prev => {
      const temp = prev;
      temp[id].color = color;
      return temp;
    });
    setTagName(prev => {
      const temp = prev;
      temp[id].color = color;
      newTags(temp);
      return temp;
    });
  }, [newTags]);

  const openColorPicker = useCallback((index) => {
    setOpenedColorPicker(index);
  }, []);
  
  const closeColorPicker = useCallback((e) => {
    e.stopPropagation();
    if (openedColorPicker !== -1) {
      setOpenedColorPicker(-1);
    }
  }, [openedColorPicker]);
  
  const onOutsideClick = useCallback((e) => {
    if (openedColorPicker !== -1 && !(colorPickerRef.current.contains(e.target))) {
      setOpenedColorPicker(-1);
    }
  }, [openedColorPicker]);

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);
    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [onOutsideClick]);

  // to position color-picker with respect to config container
  useLayoutEffect(() => {
    const container = document.getElementById('configContainer');
    if (container) {
      const { left: cLeft, right: cRight } = container.getBoundingClientRect(); // this will get the config container position
      const colorPicker = document.getElementById('colorPicker' + openedColorPicker);
      if (colorPicker) {
        const { left, right} = colorPicker.getBoundingClientRect(); // we get the edit color picker position
        if (left <= cLeft) {
          colorPicker.style.left = 'calc(50% + ' + (cLeft - left) + 5 + 'px)';
        } else if (right >= cRight) {
          colorPicker.style.left = 'calc(50% - ' + (right - cRight) + 5 + 'px)';
        }
      }
    }
  }, [openedColorPicker]);

  return (
    <Container onBlur={onBlur}>
      <TagInputWrapper theme={disabled}>
        <TagList theme={tagName.length === 0} ref={colorPickerRef}>
          {tagName.map((tag, index) => (
            <ColorPickerLabel onClick={() => {openColorPicker(index)}} bgColor={(tag && tag.color !== undefined) ? tag.color.toUpperCase() : '#c4d3dc'} >
              <TagListName key={index} bgColor={(tag && tag.color !== undefined) ? tag.color.toUpperCase() : '#c4d3dc'}>
                {(openedColorPicker === index) ?
                  <ColorPicker id={'colorPicker' + index}>
                    <CloseIcon title='Close' onClick={closeColorPicker}><CrossIcon /></CloseIcon>
                    <ColorTypeContainer>
                      <ColorTypeHeader>Custom</ColorTypeHeader>
                      <ColorPallete
                        isSelected={(tag && tag.color !== undefined) && !tagStandardColors.includes(tag.color)}
                        htmlFor={'customColorPicker' + index} bgColor={(tag && tag.color !== undefined) ? tag.color.toUpperCase() : '#c4d3dc'}
                      >
                        <CustomColorPicker type='color' id={'customColorPicker' + index} value={(tag && tag.color !== undefined) ? tag.color.toUpperCase() : '#c4d3dc'} onChange={(e) => {onColorPick(e.target.value, index);}} />
                      </ColorPallete>
                    </ColorTypeContainer>
                    <ColorTypeContainer>
                      <ColorTypeHeader>Standard</ColorTypeHeader>
                      <ColorContainer>
                        {tagStandardColors.map(color => {
                          return (<ColorPallete bgColor={color} isSelected={color.toUpperCase() === tag.color.toUpperCase()} onClick={() => {onColorPick(color, index);}} />)
                        })}
                      </ColorContainer>
                    </ColorTypeContainer>
                  </ColorPicker>
                : null}
                <TagListTitle title={tag.name} brightness={(tag && tag.color !== undefined) ? getBrightness(tag.color.toUpperCase()) : getBrightness('#c4d3dc')}>{tag.name}</TagListTitle>
                <TagListClose theme={disabled} brightness={(tag && tag.color !== undefined) ? getBrightness(tag.color.toUpperCase()) : getBrightness('#c4d3dc')} onClick={(e) => {e.stopPropagation(); removeTags(index);}}><CrossIcon /></TagListClose>
              </TagListName>
            </ColorPickerLabel>
          ))}
          <InputContainer theme={tagName.length === 0} onClick={closeColorPicker}>
            <input
              type='text'
              onKeyUp={addTags}
              onClick={toggle}
              maxLength={64}
              tabIndex={0}
              disabled={disabled}
              value={enteredTag}
              onChange={e => onTagEnter(e.target.value)}
              style={{'display': disabled ? 'none' : '', 'pointerEvents': disabled ? 'none' : 'auto'}}
              placeholder={disabled ? '' :'+Tags'}
            />
          </InputContainer>
        </TagList>
      </TagInputWrapper>
    </Container>
  );
};

export default TagsInput;