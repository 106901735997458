import React, { useState, useEffect } from 'react'
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, LeftDiv, Spacer, TextField, TextArea, SelectField, 
Row, Column, RightDiv, SectionHeader, Button, ButtonWrapper, SectionLabel } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css"
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import styled from 'styled-components';


const PowerContainer = styled(Wrapper)` 
    @media screen and (max-width: 750px) {
        font-size: 0.95rem;
    }
    @media screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
    @media screen and (max-width: 550px) {
        font-size: 0.75rem;
    }
    @media screen and (max-width: 450px) {
        font-size: 0.68rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.65rem;
    }
`;

const StyledColumn = styled(Column)`
    width: unset;
    display: flex;
    align-items: baseline;
    & > div {
        width: max-content;
    }
    @media screen and (max-width: 375px) {
        gap: 10px 0;
        & > ${Button} {
            margin-left: 0;
        }
    }
`;

const ButtonSmall = styled.button`
  /* Adapt the colors based on primary prop */
  background:rgb(242, 20, 10);
  color: #ffffff;
  font-size: 1em;
  margin: 1em 0em 1em 1em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 3px;
  opacity: 0.7;
  @media screen and (max-width: 400px) {
    margin: 16px 0 16px 3.2px;
    }
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

const SubmitButtonReboot = styled(Button)`
@media screen and (max-width: 400px) {
    margin: 16px 0 16px 2px;
    }
`;

const Label1 = styled.label`
  display:inline-block;
  margin-bottom:5px;
  @media screen and (max-width: 400px) {
    margin: 0px 21px 0px 0px !important;
}
`;

const ParentDiv = styled.div`
    @media screen and (max-width: 400px) {
        margin-left : 15px;
    }
`;

const Power = (props) => {
    const [inputsReboot, setInputsReboot] = useState(Constants.DEVICE_CONTROL_DEFAULT_VALUES);
    const [inputsShutdown, setInputsShutdown] = useState(Constants.DEVICE_CONTROL_DEFAULT_VALUES);

    // const source = props.match.path.includes("reboot") ? "Reboot":"Shutdown";

    const handleNumberOnChangeReboot = (event) => {
        event.persist();
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        setInputsReboot(inputsReboot => ({...inputsReboot, [event.target.name]: event.target.value}));
    }

    const handleNumberOnChangeShutdown = (event) => {
        event.persist();
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        setInputsShutdown(inputsShutdown => ({...inputsShutdown, [event.target.name]: event.target.value}));
    }

    function prepareRequestJson (jsonData, source) {
        if (jsonData["delay"] == "" || jsonData["delay"] == undefined) {
            swal("Please enter delay in seconds.")
            return;
        }

        jsonData ["action"] = source
        jsonData ["delay"] = jsonData["delay"]
        jsonData ["source"] = "Edge UI"
        
        return jsonData
    }


    function sendDeviceControlRequest (jsonData, source) {
        axios.post(Constants.EDGE_API_ENDPOINT + '/devices/power', jsonData)
        .then(res => {
            swal("Device " + source.toLowerCase() + " request submitted successfully.", { icon: "success" });
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });

    }

    const handleSubmit = (event, source) => {
        if(event)
            event.preventDefault();
        var jsonData = source === 'Reboot' ? inputsReboot : inputsShutdown;
        var finalJson = Object.assign({}, jsonData);
        finalJson = prepareRequestJson(finalJson, source)
        if (finalJson == undefined) {
            return;
        }

        swal({
                text:  `\n\n Are you sure you want to ` + source.toLowerCase() + " this device?",
                buttons: ['No', 'Yes'],
                dangerMode: true

            })
            .then((isConfirm) => {
                if (isConfirm) {
                    sendDeviceControlRequest(finalJson, source)
                }
            });
    }

    return (
            <ContentDiv>
                <PowerContainer>
                    <SectionHeader>
                        <SectionLabel>Reboot Device</SectionLabel>
                    </SectionHeader>
                    <Row>
                        <StyledColumn>
                          <ParentDiv>
                            <Label1 style={{'marginRight': '28px'}}>Reboot After</Label1>
                            <TextField name="delay" type="text" value={inputsReboot.delay} onChange={handleNumberOnChangeReboot} maxLength="5" style={{'width': '50px','textAlign':'center','marginLeft':'2px'}} />
                            <Label style={{'marginLeft': '10px', 'marginRight': '10px'}}>Seconds</Label>
                          </ParentDiv>
                          <SubmitButtonReboot primary onClick={(e) => handleSubmit(e, "Reboot")}>Submit</SubmitButtonReboot>
                        </StyledColumn>
                    </Row>

                </PowerContainer>
                        <Row >
                            <Spacer />
                        </Row>
                <PowerContainer>
                    <SectionHeader>
                        <SectionLabel style={{'color':'rgb(242 20 10)'}}>Shutdown Device</SectionLabel>
                    </SectionHeader>
                    <Row>
                        <StyledColumn>
                        <ParentDiv>
                            <Label style={{'marginRight': '10px'}}>Shutdown After</Label>
                            <TextField name="delay" type="text" value={inputsShutdown.delay} onChange={handleNumberOnChangeShutdown} maxLength="5" style={{'width': '50px','textAlign':'center'}} />
                            <Label style={{'marginLeft': '10px', 'marginRight': '10px'}}>Seconds</Label>
                        </ParentDiv>
                          <ButtonSmall primary onClick={(e) => handleSubmit(e, "Shutdown")}>Submit</ButtonSmall>
                        </StyledColumn>
                    </Row>
                </PowerContainer>
            </ContentDiv>
            )
}

export default Power;