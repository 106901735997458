export const TIMEZONE = [
  {'value':'Pacific/Niue'                 ,       'name': '(GMT -11:00) Niue'}, // Niue Time
  {'value':'Pacific/Midway'               ,       'name': '(GMT -11:00) Midway, Pago_Pago'}, // Samoa Standard Time

  {'value':'America/Adak'                 ,       'name': '(GMT -10:00) Adak, Honolulu'}, // Hawaii-Aleutian Daylight Time
  {'value':'Pacific/Chuuk'                ,       'name': '(GMT -10:00) Chuuk'}, // Chuuk Time
  {'value':'Pacific/Rarotonga'            ,       'name': '(GMT -10:00) Rarotonga'}, // Cook Islands Standard Time
  {'value':'Pacific/Tahiti'               ,       'name': '(GMT -10:00) Tahiti'}, // Tahiti Time

  {'value':'Pacific/Marquesas'            ,       'name': '(GMT -09:30) Marquesas'}, // Marquesas Time
  {'value':'America/Nome'                 ,       'name': '(GMT -09:00) Nome, Anchorage, Juneau, Metlakatla, Sitka'}, // Alaska Daylight Time
  {'value':'Pacific/Gambier'              ,       'name': '(GMT -09:00) Gambier'},

  {'value':'Pacific/Pitcairn'             ,       'name': '(GMT -08:00) Pitcairn '}, // Pitcairn Time
  {'value':'America/Los_Angeles'          ,       'name': '(GMT -08:00) Los_Angeles, Tijana, Vancover'}, // Pacific Daylight Time

  {'value':'America/Denver'               ,       'name': '(GMT -07:00) Denver, Boise, Creston, Cambridge_Bay, Yellowkife'},  // Mountain Daylight Time
  {'value':'America/Dawson'               ,       'name': '(GMT -07:00) Dawson'},
  {'value':'America/Hermosillo'           ,       'name': '(GMT -07:00) Hermosillo, Mazatlan'},  // Mexican Pacific Standard Time
  {'value':'America/Phoenix'              ,       'name': '(GMT -07:00) Phoenix'},  // Mountain Standard Time
  {'value':'America/Whitehorse'           ,       'name': '(GMT -07:00) Whitehorse'},  // Yukon Time
  {'value':'America/Belize'               ,       'name': '(GMT -06:00) Belize, Swift_Curent, Mexico_City, Merida, Rankin_Ilet'}, // Central Standard Time
  {'value':'America/Chihuahua'            ,       'name': '(GMT -06:00) Chihuahua'}, // GMT-06:00
  {'value':'America/North_Dakota/Center'  ,       'name': '(GMT -06:00) Center'}, // Central European Time
  {'value':'America/Chicago'              ,       'name': '(GMT -06:00) Chicago, Matamoros, Menominee, Resoute, Winnipeg'}, // Central Daylight Time
  {'value':'America/Indiana/Knox'         ,       'name': '(GMT -06:00) Knox'}, // Australian Eastern Daylight Time
  {'value':'America/Regina'               ,       'name': '(GMT -06:00) Regina'}, // Central European Time
  {'value':'Pacific/Easter'               ,       'name': '(GMT -06:00) Easter'}, // Eastern Time
  {'value':'Pacific/Galapagos'            ,       'name': '(GMT -06:00) Galapagos'}, // 

  {'value':'America/New_York'             ,       'name': '(GMT -05:00) New_York, Indianapolis, Detroit, Tornto, ThunderBay'}, // Eastern Daylight Time
  {'value':'America/Havana'               ,       'name': '(GMT -05:00) Havana'}, // Cuba Daylight Time
  {'value':'America/North_Dakota/New_Salem',      'name': '(GMT -05:00) New_Salem'}, // Central Daylight Time
  {'value':'America/Cancun'               ,       'name': '(GMT -05:00) Cancun, Cayman, Jamaica, Panama'}, // Eastern Standard Time
  {'value':'America/Bogota'               ,       'name': '(GMT -05:00) Bogota'}, // Colombia Standard Time
  {'value':'America/Eirunepe'             ,       'name': '(GMT -05:00) Eirunepe'}, // Acre Standard Time
  {'value':'America/Guayaquil'            ,       'name': '(GMT -05:00) Guayaquil'}, // Ecuador Time
  {'value':'America/Indiana/Petersburg'   ,       'name': '(GMT -05:00) Petersburg'}, // Moscow Standard Time
  {'value':'America/Indiana/Tell_City'    ,       'name': '(GMT -05:00) Tell_City'}, // Central Daylight Time
  {'value':'America/Lima'                 ,       'name': '(GMT -05:00) Lima'}, // Peru Standard Time
  {'value':'America/Rio_Branco'           ,       'name': '(GMT -05:00) Rio_Branco'},
  {'value':'Europe/Atlantic/'             ,       'name': '(GMT -04:00) Atlantic'},
  {'value':'America/Araguaina'            ,       'name': '(GMT -04:00) Araguaina'}, // Argentina Standard Time
  {'value':'Atlantic/Azores'              ,       'name': '(GMT -04:00) Azores'}, // Azores Standard Time
  {'value':'America/Antigua'              ,       'name': '(GMT -04:00) Antigua, Aruba, Grenada, Lower_Princes, Martinique'}, // Atlantic Standard Time
  {'value':'America/Asuncion'             ,       'name': '(GMT -04:00) Asuncion'}, // Paraguay Summer Time
  {'value':'America/Atikokan'             ,       'name': '(GMT -04:00) Atikokan'},  // Eastern Standard Time
  {'value':'America/Boa_Vista'            ,       'name': '(GMT -04:00) Boa_Vista, Campo_Grande, Cuiaba, Manaus'}, // Amazon Standard Time
  {'value':'America/Caracas'              ,       'name': '(GMT -04:00) Caracas'}, // Venezuela Time
  {'value':'America/Guyana'               ,       'name': '(GMT -04:00) Guyana'}, // Guyana Time
  {'value':'America/Glace_Bay'            ,       'name': '(GMT -04:00) Glace_Bay, Goose_Bay, Halifax, Moncton'}, // Atlantic Daylight Time
  {'value':'America/Kralendijk'           ,       'name': '(GMT -04:00) Kralendijk'},
  {'value':'America/St_Kitts'             ,       'name': '(GMT -04:00) St_Kitts, Vevay, Vincennes, Winamac'}, // Eastern Daylight Time
  {'value':'America/La_Paz'               ,       'name': '(GMT -04:00) La_Paz'}, // Bolivia Time
  {'value':'America/Marigot'              ,       'name': '(GMT -04:00) Marigot'},
  {'value':'America/Porto_Velho'          ,       'name': '(GMT -04:00) Porto_Velho'},
  {'value':'America/Santiago'             ,       'name': '(GMT -04:00) Santiago'}, // Chile Summer Time
  {'value':'America/St_Barthelemy'        ,       'name': '(GMT -04:00) St_Barthelemy'},
  {'value':'America/St_Thomas'            ,       'name': '(GMT -04:00) St_Thomas'},
  {'value':'America/Thule'                ,       'name': '(GMT -04:00) Thule'},
  {'value':'America/Anguilla'             ,       'name': '(GMT -03:00) Anguilla'}, // Atlantic Standard Time
  {'value':'America/Argentina/Buenos_Aires',       'name': '(GMT -03:00) Buenos_Aires, Cordoba, Jujuy, La_Rioja, Mendoza'}, // Argentina Standard Time
  {'value':'America/Argentina/San_Juan'   ,       'name': '(GMT -03:00) San_Juan'}, // Atlantic Standard Time
  {'value':'America/Argentina/San_Luis'   ,       'name': '(GMT -03:00) San_Luis'}, // Central Standard Time
  {'value':'America/Argentina/Tucuman'    ,       'name': '(GMT -03:00) Tucuman'}, // Argentina Standard Time
  {'value':'America/Belem'                ,       'name': '(GMT -03:00) Belem, Fortaleza, Maceio, Santrem, Sao_Pulo'}, // Brasilia Standard Time
  {'value':'America/Cayenne'              ,       'name': '(GMT -03:00) Cayenne'}, // French Guiana Time
  {'value':'America/Miquelon'             ,       'name': '(GMT -03:00) Miquelon'}, // St Pierre & Miquelon Daylight Time
  {'value':'America/Montevideo'           ,       'name': '(GMT -03:00) Montevideo'}, // Uruguay Standard Time
  {'value':'America/Nuuk'                 ,       'name': '(GMT -03:00) Nuuk'}, // Greenwich Mean Time
  {'value':'America/Paramaribo'           ,       'name': '(GMT -03:00) Paramaribo'}, // Suriname Time
  {'value':'America/St_Johns'             ,       'name': '(GMT -03:00) St_Johns'}, 
  {'value':'Antarctica/Palmer'            ,       'name': '(GMT -03:00) Palmer'},
  {'value':'Antarctica/Rothera'           ,       'name': '(GMT -03:00) Rothera'},
  {'value':'Atlantic/Stanley'             ,       'name': '(GMT -03:00) Stanley'}, // Falkland Islands Standard Time

  {'value':'America/Noronha'              ,       'name': '(GMT -02:00) Noronha'},
  {'value':'Atlantic/South_Georgia'       ,       'name': '(GMT -02:00) South_Georgia'},

  {'value':'Atlantic/Cape_Verde'          ,       'name': '(GMT -01:00) Cape_Verde'},
  {'value':'America/Scoresbysund'         ,       'name': '(GMT -01:00) Scoresbysund'}, //East Greenland Standard Time
  {'value':'Atlantic/Bermuda'             ,       'name': '(GMT -01:00) Bermuda'}, // Atlantic Daylight Time

  {'value':'Africa/Abidjan'               ,       'name': '(GMT +00:00) Abidjan, Bamako, Bissau, Dakar, Danmarkshavn'}, // Greenwich Mean Time
  {'value':'Atlantic/Canary'              ,       'name': '(GMT +00:00) Canary'},
  {'value':'Atlantic/Faroe'               ,       'name': '(GMT +00:00) Faroe, Madeira, Lisbon'}, // Western European Standard Time
  {'value':'Europe/London'                ,       'name': '(GMT +00:00) London, Lome, Guernsey, Jersey, Reykjavik'}, // Greenwich Mean Time
  {'value':'Africa/Nouakchott'            ,       'name': '(GMT +00:00) Nouakchott'},
  {'value':'Africa/Ouagadougou'           ,       'name': '(GMT +00:00) Ouagadougou'},
  {'value':'Africa/Sao_Tome'              ,       'name': '(GMT +00:00) Sao_Tome'},
  {'value':'Atlantic/St_Helena'           ,       'name': '(GMT +00:00) St_Helena'},

  {'value':'Europe/Andorra'               ,       'name': '(GMT +01:00) Andorra, Brussels, Bratislava, Monaco, Madrid'}, //Central European Standard Time
  {'value':'Europe/Belgrade'              ,       'name': '(GMT +01:00) Belgrade'},
  {'value':'Europe/Berlin'                ,       'name': '(GMT +01:00) Berlin, Paris, Rome, Oslo, Tunis, Malta'}, //Central European Standard Time
  {'value':'Europe/Busingen'              ,       'name': '(GMT +01:00) Busingen'},
  {'value':'Africa/Brazzaville'           ,       'name': '(GMT +01:00) Brazzaville, Douala, Kinshasa, Luanda, Malabo'}, //West Africa Standard Time
  {'value':'Africa/Casablanca'            ,       'name': '(GMT +01:00) Casablanca, El_Aaiun'}, // GMT
  {'value':'Europe/Dublin'                ,       'name': '(GMT +01:00) Dublin'}, // Greenwich Mean Time
  {'value':'Europe/Prague'                ,       'name': '(GMT +01:00) Prague'}, 
  {'value':'Arctic/Longyearbyen'          ,       'name': '(GMT +01:00) Longyearyen'},

  {'value':'Europe/Athens'                ,       'name': '(GMT +02:00) Athens, Berut, Helsinki, Kiev, Tripoli, Kaliningrad'},  //Eastern European Standard Time
  {'value':'Asia/Gaza'                    ,       'name': '(GMT +02:00) Gaza, Heron'}, // Mountain Standard Time
  {'value':'Asia/Famagusta'               ,       'name': '(GMT +02:00) Famagusta'},
  {'value':'Asia/Jerusalem'               ,       'name': '(GMT +02:00) Jerusalem'}, //Israel Standard Time 
  {'value':'Africa/Juba'                  ,       'name': '(GMT +02:00) Juba, Lusaka, Maputo, Windhoek, Kigali'}, // Central Africa Time
  {'value':'Africa/Johannesburg'          ,       'name': '(GMT +02:00) Johannesburg, Mbabane'} , //South Africa Standard Time
  {'value':'Asia/Nicosia'                 ,       'name': '(GMT +02:00) Nicosia'},
  {'value':'Europe/Riga'                  ,       'name': '(GMT +02:00) Riga, Sofia, Tallinn, Uzhgorod, Vilnius'}, //Eastern European Standard Time
  {'value':'Europe/Zaporozhye'            ,       'name': '(GMT +02:00) Zaporozhye'},

  {'value':'Africa/Algiers'               ,       'name': '(GMT +03:00) Algiers'}, // Central European Standard Time
  {'value':'Asia/Amman'                   ,       'name': '(GMT +03:00) Amman'},
  {'value':'Asia/Baghdad'                 ,       'name': '(GMT +03:00) Baghdad, Bahain, Kuwait, Qatar, Riyadh'}, //Arabian Standard Time
  {'value':'Asia/Damascus'                ,       'name': '(GMT +03:00) Damascus'},
  {'value':'Asia/Aden'                    ,       'name': '(GMT +03:00) Aden'}, // Mountain Daylight Time
  {'value':'Europe/Istanbul'              ,       'name': '(GMT +03:00) Istanbul'},
  {'value':'Europe/Moscow'                ,       'name': '(GMT +03:00) Moscow, Kirov, Minsk, Simferopol'}, //Moscow Standard Time
  {'value':'Africa/Nairobi'               ,       'name': '(GMT +03:00) Nairobi, Antananarivo, Comoro, Djibouti, Kampala'}, // East Africa Time
  {'value':'Europe/Volgograd'             ,       'name': '(GMT +03:00) Volgograd'},  //Volgograd Standard Time
  {'value':'Asia/Tehran'                  ,       'name': '(GMT +03:30) Tehran'}, // Iran Standard Time

  {'value':'Asia/Dubai'                   ,       'name': '(GMT +04:00) Dubai, Muscat'}, // Gulf Standard Time
  {'value':'Indian/Mahe'                  ,       'name': '(GMT +04:00) Mahe'}, // India Standard Time
  {'value':'Indian/Mauritius'             ,       'name': '(GMT +04:00) Mauritius'}, // Mauritius Standard Time
  {'value':'Indian/Reunion'               ,       'name': '(GMT +04:00) Reunion'}, // Réunion Time
  {'value':'Asia/Tbilisi'                 ,       'name': '(GMT +04:00) Tbilisi'}, // Georgia Standard Time-
  {'value':'Europe/Ulyanovsk'             ,       'name': '(GMT +04:00) Ulyanovsk'}, // Samara Standard Time
  {'value':'Asia/Yerevan'                 ,       'name': '(GMT +04:00) Yerevan'}, // Armenia Standard Time
  {'value':'Asia/Kabul'                   ,       'name': '(GMT +04:30) Kabul'}, // Afghanistan Time
  
  {'value':'Asia/Ashgabat'                ,       'name': '(GMT +05:00) Ashgabat'}, // Turkmenistan Standard Time
  {'value':'Asia/Aqtobe'                  ,       'name': '(GMT +05:00) Aqtobe, Atrau, Qyzylorda'}, // West Kazakhstan Time
  {'value':'Asia/Dushanbe'                ,       'name': '(GMT +05:00) Dushanbe'}, // Tajikistan Time
  {'value':'Asia/Karachi'                 ,       'name': '(GMT +05:00) Karachi'}, // Pakistan Standard Time
  {'value':'Indian/Kerguelen'             ,       'name': '(GMT +05:00) Kerguelen'},
  {'value':'Antarctica/Mawson'            ,       'name': '(GMT +05:00) Mawson'}, // Eastern Daylight Time
  {'value':'Indian/Maldives'              ,       'name': '(GMT +05:00) Maldives'}, // Maldives Time
  {'value':'Asia/Oral'                    ,       'name': '(GMT +05:00) Oral'},
  {'value':'Asia/Samarkand'               ,       'name': '(GMT +05:00) Samarkand, Tashkent'}, // Uzbekistan Standard Time
  {'value':'Asia/Yekaterinburg'           ,       'name': '(GMT +05:00) Yekaterinburg'}, // +5
  {'value':'Asia/Kolkata'                 ,       'name': '(GMT +05:30) Kolkata, Colombo'}, //India Standard Time
  {'value':'Asia/Kathmandu'               ,       'name': '(GMT +05:45) Kathmandu'}, // Nepal Time
  
  {'value':'Asia/Almaty'                  ,       'name': '(GMT +06:00) Almaty'}, // East Kazakhstan Time
  {'value':'Asia/Bishkek'                 ,       'name': '(GMT +06:00) Bishkek'}, //Kyrgyzstan Time
  {'value':'Indian/Chagos'                ,       'name': '(GMT +06:00) Chagos'},
  {'value':'Asia/Dhaka'                   ,       'name': '(GMT +06:00) Dhaka'},
  {'value':'Asia/Omsk'                    ,       'name': '(GMT +06:00) Omsk'}, // Omsk Standard Time
  {'value':'Asia/Qostanay'                ,       'name': '(GMT +06:00) Qostanay'},
  {'value':'Asia/Thimphu'                 ,       'name': '(GMT +06:00) Thimphu'}, //Bhutan Time
  {'value':'Asia/Urumqi'                  ,       'name': '(GMT +06:00) Urumqi'}, // China Standard Time
  {'value':'Antarctica/Vostok'            ,       'name': '(GMT +06:00) Vostok'},
  {'value':'Indian/Cocos'                 ,       'name': '(GMT +06:30) Cocos'}, // Cocos Islands Time
  {'value':'Asia/Yangon'                  ,       'name': '(GMT +06:30) Yangon'}, // Myanmar Time
  
  {'value':'Asia/Bangkok'                 ,       'name': '(GMT +07:00) Bangkok'}, // Bankok Time
  {'value':'Indian/Christmas'             ,       'name': '(GMT +07:00) Christmas'}, // Christmas Island Time
  {'value':'Antarctica/Davis'             ,       'name': '(GMT +07:00) Davis'}, // Pacific Daylight Time
  {'value':'Asia/Ho_Chi_Minh'             ,       'name': '(GMT +07:00) Ho_Chi_Minh, Phnom_Penh, Vientiane'}, // Indochina Time
  {'value':'Asia/Jakarta'                 ,       'name': '(GMT +07:00) Jakarta'}, // Western Indonesia Time
  {'value':'Asia/Krasnoyarsk'             ,       'name': '(GMT +07:00) Krasnoyarsk, Baraul'}, // Krasnoyarsk Standard Time
  {'value':'Asia/Novokuznetsk'            ,       'name': '(GMT +07:00) Novokuznetsk'},
  {'value':'Asia/Novosibirsk'             ,       'name': '(GMT +07:00) Novosibirsk'}, // Novosibirsk Standard Time
  {'value':'Asia/Pontianak'               ,       'name': '(GMT +07:00) Pontianak'}, // Western Indonesia Time
  {'value':'Asia/Hovd'                    ,       'name': '(GMT +07:00) Hovd'},
  {'value':'Asia/Tomsk'                   ,       'name': '(GMT +07:00) Tomsk'}, // Novosibirsk Standard Time
  
  {'value':'Asia/Brunei'                  ,       'name': '(GMT +08:00) Brunei'}, // Brunei Darussalam Time
  {'value':'Asia/Choibalsan'              ,       'name': '(GMT +08:00) Choibalsan'},
  {'value':'Asia/Hong_Kong'               ,       'name': '(GMT +08:00) Hong_kong'}, // Hong Kong Standard Time
  {'value':'Asia/Irkutsk'                 ,       'name': '(GMT +08:00) Irkutsk'}, // Irkutsk Standard Time
  {'value':'Asia/Kuching'                 ,       'name': '(GMT +08:00) Kuching, Kuala_Lumpur'}, //Malaysia Time
  {'value':'Australia/Perth'              ,       'name': '(GMT +08:00) Perth'},
  {'value':'Asia/Makassar'                ,       'name': '(GMT +08:00) Makassar'}, // Central Indonesia Time
  {'value':'Asia/Manila'                  ,       'name': '(GMT +08:00) Manila'}, // Philippine Standard Time
  {'value':'Asia/Shanghai'                ,       'name': '(GMT +08:00) Shanghai, Macau'}, // China Standard Time
  {'value':'Asia/Singapore'               ,       'name': '(GMT +08:00) Singapore'}, // Singapore Standard Time
  {'value':'Asia/Taipei'                  ,       'name': '(GMT +08:00) Taipei'}, // Taipei Standard Time
  {'value':'Asia/Ulaanbaatar'             ,       'name': '(GMT +08:00) Ulaanbaatar'}, // Ulaanbaatar Standard Time
  {'value':'Australia/Eucla'              ,       'name': '(GMT +08:45) Eucla'}, //Australian Central Western Standard Time
  
  {'value':'Asia/Chita'                   ,       'name': '(GMT +09:00) Chita, Yakutsk'}, // Yakutsk Standard Time
  {'value':'Asia/Dili'                    ,       'name': '(GMT +09:00) Dili'}, // East Timor Time
  {'value':'Asia/Jayapura'                ,       'name': '(GMT +09:00) Jayapura'}, // Eastern Indonesia Time
  {'value':'Asia/Khandyga'                ,       'name': '(GMT +09:00) Khandyga'},
  {'value':'Asia/Pyongyang'               ,       'name': '(GMT +09:00) Pyongyang'}, // Korean Standard Time
  {'value':'Pacific/Palau'                ,       'name': '(GMT +09:00) Palau'}, // Palau Time
  {'value':'Asia/Seoul'                   ,       'name': '(GMT +09:00) Seoul'},
  {'value':'Asia/Tokyo'                   ,       'name': '(GMT +09:00) Tokyo'}, // Japan Standard Time
  {'value':'Australia/Adelaide'           ,       'name': '(GMT +09:30) Adelaide, Broken_Hill, Darwin'}, // Australian Central Daylight Time
  
  {'value':'Australia/Brisbane'           ,       'name': '(GMT +10:00) Brisbane, Lindeman, Melbourne, Sydney'}, // Australian Eastern Standard Time
  {'value':'Antarctica/DumontDUrville'    ,       'name': '(GMT +10:00) DumontDUrville'}, // DDUT
  {'value':'Pacific/Guam'                 ,       'name': '(GMT +10:00) Guam'}, // Chamorro Standard Time
  {'value':'Antarctica/Macquarie'         ,       'name': '(GMT +10:00) Macquarie'},
  {'value':'Pacific/Port_Moresby'         ,       'name': '(GMT +10:00) Port_Moresby'}, // Papua New Guinea Time
  {'value':'Pacific/Saipan'               ,       'name': '(GMT +10:00) Saipan'},
  {'value':'Asia/Ust-Nera'                ,       'name': '(GMT +10:00) Ust-Nera'},
  {'value':'Asia/Vladivostok'             ,       'name': '(GMT +10:00) Vladivostok'}, // Vladivostok Standard Time
  {'value':'Australia/Lord_Howe'          ,       'name': '(GMT +10:30) Lord_Howe'},

  {'value':'Pacific/Bougainville'         ,       'name': '(GMT +11:00) Bougainville'}, // GMT+11:00
  {'value':'Antarctica/Casey'             ,       'name': '(GMT +11:00) Casey'}, // New Zealand Daylight Time
  {'value':'Pacific/Efate'                ,       'name': '(GMT +11:00) Efate'},
  {'value':'Pacific/Guadalcanal'          ,       'name': '(GMT +11:00) Guadalcanal'}, // Solomon Islands Time
  {'value':'Australia/Hobart'             ,       'name': '(GMT +11:00) Hobart'},  // Australian Eastern Daylight Time
  {'value':'Pacific/Kosrae'               ,       'name': '(GMT +11:00) Kosrae'}, // KOST
  {'value':'Asia/Magadan'                 ,       'name': '(GMT +11:00) Magadan, Sakhalin'}, // Magadan Standard Time
  {'value':'Pacific/Norfolk'              ,       'name': '(GMT +11:00) Norfolk'}, // Eastern Daylight Time
  {'value':'Pacific/Noumea'               ,       'name': '(GMT +11:00) Noumea'}, // New Caledonia Standard Time
  {'value':'Pacific/Pohnpei'              ,       'name': '(GMT +11:00) Pohnpei'},
  {'value':'Asia/Srednekolymsk'           ,       'name': '(GMT +11:00) Srednekolymsk'},

  {'value':'Asia/Anadyr'                  ,       'name': '(GMT +12:00) Anadyr'}, //Anadyr Standard Time
  {'value':'Pacific/Auckland'             ,       'name': '(GMT +12:00) Auckland, McMrdo'}, // New Zealand Daylight Time
  {'value':'Pacific/Fiji'                 ,       'name': '(GMT +12:00) Fiji'}, // Fiji Standard Time
  {'value':'Asia/Kamchatka'               ,       'name': '(GMT +12:00) Kamchatka'}, // Petropavlovsk-Kamchatski Standard Time
  {'value':'Pacific/Funafuti'             ,       'name': '(GMT +12:00) Funafuti'}, // Tuvalu Time
  {'value':'Pacific/Kwajalein'            ,       'name': '(GMT +12:00) Kwajalein, Majuro'}, // Marshall Islands Time
  {'value':'Pacific/Nauru'                ,       'name': '(GMT +12:00) Nauru'}, // Nauru Time
  {'value':'Pacific/Tarawa'               ,       'name': '(GMT +12:00) Tarawa'}, // Gilbert Islands Time
  {'value':'Pacific/Wake'                 ,       'name': '(GMT +12:00) Wake'}, // Wake Island Time
  {'value':'Pacific/Wallis'               ,       'name': '(GMT +12:00) Wallis'},
  {'value':'Pacific/Chatham'              ,       'name': '(GMT +12:45) Chatham'},

  {'value':'UTC'                          ,       'name': ' UTC'}
  ]